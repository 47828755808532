export default {
  async search({commit, dispatch, state}, payload) {
    if (state.controller) {
      state.controller.abort()
    }

    commit('setLoading', true)

    const controller = new AbortController()
    const signal = controller.signal

    commit('setController', controller)

    const url = `tags/tagbar/meta_autocomplete?query=${payload.query}&count=30` +
      `&start_index=${payload.more ? state.founded_next_index : 0}`;

    const responseData = await dispatch('makeFetch', {
      url: url,
      method: 'GET',
      signal: signal,
      errorMessage: 'Failed to Get Tagbar Search!'
    }, { root: true } )

    //TODO BARRY COMBINE list AND metas TOGETHER
    const metas = responseData.metas.map( (item, index) => {
      item.display = responseData.list[index].display
      return item
    })

    commit('setLoading', false)
    commit('setFoundedData', {
      query: payload.query,
      metas: metas,
      next_index: responseData.next_index
    })

  },
  async getHistory({commit, dispatch, rootState}) {
    commit('setHistoryLoading', true)

    let metas;

    if (rootState.auth.isLoggedIn) {
      const responseData = await dispatch('makeFetch', {
        url: 'tags/tagbar/meta_history?limit=30',
        method: 'GET',
        errorMessage: 'Failed to Get History!'
      }, { root: true } )

      metas = responseData.metas.map( (item, index) => {
        item.display = responseData.list[index].display_name
        return item
      })
    } else {
      metas = localStorage.getItem('tagbar/history') ? JSON.parse(localStorage.getItem('tagbar/history')) : []
    }

    commit('setHistory', metas);
    commit('setHistoryLoading', false)
  },
  addHistoryTag({commit, rootState}, payload) {
    commit('addHistoryTag', payload)
    if (!rootState.auth.isLoggedIn) {
      commit('updateHistoryLSTag')
    }
  },
  async clearHistory({commit, dispatch}) {
    commit('setHistoryLoading', true)

    const responseData = await dispatch('makeFetch', {
      url: 'tags/tagbar/history',
      method: 'DELETE',
      errorMessage: 'Failed to Clear History!'
    }, { root: true } )

    if (responseData?.success) {
      commit('setHistory', []);
    }

    commit('setHistoryLoading', false)
  },
  clearFoundData({commit}) {
    commit('clearFoundData')
  },



}