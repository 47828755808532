import { SYS_UIDS } from '@/const/config'

export const SPECIAL_PAGE_NAMES = {
  [SYS_UIDS.breaking_news] : "MEGA Leadership Team",
  [SYS_UIDS.ceo] : "CEOs Teams",
  [SYS_UIDS.heads_of_state] : "Heads of State Teams",
  [SYS_UIDS.executives] : "Executives Teams",
  [SYS_UIDS.politicians] :"Politicians Teams",
  [SYS_UIDS.companies] : "Companies Teams",
  [SYS_UIDS.governments] : "Governments Teams",
  [SYS_UIDS.non_profits] :  "Non Profits Teams",
}