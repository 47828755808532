<template>
  <span>
    <label :class="labelClass" title="Upload Pictures" tabindex="104" :for="`file-input-${postUid}`">
      <slot></slot>
    </label>
    <input type="file" class="d-none" :id="`file-input-${postUid}`" :multiple="isMultiple" accept="image/*, .heic" @change="onInputChange">
    <transition name="opacity" mode="out-in">
      <div v-if="isDragActive"
           @dragover.prevent="$emit('dragEnter')"
           @drop.prevent="drop($event)"
           class="position-absolute p-3 fs-5 d-flex align-items-center justify-content-center w-100 bottom-0 start-0 z-3 theme-bg color-white border theme-border-color"
           :class="dragZoneClass"
      >
        Drop Here
      </div>
    </transition>
  </span>
</template>

<script>
import {getOrientation, rotateImage} from "@/functions/global";
import {ACCEPTED_IMAGE_FORMATS} from "@/const/images";

export default {
  emits: ['imageAdd', 'imageLoaded', 'imageFailed', 'uploadStateChange', 'dragEnter', 'onDrop'],
  props: {
    labelClass: {
      type: String,
      default: '',
    },
    postUid: {
      default: '',
    },
    idOffset: {
      type: Number,
      default: 0,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    uploadOnReady: {
      type: Boolean,
      default: true,
    },
    isDragActive: {
      type: Boolean,
      default: false,
    },
    dragZoneClass: {
      type: String,
    },
  },
  data() {
    return {
      nextId: this.idOffset || 0,
    }
  },
  methods: {
    async onInputChange(e) {
      await this.addFiles(e.target.files)
      e.target.value = null;
    },
    async uploadOnS3(imageFile, id, type) {
      const imageType = imageFile.type || type
      this.$emit('uploadStateChange', 'uploading', id)
      await this.$store.dispatch('uploadToS3', imageFile, imageType).then( ({src}) => {
        this.$emit('uploadStateChange', 'done', id, src)
        this.$emit('imageLoaded', id, src)
      }).catch((error) => {
        this.$store.dispatch('setError', error.message || "FileUploader:uploadOnS3 - Error!");
      })
    },
    drop(e) {
      this.$emit('onDrop')
      this.addFiles(e.dataTransfer.files)
    },
    async addFiles(files) {
      for( let i = 0; i < files.length; i++ ){
        if (ACCEPTED_IMAGE_FORMATS.indexOf(files[i].type) < 0) {
          this.$store.dispatch('setError', `${files[i].type} is an invalid file format, please upload as .png, .jpg, .jpeg, .avif, .gif, .heic.`);
          continue
        }

        if ( (files[i].type === 'image/png' && files[i].size / 1048576 > 8) || files[i].size / 1048576 > 10) {
          this.$store.dispatch('setError', 'Max file size is Exceeded (8 Mb).');
          continue
        }

        this.nextId += 1
        this.$emit('imageAdd', {name: files[i].name, id: this.nextId, loading: true, type: files[i].type})
        let reader = new FileReader();
        reader.id = this.nextId
        const {orientation, file} = await getOrientation(files[i])
        reader.onload = async ({target}) => {
          if (file.type === 'image/heic') {
            await this.loadHeic2Any()
            await window.heic2any({
              blob: file,
              toType: "image/jpg"
            }).then( async (blob) => {
              const blob_src = URL.createObjectURL(blob);
              const {imageFile} = await rotateImage(blob_src, orientation, "image/jpeg");
              if (this.uploadOnReady) {
                await this.uploadOnS3(imageFile, reader.id, "image/jpeg")
              } else {
                this.$emit('imageLoaded', reader.id, imageFile)
              }
            })
          } else {
            const {imageFile} = await rotateImage(target.result, orientation, file.type);
            if (this.uploadOnReady) {
              await this.uploadOnS3(imageFile, reader.id, file.type)
            } else {
              this.$emit('imageLoaded', reader.id, imageFile)
            }
          }
        };
        reader.onerror = (e) => {
          console.error(e);
        };
        reader.readAsDataURL(file);
      }
    },
    loadHeic2Any() {
      return new Promise((resolve) => {
        if (document.getElementById('heic2any')) return resolve()

        let script = document.createElement('script')
        script.onload = resolve
        script.async = true
        script.id = 'heic2any'
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/heic2any/0.0.4/heic2any.min.js'
        document.head.appendChild(script)
      })
    },
  },
}
</script>