import {SYS_UIDS} from "@/const/config";

export default {

// ////// ///// ///// Invites // ////// ///// /////

  async getInvites({dispatch, commit, rootState}, payload) {
    if (!payload.keep) commit('setInvitesLoading', true)

    if (payload.more && rootState.col3.invites.nextIndex === -1) return

    const count = payload.count ? payload.count : 25

    let url = `tags/${payload.uid}/called_out?count=${count}`;
    if(['home', 'custom'].indexOf(rootState.tags.currentTag.type) >= 0) {
      url = `tags/${SYS_UIDS.home}/called_out?count=${count}`;
    }

    if (payload.useUserUrl) {
      url = `tags/${payload.uid}/called_out_by?count=${count}`;
    }

    if (payload.more)  url += `&start_index=${rootState.col3.invites.nextIndex}`

    const responseData = await dispatch('makeFetch', {
      url: url,
      version: 3,
      method: 'GET',
      errorMessage: 'Failed to Get Col3:Invites!'
    }, { root: true } )

    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('setInvites', responseData);
    commit('setInvitesLoading', false)
  },
  // removeByPostUid({commit}, payload) {
  //   commit('removeByPostUid', payload)
  // },
  clearInvites({commit}) {
    commit('setInvitesLoading', true)
    commit('clearInvites')
  },

// ////// ///// ///// PHOTOS // ////// ///// /////

  async getPhotos({dispatch, commit}, payload) {
    commit('setPhotosLoading', true)
    const count = payload.count || 9
    const startIndex = payload.startIndex || 0
    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/photo_posts?count=${count}&start_index=${startIndex}`,
      method: 'GET',
      errorMessage: 'Failed to Get Col3:Photos!'
    }, { root: true } )
    commit('setPhotosData', responseData)
    commit('setPhotosLoading', false)
  },
  async removePhoto({commit, state, dispatch, rootState}, payload) {
    const prevCount = state.photos.photos.length
    commit('removePhoto', payload)
    if (prevCount !== state.photos.photos.length && state.photos.nextIndex !== -1) {
      dispatch('getPhotos', {
        uid: rootState.tags.currentTag.uid,
        count: 1,
        startIndex: state.photos.photos.length + 1
      })
    }
  },
  addPhotos({commit}, payload) {
    commit('addPhotos', payload)
  },
  removePhotosByPostUid({commit}, payload) {
    commit('removePhotosByPostUid', payload)
  },
  clearPhotos({commit}) {
    commit('clearPhotosData')
  },

  // ////// ///// ///// TEAMMATES // ////// ///// /////

  async getTeammates({dispatch, commit, rootState}, payload) {
    if (payload.more && rootState.col3.teammates.nextIndex === -1) return

    commit('setTeammatesLoading', true)
    const count = payload.count ? payload.count : 9
    let url
    if (payload.more) {
      url = rootState.col3.teammates.prevUrl
    } else if (payload.useOrgsUrl) {
      url = `tags/${payload.uid}/orgs`;
    } else if (payload.mode === 'leaders') {
      url = payload.uid !== -1 ? `tags/${payload.uid}/teammates` : `tags/by_type/5/teammates`
    } else {
      let mode = payload.mode || 'teammates';
      if (payload.mode === 'recent') mode = 'recent_teammates';
      if (payload.mode === 'mutual') mode = 'mutual_teammates';
      url = `tags/${payload.uid}/${mode}`;
      if(['home', 'custom'].indexOf(rootState.tags.currentTag.type) >= 0) {
        url = `tags/${SYS_UIDS.home}/mdt`;
      }
    }

    const prevUrl = url
    url += `?count=${count}`

    if (payload.startIndex)  url += `&start_index=${payload.startIndex}`
    if (payload.query) url += `&query=${payload.query}`


    const {tags, list, next_index, total_count, leaders_count} = await dispatch('makeFetch', {
      url: url,
      method: 'GET',
      errorMessage: 'Failed to Get Col3:Teammates!'
    }, { root: true } )

    await dispatch('tags/addTags', tags, { root: true })
    commit('setTeammatesData', {list, next_index, total_count, leaders_count, prevUrl})
    commit('setTeammatesLoading', false)
  },
  removeTeammate({dispatch, commit, state}, payload) {
    const prevCount = state.teammates.list.length
    commit('removeTeammate', payload)
    if (prevCount !== state.teammates.list.length && state.teammates.nextIndex > 0) {
      dispatch('getTeammates', {
        count: 1,
        more: true,
        startIndex: state.teammates.list.length + 1
      })
    }
  },
  clearTeammates({commit}) {
    commit('clearTeammatesData')
  },

}