<template>
  <svg class="d-dude" data-name="Component 2 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.06 50.51">
    <path class="curr-color" data-name="Ellipse 638" d="m16.53,0c6.08,0,11,4.92,11,11s-4.92,11-11,11-11-4.92-11-11S10.46,0,16.53,0Zm0,20c4.97,0,9-4.03,9-9S21.5,2,16.53,2,7.53,6.03,7.53,11c0,4.97,4.03,8.99,9,9Z" />
    <path class="curr-color" data-name="Ellipse 639" d="m32.23,13.27c4.35-.06,7.93,3.41,8,7.76-.07,4.35-3.65,7.82-8,7.76-4.35.06-7.93-3.41-8-7.76.07-4.35,3.65-7.82,8-7.76Zm0,13.52c3.25.06,5.93-2.51,6-5.76-.07-3.24-2.75-5.82-6-5.76-3.25-.06-5.93,2.51-6,5.76.07,3.24,2.75,5.82,6,5.76Z"/>
    <path class="curr-color" data-name="Union 7" d="m28.03,50.5H4.87c-2.62.07-4.79-2-4.87-4.61v-9.66c0-2.91.87-5.76,2.5-8.17,1.62-2.4,3.85-4.32,6.46-5.56,2.33-1.13,4.89-1.71,7.48-1.7h.01c2.59,0,5.14.58,7.47,1.7,2.3,1.1,4.31,2.72,5.87,4.74.43-.04.87-.07,1.3-.07h0c1.87,0,3.72.41,5.42,1.21,1.89.88,3.52,2.26,4.7,3.98,1.18,1.73,1.82,3.78,1.82,5.87v6.74c-.07,2-1.74,3.57-3.74,3.52h-7.26c-.94,1.29-2.44,2.04-4.03,2.02Zm-11.58-27.71c-2.29,0-4.55.51-6.62,1.5-2.29,1.09-4.25,2.77-5.67,4.87-1.41,2.09-2.16,4.54-2.16,7.06v9.66c.08,1.51,1.35,2.68,2.87,2.61h23.17c1.07.02,2.06-.56,2.59-1.49l.28-.53h8.41c.9.06,1.67-.62,1.74-1.52v-6.74c-.07-3.5-2.17-6.64-5.38-8.04-1.43-.67-2.98-1.02-4.56-1.02h0c-.54,0-1.07.04-1.6.12l-.61.09-.36-.5c-1.4-1.97-3.29-3.54-5.47-4.58-2.06-.99-4.32-1.5-6.61-1.5h-.01Z" />
    <g id="_" data-name=" " style="isolation: isolate;">
      <text transform="translate(19.21 43.48)" style="fill: #fff; font-family: HelveticaNeue-Bold, 'Helvetica Neue'; font-size: 12px; font-weight: 700; isolation: isolate;"><tspan x="0" y="0">&gt;</tspan></text>
    </g>
    <path class="curr-color" data-name="Subtraction 18" d="m39.31,48.94h-9.46l.77-1.47c.18-.35.28-.73.28-1.12v-9.66c0-2.64-.83-5.21-2.36-7.35l-.94-1.33,1.6-.24c.63-.09,1.26-.14,1.9-.14h0c1.87,0,3.72.41,5.42,1.21,1.89.88,3.52,2.26,4.7,3.98,1.18,1.73,1.82,3.78,1.82,5.87v6.74c-.06,2-1.74,3.57-3.74,3.52Zm-6.45-2h6.45c.9.06,1.67-.62,1.74-1.52v-6.74c0-1.7-.52-3.35-1.48-4.75-.98-1.43-2.33-2.56-3.9-3.3-1.43-.67-2.98-1.02-4.56-1.02h-.03c1.2,2.16,1.83,4.59,1.83,7.06v9.66c0,.2-.01.39-.04.59Z" />
    <path id="Path_335" data-name="Path 335" d="m23.46,24.13c-.74-.36-1.5-.67-2.28-.91l-4.9,4.64-4.9-4.64c-.78.25-1.55.55-2.28.91-2.94,1.44-5.35,3.78-6.88,6.67,8.89,4.3,19.25,4.3,28.14,0-1.53-2.9-3.95-5.23-6.89-6.67Z"/>
    <path class="curr-color" data-name="Path 335 - Outline" d="m11.5,22.65l4.77,4.52,4.77-4.52.28.09c.81.25,1.59.57,2.35.94,3.03,1.49,5.51,3.9,7.1,6.89l.24.46-.46.22c-4.44,2.15-9.31,3.27-14.24,3.27h-.08c-4.93,0-9.8-1.13-14.24-3.27l-.46-.22.24-.46c1.59-2.98,4.07-5.39,7.1-6.89.76-.37,1.55-.69,2.35-.94l.28-.09Zm18.15,7.93c-1.5-2.59-3.73-4.68-6.41-6-.63-.31-1.27-.58-1.93-.8l-5.03,4.77-5.03-4.77c-.66.22-1.31.49-1.93.8-2.68,1.32-4.91,3.41-6.41,6,4.18,1.93,8.73,2.94,13.33,2.95h.08c4.61,0,9.16-1.01,13.34-2.95Z" />
    <g data-name="Group 1673">
      <circle class="curr-color" data-name="Ellipse 640" cx="10.94" cy="25.64" r=".5"/>
      <path class="curr-color" data-name="Ellipse 640 - Outline" d="m10.95,25.14c.28,0,.5.23.49.51s-.23.5-.51.49-.5-.23-.49-.51.23-.5.51-.49Z" />
      <circle class="curr-color" data-name="Ellipse 641" cx="11.93" cy="26.65" r=".5" />
      <path class="curr-color" data-name="Ellipse 641 - Outline" d="m11.94,26.15c.28,0,.5.23.49.51s-.23.5-.51.49-.5-.23-.49-.51.23-.5.51-.49Z" />
      <circle class="curr-color" data-name="Ellipse 642" cx="12.91" cy="27.67" r=".5" />
      <path class="curr-color" data-name="Ellipse 642 - Outline" d="m12.92,27.17c.28,0,.5.23.49.51s-.23.5-.51.49-.5-.23-.49-.51.23-.5.51-.49Z" />
      <circle class="curr-color" data-name="Ellipse 643" cx="13.89" cy="28.69" r=".5" />
      <path class="curr-color" data-name="Ellipse 643 - Outline" d="m13.9,28.19c.28,0,.5.23.49.51s-.23.5-.51.49-.5-.23-.49-.51.23-.5.51-.49Z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "DoubleDude"
}
</script>

<style>
 .d-dude .curr-color{
   fill: currentColor;
   font-weight: normal;
   font-style: normal;
   speak: never;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;

   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
</style>