<template>
  <div v-if="list.length > 0">
    <tag-item v-for="tmUid in list"
      :key="tmUid"
      :uid="tmUid"
      :invite-tag-uid="inviteTagUid"
      :show-call-out="showCallOut"
      class="d-flex flex-row tag-item border-bottom"
    />
  </div>
</template>

<script>
import TagItem from "./TagItem";
export default {
  name: "TagsList",
  components: {TagItem},
  props: ['list', 'inviteTagUid', 'showCallOut'],
}
</script>