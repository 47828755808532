import getPosts from "./getPosts";
import hideLeftBar from "./hideLeftBar";

export default {
  mixins: [getPosts, hideLeftBar],
  computed: {
    currentTagUid() {
      return this.$store.getters['tags/currentTagUid']
    },
  },
  methods: {
    refreshHomeFeed() {
      if (this.$route.fullPath === '/') {
        this.hideLeftBar()
        this.$store.dispatch('posts/clearPosts');
        this.getPosts()
      }
    },
  }
}