import {NONE} from "@/const/statuses";

export default {
  setSaveStatus(state, payload) {
    state.saveStatus = payload;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setManageTeammatesData(state, payload) {
    state.list = payload.list;
    state.nextIndex = payload.next_index;
  },
  setWdu(state, payload) {
    state.wdu = payload
  },
  sortList(state, payload) {
    state.list.splice(payload.newIndex, 0, state.list.splice(payload.oldIndex, 1)[0]);
  },
  clearData(state) {
    state.isLoading = true;
    state.nextIndex = 0;
    state.list = [];
    state.saveStatus = NONE;
  },
  toggleAttr(state, {uid, field}) {
    const item = state.list.find((item) => item.uid === uid)
    item[field] = !item[field]
  },
  addTeammate(state, payload) {
    state.list.push(payload)
  },
  removeTeammate(state, uid) {
    state.list = state.list.filter((item) => item.uid !== uid)
  },
}