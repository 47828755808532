<template>
  <span class="comment-reactions-box d-flex align-items-center shadow-sm rounded-pill">
    <span v-for='(count, reaction) in reactions' :key='reaction'
      data-toggle='tooltip'
      data-html='true'
      class="d-flex"
    >
      <user-reactions-dd :uid="uid" type="comment" :selected-reaction="reaction">
        <span class='reaction-img-wrapper small d-flex' :class="(reaction || 'none') + '-wrapper'">
          <heart-icon v-if="reaction === 'love'" size="xs"/>
          <i v-else :class='reaction'></i>
        </span>
      </user-reactions-dd>
    </span>
    <user-reactions-dd :uid="uid" type="comment" :link-class="countLinkClass">
      <base-numeral :num="reactionsCount"></base-numeral>
    </user-reactions-dd>
  </span>
</template>

<script>
import UserReactionsDd from "../../../dd/UserReactionsDd";
import HeartIcon from "@/components/icons/HeartIcon";

export default {
  name: "CommentReactionsBox",
  props: ['uid', 'reactions', 'reactionsCount', 'isHighlighted'],
  components: {HeartIcon, UserReactionsDd},
  computed: {
    countLinkClass() {
      return (this.isHighlighted ? 'link-color' : 'link-main') + ' ms-1'
    }
  },
}
</script>
