<template>
  <div class="dropdown position-static" ref="memberOfTeamDD">
    <a
        :class="linkClass"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        id="memberOfTeamDD"
        data-bs-boundary="viewport"
        ref="memberOfTeamDDLink"
    ><slot/></a>

    <div class="dropdown-menu dropdown-menu-full-size-sm" aria-labelledby="memberOfTeamDD">
      <template v-if="isVisible">
        <div class="theme-color fs-80 px-2 py-1 fw-bold">
          Dual Dialogues
        </div>
        <hr class="dropdown-divider">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list :list="list"/>
          <base-spinner v-if="isLoading || moreIsLoading"/>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
  import TagsList from "./parts/TagsList";
  import {Dropdown} from 'bootstrap';
  import ddFetchData from "../../hooks/ddFetchData";

  export default {
    components: {TagsList},
    props: {
      uid: {required: true},
      linkClass: {default: 'link-main'}
    },
    mixins: [ddFetchData],
    watch: {
      uid() {
        if (!this.isVisible) return

        Dropdown.getInstance(this.$refs.memberOfTeamDDLink).hide()
      },
    },
    computed: {
      errorMessage() {
        return 'Failed to Get MemberOfTeams!'
      },
      url() {
        return `tags/${this.uid}/resolving?count=30` },
      isOrg() {
        return this.$store.getters['tags/findTag'](this.uid || 1).isOrg
      },
    },

  }
</script>