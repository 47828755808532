export const removeBlankParagraphs = (oldMessage) => {
    let content = JSON.parse(oldMessage).content.reverse()

    let hasBlankLikes = true
    let currentIteration = 0

    while (hasBlankLikes || currentIteration === 50) {
        if ((content[0].content && content[0].type === 'paragraph')
            || !content[1]
            || content[1].content && content[1].type === "paragraph"
        ) {
            hasBlankLikes = false
            break
        }

        if (!content[1].content && content[1].type === "paragraph") {
            content.splice(0, 1)
        }
        currentIteration += 1
    }

    return JSON.stringify({type: "doc", content: content.reverse()})
}