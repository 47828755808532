<template>
  <div class="comment-input-container px-2 py-1">
    <div v-if="isLoggedIn" class="comment-input mb-2 position-relative">
      <transition name="opacity" mode="out-in">
        <div v-if="textBoxError" class="comment-input-error theme-bg text-inverse fs-90 text-center rounded-top pointer position-absolute bottom-100 w-100" @click="hideError">
          {{textBoxError}}
        </div>
      </transition>

      <images-preview :images="images" class="py-1" @removeImage="onImageRemove" @onSort="onImagesSort"/>

      <p class="m-0 ms-2 text-around-comment-input text-grey fs-60">Leaders' dialogue highlighted above - if {{parentCommentUid ? 'reply' : 'comment'}} here.</p>
      <div class="comment-input-inner"
           @dragenter.prevent="dragEnter"
           @dragleave.prevent="dragLeave"
      >
        <div class="d-flex">
          <img class="me-2 avatar rounded-circle" :src="currentUserAvatar" alt="img" loading="lazy">

          <div class="input-group theme-border rounded">
            <span class="fs-110 ps-2 input-arrow-prefix comment-input-bg rounded">></span>
            <input v-if="!isActive"
              @focusin="activateInput"
              class="form-control border-0 comment-input-bg text-truncate fs-110 ps-1"
              contenteditable="true"
              :placeholder="commentsPlaceholder"
            />
            <tip-tap
                v-if="isActive"
                class="form-control border-0 comment-input-bg ps-1"
                :placeholder="commentsPlaceholder"
                :limit="postCharsLimit"
                :is-comment="true"
                :auto-focus="true"
                @onChangeText="onChangeText"
                @onLinkPaste="onLinkPaste"
                @onEnterKey="saveComment"
                @onCharsCountUpdate="onCharsCountUpdate"
                @onBlur="deactivateInput"
                ref="tiptap"
            />
            <span v-show="!isLoading" class="input-group-text comment-input-bg rounded-end border-0">
              <the-twemoji
                  @onShow="activateInput"
                  @onHide="deactivateInput"
                  @onSelect="addEmoji"
                  class="me-2 lh-1"
                  icon-class="link-main fs-4"
              />
              <file-uploader
                  class="me-1 lh-1"
                  :post-uid="post.uid + '' + isPostZoom"
                  :is-drag-active="isDragActive"
                  @imageLoaded="onImageLoaded"
                  @imageAdd="onImageAdd"
                  @dragEnter="dragEnter"
                  @onDrop="onDrop"
              >
                <i class="icon-image link-main fs-4"></i>
              </file-uploader>
            </span>
            <span v-show="isLoading" class="input-group-text comment-input-bg rounded-end border-0"><base-spinner/></span>
          </div>
        </div>
        <p class="m-0 ms-2 text-around-comment-input text-grey fs-60">
          Teammates' dialogue below - every mention counts.
        </p>
        <div id="web-page-container">
          <base-spinner v-if="isWebPreviewLoading && web_url" class="w-100"/>
          <web-page-preview v-else-if='!isWebPreviewLoading && web_url'
                            v-bind="url_preview"
                            :edit-mode="true"
                            @removeWebPreviewImage="removeWebPreviewImage"
                            @removeWebPreviewAll="removeWebPreview"
          />
        </div>
      </div>
    </div>

    <div v-else class="comment-input-inner">
      <disabled-comment-input @focusin="goLogin" :parent-comment-uid="parentCommentUid"/>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import currentUserAvatar from "../../../hooks/currentUserAvatar";
import editor from "../../../hooks/editor";
import TipTap from "../../editors/TipTap";
import FileUploader from "../../editors/FileUploader";
import ImagesPreview from "../../editors/ImagesPreview";
import TheTwemoji from "../../editors/TheTwemoji";
import WebPagePreview from "../../editors/WebPagePreview";
import DisabledCommentInput from "./DisabledCommentInput";

export default {
  name: 'CommentInput',
  inject: ['post', 'tag', 'postOptions', 'isPostZoom', 'onTypeStart', 'onTypeEnd'],
  components: {DisabledCommentInput, WebPagePreview, ImagesPreview, FileUploader, TipTap, TheTwemoji},
  mixins: [editor, currentUserAvatar],
  props: ['parentCommentUid'],
  data() {
    return {
      textBoxError: null,
      images: [],
      message: {},
      url_preview: null,
      isLoading: false,
      deactivateTimeout: null,
      isTyping: false,
    }
  },
  watch: {
    'postOptions.focusInput'() {
      this.activateInput()
      this.focusEditor()
    },
    charsCount() {
      if (this.charsCount) {
        return this.startTyping()
      }

      this.stopTyping()
    },
  },
  computed: {
    ...mapGetters('auth', ['currentUser', 'isLoggedIn']),
    ...mapGetters('tags', ['currentTagType']),
  },
  methods: {
    addMention(tagAttributes) {
      if (!this.isActive) {
        this.activateInput()
      }
      this.$nextTick(() => {
        if (this.currentUser.uid !== tagAttributes.uid) {
          this.$refs.tiptap.addMention(tagAttributes)
        }
      })
    },
    async saveComment() {
      if (this.isLoading) return

      if(this.isEmpty) {
        return this.textBoxError = "Message can't be empty.."
      }
      this.stopTyping()
      const images = this.images.map((img)=> { return img.src})
      const comment = {
        postUid: this.post.uid,
        commentsCount: this.post.stats.comments_count,
        data: {
          parent_comment_uid: this.parentCommentUid,
          images: images,
          poster_uid: this.currentUser.uid,
          message: JSON.stringify(this.message),
          url_preview: this.url_preview,
          promo_tag_uids: this.post.promo_tag_uids,
        }
      }
      this.isLoading = true

      const response = await this.$store.dispatch('posts/createComment', comment)

      if(response.success) {
        this.resetComment()
      } else {
        this.isLoading = false
        this.textBoxError = response.message
      }
    },
    resetComment() {
      this.isLoading = false
      this.errorTimeoutId = null
      this.images = []
      this.message = {}
      this.isWebPreviewLoading = false
      this.removeWebPreview()
      this.$refs.tiptap.clearAll()
    },
    addError(e) {
      this.textBoxError = e
    },
    hideError() {
      clearTimeout(this.errorTimeoutId)
      this.textBoxError = null
    },
    onChangeText(text) {
      this.message = text
    },
    activateInput() {
      clearTimeout(this.deactivateTimeout)
      this.isActive = true
      this.focusEditor()
    },
    startTyping() {
      if (this.isTyping) return

      this.onTypeStart(this.parentCommentUid)
      this.isTyping = true
    },
    stopTyping() {
      if (!this.isTyping) return

      this.onTypeEnd(this.parentCommentUid)
      this.isTyping = false
    },
    deactivateInput() {
      this.deactivateTimeout = setTimeout(() => {
        if(this.isEmpty) {
          this.isActive = false
        }
        this.stopTyping()
      }, 3000)
    },
    focusEditor() {
      this.$nextTick(() => {
        this.$refs.tiptap.focusEditor()
      })
    },
    goLogin() {
      this.$router.push({name: 'sign-in', params: {slug: this.$store.getters['tags/currentTagSlugNoHome']}})
    },
  },
  beforeUnmount() {
    clearTimeout(this.deactivateTimeout)
    this.stopTyping()
  },
}
</script>
