export default {
  methods: {
    async getPosts() {
      try {
        return this.$store.dispatch('posts/getPosts', {uid: this.currentTagUid});
      } catch (error) {
        this.$store.dispatch('setError', error.message || "App:fetchPosts: failed!");
      }
    },
  }
}