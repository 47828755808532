<template>
  <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
    <div v-if="title" class="toast-header text-inverse" :class="headerClass">
      <strong class="me-auto">{{title}}</strong>
      <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close"></button>
    </div>
    <div class="toast-body d-flex">

      <span>{{message}}</span>
      <button v-if="!title" type="button" class="btn-close" aria-label="Close" @click="close"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    title: String,
    message: String,
    type: {
      type: String,
      default: 'success'

    }
  },
  data() {
    return {
      timeoutId: null,
      timeoutMS: 6000
    }
  },
  computed: {
    headerClass() {
      return {'success': 'theme-bg', 'alert': 'bg-danger'}[this.type]
    },
  },
  mounted() {
    this.timeoutId = setTimeout(()=> {
      this.close()
    }, this.timeoutMS)
  },
  methods: {
    close() {
      clearTimeout(this.timeoutId);
      this.$store.dispatch('removeNotice', this.id);
    }
  },
}
</script>
