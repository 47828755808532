import {removeBlankParagraphs} from "@/functions/post";

export default {
  async createPost({dispatch, commit, rootGetters}, payload) {
    payload.data.message = removeBlankParagraphs(payload.data.message)
    commit('setPostBoxLoading', true)
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/${rootGetters['tags/currentTagUid']}/post`,
        method: 'POST',
        errorMessage: 'Failed to Create Post!',
        body: payload.data,
      }, { root: true } )
      commit('setPostBoxLoading', false)
      if(responseData.success) {
        await dispatch('tags/addTags', responseData.tags, { root: true })
        commit('posts/addPost', responseData.post_obj)
        return responseData
      } else {
        throw responseData.message;
      }
    }catch (error) {
      commit('setPostBoxLoading', false)
      commit('setPostBoxError', error)
    }
  },
}