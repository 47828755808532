import {NONE, SAVING, SAVED} from "@/const/statuses";

export default {
  async getTeammatesData({dispatch, commit}, payload) {
    commit('setLoading', true)
    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/manage/teammates`,
      method: 'GET',
      errorMessage: 'Failed to Get Manage Teammates List!'
    }, { root: true } )
    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('setManageTeammatesData', responseData)
    commit('setLoading', false)
  },
  async save({dispatch, commit, getters}, payload) {
    commit('setSaveStatus', SAVING)
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/${payload.uid}/manage/teammates`,
        method: 'POST',
        body: {list: getters.list, wdu: getters.wdu },
        errorMessage: 'Failed to Save Teammates',
      }, {root: true})
      if (responseData && responseData.success) {
        commit('setSaveStatus', SAVED)
      }
      setTimeout(() => {
        commit('setSaveStatus', NONE)
      }, 1500)
    } catch (e) {
      dispatch('setError', e, {root: true})
      commit('setSaveStatus', NONE)
    }
  },
  removeTeammate({commit}, uid) {
    commit('removeTeammate', uid)
  },

}