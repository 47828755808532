export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setDetails(state, payload) {
    Object.assign(state.details, payload)
  },
  setSubscriptionAttempt(state, payload) {
    Object.assign(state.subscriptionAttempt, payload)
  },
  clearData(state) {
    state.details = {
      subscription_start_date: null,
      subscription_message: null,
      tag_domain: null,
      subscribe_allowed: null,
      current_tier: null,
      subscriber: null,
      renewal_date: null,
      plans: null,
      extra_plans: null,
    }
    state.isLoading = false
    state.tier = null
    state.subscriptionAttempt = {
      tier: -1,
      price: 0,
      reservation_token: null,
      stripe_client_key: null,
      change_token: null,
      action: null,
      amount: null,
      message: null,
      renewal_date: null,
    }
  },
  clearSubscriptionAttempt(state) {
    state.subscriptionAttempt = {
      tier: -1,
      price: 0,
      reservation_token: null,
      stripe_client_key: null,
      change_token: null,
      action: null,
      amount: null,
      message: null,
      renewal_date: null,
    }
  },
}