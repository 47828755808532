import {Share} from "@capacitor/share";
// import {Capacitor} from "@capacitor/core";
// import {Directory, Filesystem} from "@capacitor/filesystem";
import {Browser} from "@capacitor/browser";
import tagTitle from "@/hooks/tagTitle";

export default {
    mixins: [tagTitle],
    data() {
        return {
            qrCardBase64Url: '',
            qrCodeBase64Url: '',
            isUploading: false,
            shareButtonVisible: true,
        }
    },
    mounted(){
        this.setShareVisibility()
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters['auth/isLoggedIn']
        },
        currentUser() {
            return this.$store.getters['auth/currentUser']
        },
        tag() {
            return this.$store.getters['tags/findTag'](this.currentUser.uid)
        },
        org() {
            if (this.tag.org_uid === -1) return

            return this.$store.getters['tags/findTag'](this.tag.org_uid)
        },
    },
    methods: {
        onChangeQrCode(qrCode) {
            if (this.tag.qr_code_url) return

            this.qrCodeBase64Url = qrCode
            this.createQrCode()
        },
        async uploadQrToS3() {
            this.isUploading = true
            await this.$store.dispatch('uploadToS3', this.qrCardBase64Url, 'image/png').then(({src}) => {
                this.$store.dispatch('tags/updateQrCode', {uid: this.currentUser.uid, qr_url: src})
                this.isUploading = false
            }).catch((error) => {
                this.$store.dispatch('setError', error.message || "FileUploader:uploadOnS3 - Error!");
                this.isUploading = false
            })
        },
        async shareQr() {
            if (navigator.share) {
                await navigator.share({
                    url: this.tag.qr_code_url,
                })
                return
            }
            console.log(Share)
            await Share.share({
                url: this.tag.qr_code_url
            })
        },
        async downloadFile() {
            const qr_url = this.isLoggedIn ? this.tag.qr_code_url : this.signedOutQr
            // if (Capacitor.isNativePlatform()) {
            //     try {
            //         await Filesystem.downloadFile({
            //             path: 'QR.png',
            //             url: qr_url,
            //             directory: Directory.Documents,
            //         })
            //         this.$store.dispatch('setNotice', {
            //             title: "QrCode",
            //             message: 'Downloaded',
            //             type: 'notice'
            //         })
            //     } catch (e) {
            //         this.$store.dispatch('setError', e);
            //     }
            // } else {
                Browser.open({url: qr_url});
            // }
        },
        async createQrCode() {
            const canvas = document.createElement('canvas')
            const ctx = await this.addBgToCanvas(canvas)
            await this.addQrToCanvas(ctx)
            await this.addUserInfoToCanvas(ctx)

            this.qrCardBase64Url = canvas.toDataURL()
            this.uploadQrToS3()
        },
        async addBgToCanvas(canvas) {
            return new Promise((resolve) => {
                const bg_img = new Image()
                bg_img.crossOrigin = 'anonymous'
                bg_img.onload = () => {
                    canvas.width = bg_img.width
                    canvas.height = bg_img.height
                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(bg_img, 0, 0)
                    resolve(ctx)
                }
                bg_img.src = this.defaultQrBg
            })
        },
        addQrToCanvas(ctx) {
            return new Promise((resolve) => {
                const qr = new Image()
                qr.crossOrigin = 'anonymous'
                qr.onload = () => {
                    ctx.drawImage(qr, 1445, 42, 200, 200)
                    resolve()
                }
                qr.src = this.qrCodeBase64Url
            })
        },
        addUserInfoToCanvas(ctx) {
            ctx.font = "bold 45px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
            ctx.fillStyle = "black"
            ctx.fillText(this.tag.display_name, 235, 868)

            if (this.tagTitle) {
                const org_name = this.org?.display_name || ''
                ctx.font = "45px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
                ctx.fillStyle = "black"
                ctx.fillText(`${this.tagTitle} ${org_name}`, 235, 920)
            }

            return new Promise((resolve) => {
                const avatar = new Image()
                avatar.crossOrigin = 'anonymous'
                avatar.onload = () => {
                    ctx.save()
                    ctx.beginPath()
                    ctx.arc(130, 880, 65, 0, Math.PI * 2, false)
                    ctx.strokeStyle = '#ccc'
                    ctx.stroke()
                    ctx.clip()
                    ctx.drawImage(avatar, 65, 815, 130, 130)
                    ctx.restore()
                    resolve()
                }
                avatar.src = (this.currentUser.icon || this.imgPath + '/default-user.png') + `?time=${Date.now()}`
            })
        },
        async setShareVisibility() {
            const {value} = await Share.canShare()
            this.shareButtonVisible =  value || navigator.share
        },
    },
}