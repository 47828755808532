<template>
  <div class="dropdown d-inline-block user-reactions-dd">
    <a
        href="#"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        data-bs-offset="-10,5"
        id="userReactionsDD"
        :class="linkClass"
        @click="getReactions"
    >
      <slot></slot>
    </a>

    <div class="dropdown-menu" aria-labelledby="userReactionsDD">

      <div>
        <div class="d-flex align-items-center fs-80 p-2 text-grey">
          <div class="px-1 py-2 link-bottom-bordered lh-26" :class="{'border-bottom theme-border-color theme-color': !reaction}" @click="selectFilter(null)">
            All {{totalCount}}
          </div>
          <div v-for="(count, key) in headerReactions" :key="key" @click="selectFilter(key)"
               class="px-1 py-2 ms-2 link-bottom-bordered"
               :class="{'border-bottom theme-border-color theme-color': reaction === key}"
          >
            <span class="reaction-img-wrapper align-middle" :class="key+'-wrapper'">
              <heart-icon v-if="key === 'love'" size="sm"/>
              <i v-else :class="key"></i>
            </span>
            <span class="align-middle lh-26"><base-numeral :num="count"></base-numeral></span>
          </div>
        </div>
        <hr class="dropdown-divider">

        <div @scroll.passive="infinityScrollHandler" class="user-reactions-list">
          <div>
            <tag-item v-for="reactionItem in reactionList"
                      :key="reactionItem.uid"
                      :uid="reactionItem.uid"
                      :reaction="reactionItem.reaction"
                      class="d-flex flex-row user-reaction-item border-bottom"
            ></tag-item>
          </div>
          <base-spinner v-if="isLoading"></base-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import fetchOnScroll from "../../hooks/fetchOnScroll";
  import TagItem from "./parts/TagItem";
  import HeartIcon from "@/components/icons/HeartIcon";

  export default {
    components: {HeartIcon, TagItem},
    props: {
      uid: {
        required: true,
      },
      selectedReaction: {
        required: false,
      },
      type: {
        default: 'post',
      },
      linkClass: String,
    },
    mixins: [fetchOnScroll],
    data() {
      return {
        isLoading: false,
        nextIndex: 0,
        totalCount: 0,
        reactionList: [],
        headerReactions: {},
        reaction: this.selectedReaction,
      }
    },
    mounted() {
      // this.$refs.userReactionsDD.addEventListener('show.bs.dropdown', this.getReactions)
      // this.$refs.userReactionsDD.addEventListener('hidden.bs.dropdown', this.clearReactions)
    },

    methods: {

      selectFilter(reaction) {
        this.reaction = reaction
        this.getReactions()
      },
      async getReactions() {
        this.isLoading = true
        try {
          let url = `tags/posts/${this.type === 'post' ? '' : 'comments/'}${this.uid}/users_click_drive`
          const responseData = await this.$store.dispatch('makeFetch', {
            url: `${url}${this.reaction ? `?reaction=${this.reaction}` : ''}`,
            method: 'GET',
            errorMessage: 'Failed to Get userReactions!'
          })
          this.headerReactions = responseData.reactions
          this.nextIndex = responseData.next_index
          this.totalCount = responseData.total_count
          this.reactionList = responseData.reaction_list
          if (responseData.tags.length) {
            this.$store.dispatch('tags/addTags', responseData.tags)
          }
          this.isLoading = false
        } catch (error) {
          this.error = error.message || "UserReactionsDD: Failed to Get userReactions!";
        }
      },
      clearReactions() {
        this.nextIndex = 0
        this.totalCount = 0
        this.reactionList = []
        this.headerReactions = {}
        this.reaction = this.selectedReaction
      },
      more() {
        console.log('MORE USER REACTIONS')
        //  TODO ALEX
      },
      // @fetch(data: { start_index: this.nextIndex}, remove: false)
    },
    beforeUnmount() {
      this.clearReactions()
      // console.log('beforeUnmount')
      // this.$refs.userReactionsDD.removeEventListener('show.bs.dropdown', this.getReactions)
      // this.$refs.userReactionsDD.removeEventListener('hidden.bs.dropdown', this.clearReactions)
    },

  }
</script>