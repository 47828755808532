export default {
  props: {
    uid: {
      type: Number,
      required: true
    },
  },
  created() {
    if (!this.$store.getters['tags/findTag'](this.uid)) {
      this.fetchTag()
    }
  },
  computed: {
    tag() {
      return this.$store.getters['tags/findTag'](this.uid)
    },
  },
  methods: {
    async fetchTag() {
      const fetchingUids = this.$store.getters['tags/getFetchingUids']
      if (fetchingUids.indexOf(this.uid) === -1) {
        await this.$store.dispatch('tags/fetchTagsByUid', [this.uid])
      }
    }
  },
}