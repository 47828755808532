<template>
  <div class="dropdown">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport" link-id="invitesFromDD"></slot>

    <ul class="dropdown-menu" aria-labelledby="invitesFromDD">
      <li class="dropdown-header px-2 py-1">
        {{tag.display_name}} called-out
        <base-numeral :num="totalCount"></base-numeral>
        <base-pluralize :num="totalCount" word="leader"></base-pluralize>
        to work together
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item-text p-0">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list :list="list"></tags-list>
          <base-spinner v-if="isLoading || moreIsLoading" class="p-3"></base-spinner>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ddFetchData from "../../hooks/ddFetchData";
import TagsList from "./parts/TagsList";

export default {
  name: 'invitesFromDD',
  components: {TagsList},
  mixins: [ddFetchData],
  props: ['uid'],
  data() {
    return {
      errorMessage: 'Failed to Get invites_from!',
    }
  },
  computed: {
    url() {
      return `tags/${this.uid}/invites_from?count=30`
    },
    tag() {
      return this.$store.getters['tags/findTag'](this.uid)
    },
  }
}
</script>