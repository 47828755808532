import {typesObject} from "@/functions/global";

export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setInvites(state, payload) {
    state.list = state.list.concat(payload.list);
    state.nextIndex = payload.next_index
    state.totalCount = payload.total_count
    state.callOutFromCount = payload.call_out_from_count
    state.callOutToCount = payload.call_out_to_count
  },
  setMentionList(state, payload) {
    payload.metas.map((item, index) => {
      payload.metas[index] = {...item, ...typesObject(item)}
    })
    state.mentionList = payload.metas
  },
  setMentionLoading(state, payload) {
    state.isMentionLoading = payload;
  },
  removeByPostUid(state, payload) {
    //TODO ALEX REWRITE. where to get invite.invite_post_uid  AND DO WE NEED THAT AT ALL????
    const filteredInvites = state.list.filter(invite => invite.invite_post_uid !== payload.postUid)
    if(state.list.length > filteredInvites.length) {
      state.totalCount = state.totalCount - 1
      state.nextIndex = state.nextIndex - 1
    }
    state.list = filteredInvites
  },
  clearInvites(state) {
      state.list = [];
      state.nextIndex = null;
      state.totalCount = null;
      state.callOutFromCount = null;
      state.callOutToCount = null;
      state.mutual_invite_uids = null;
  },
  // addInvite(state, payload) {
  //   state.invites.push(payload.invite)
  // },
}