import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      tags: {},
      currentTag: null,
      isLoading: true,
      fetchingUids: [],
    }
  },
  getters,
  actions,
  mutations,
}