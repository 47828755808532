export default {
  isInvitesLoading(state) {
    return state.isLoading;
  },
  isMentionLoading(state) {
    return state.isMentionLoading
  },
  invites(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.list)
  },
  totalCount(state) {
    return state.totalCount
  },
  callOutFromCount(state) {
    return state.callOutFromCount
  },
  callOutToCount(state) {
    return state.callOutToCount
  },
  mentionList(state) {
    return state.mentionList
  },
}