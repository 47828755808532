import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      isVisible: false,
      isLoading: false,
      error: null,
      post: {
        images: [],
        message: {
          "type": "doc",
          "content": [],
        },
        url_preview: {},
        poster_uid: null,
      }
    }
  },
  getters,
  actions,
  mutations,
}