<template>
    <base-spinner v-if="isLoading" class="p-4"/>
    <div v-if="mediaItem?.type === 'image'" :class="wrapperClass">
        <img :src="mediaItem.src" class="d-block img-fluid" @load="loaded" @error="failed" alt="img">
    </div>
    <div v-else-if="mediaItem?.type === 'video'" :class="wrapperClass">
        <base-video-js :video="mediaItem" @loaded="loaded" @toggle-controls="toggleControls"/>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100" v-if="error">{{ error }}</div>
</template>

<script>
  import BaseVideoJs from "../../UI/BaseVideoJs";

    export default {
      name: 'SliderItem',
      props: ['mediaItem', 'toggleControls'],
      components: {BaseVideoJs},
      data() {
        return {
          isLoading: true,
          error: false,
        }
      },
      computed: {
        wrapperClass() {
          if (this.isLoading || this.error) return 'd-none';
          return !this.isLoading && !this.error ? 'align-content-center d-flex h-100 justify-content-center align-items-center' :  '';
        },
      },
      watch: {
        mediaItem() {
          this.error = false;
          this.isLoading = true;
        }
      },
      methods: {
        loaded() {
          this.error = false;
          this.isLoading = false;
        },
        failed() {
          this.error = 'Failed to load Media';
          this.isLoading = false;
        }
      },
    }
</script>