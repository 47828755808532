import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      teammateIcons: null,
      showEnhancedCover: false,
      banners: null,
      teammateUids: null,
      videos: null,
    }
  },
  getters,
  actions,
  mutations,
}
