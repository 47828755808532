import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      photos: [],
      next_index: 0,
    }
  },
  getters,
  actions,
  mutations,
}