<template>
  <div class="nav-item dropdown comment-options ms-2 d-block d-md-none">
    <i class="icon-dots-three-horizontal align-middle pointer text-grey fs-5" title="Options" id="comment-menu-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"></i>
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="comment-menu-toggle" id="comment-menu-dd" style="max-width: 90px; min-width: 80px;">
      <template v-if="isVisible">
        <template v-if="isCommentOwner">
          <li>
            <span @click="toggleEditMode" class="no-decor dropdown-item fs-80 py-1 px-2 pointer">edit</span>
          </li>
          <li>
            <span @click="deleteComment" class="no-decor dropdown-item fs-80 py-1 px-2 pointer">delete</span>
          </li>
        </template>

        <template v-else-if="!isCommentOwner && isOwner">
          <li>
            <a @click="deleteComment" class="no-decor dropdown-item fs-80 py-1 px-2 pointer">delete</a>
          </li>
          <li>
            <a @click="promt = true" class="no-decor dropdown-item fs-80 py-1 px-2 pointer">flag</a>
          </li>
          <li>
            <a @click="hideComment" class="no-decor dropdown-item fs-80 py-1 px-2 pointer">hide</a>
          </li>
        </template>

        <template v-else-if="!isCommentOwner && !isOwner">
          <li>
            <a @click="promt = true" class="no-decor dropdown-item fs-80 py-1 px-2 pointer">flag</a>
          </li>
          <li>
            <a @click="hideComment" class="no-decor dropdown-item fs-80 py-1 px-2 pointer">hide</a>
          </li>
        </template>

        <the-promt v-if="promt"
                   :ok-func="flagComment"
                   :cancel-func="cancelPromt"
                   header-class="border-0 theme-color pb-2"
                   body-class="p-0 border-0"
                   footer-class="justify-content-center border-0 pt-0"
                   :close="cancelPromt"
        >
                <template #header><h5 class="text-center w-100">Flag comment as inappropriate?</h5></template>
                <template #body><p class="text-center text-grey fs-80 mb-3">
                  DualDialogue is community-driven, inappropriate content,<br>
                  users and fake accounts flagged by multiple independent<br>
                  (not socially connected) people is automatically removed from the system.<br>
                  We’ll continuously improve the algorithm with the community.
                </p></template>
                <template #okText>Confirm</template>
                <template #cancelText>Cancel</template>
        </the-promt>
      </template>
    </ul>
  </div>
</template>

<script>
import ddIsVisible from "../../../hooks/ddIsVisible";
import ThePromt from "../../modals/ThePromt";

export default {
  name: "CommentOptions",
  emits: ['toggleEditMode', 'deleteComment', 'flagComment', 'hideComment'],
  mixins: [ddIsVisible],
  components: {ThePromt},
  inject: ['postUid', 'isOwner'], //isOwner - post owner
  props: ['writerUid'],
  data() {
    return {
      promt: false,
    }
  },
  computed: {
    isCommentOwner() {
      return this.$store.state.auth.currentUser.uid === this.writerUid;
    },
  },
  methods: {
    deleteComment(e) {
      e.stopPropagation()
      this.$emit('deleteComment')
    },
    toggleEditMode(e) {
      e.stopPropagation()
      this.$emit('toggleEditMode')
    },
    hideComment(e) {
      e.stopPropagation()
      this.$emit('hideComment')
    },
    cancelPromt(e) {
      e.stopPropagation()
      this.promt = false
    },
    flagComment(e) {
      e.stopPropagation()
      this.$emit('flagComment')
    },
  },
}
</script>
