export default {
  isInvitesLoading(state) {
    return state.invites.isLoading;
  },
  invites(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.invites.list)
  },
  totalInvitesCount(state) {
    return state.invites.totalCount
  },
  callOutFromCount(state) {
    return state.invites.callOutFromCount
  },
  callOutToCount(state) {
    return state.invites.callOutToCount
  },
  mentionList(state) {
    return state.invites.mentionList
  },

//////// // ///////  PHOTOS //////// // ///////
  isPhotosLoading(state) {
    return state.photos.isLoading;
  },
  photos(state) {
    return state.photos.photos
  },

//////// // ///////  TEAMMATES //////// // ///////
  isTeammatesLoading(state) {
    return state.teammates.isLoading;
  },
  teammates(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.teammates.list.slice(0, 9))
  },
  totalTeammatesCount(state) {
    return state.teammates.totalCount;
  },
  leadersCount(state) {
    return state.teammates.leadersCount;
  },
}