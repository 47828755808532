<template>
    {{ pluralized }}
</template>

<script>
  import pluralize from 'pluralize';

  export default {
    props: ['word', 'num'],
    computed: {
      pluralized() {
        return pluralize(this.word, this.num)
      }
    }
  }
</script>