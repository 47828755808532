<template>
  <div v-if="writer" class="fs-70">
    <base-tag-dd-link :uid="writer.uid" class="link-color fw-bold"/>
    <i v-if="writer.verified" class="icon-check-square theme-color"/>
    <base-tag-dd-link v-if="writerTitle" :uid="writer.uid" class="link-main">
      {{ writerTitle }}
    </base-tag-dd-link>
    <base-tag-link v-if="writer.org_uid > 0" :uid='writer.org_uid' class="link-main" />
    <a v-if="source === 'tw'" :href="sourceUrl" class="link-main no-decor float-end" target="_blank" title="Pulled in from twitter."><i class="icon-twitter"></i></a>
  </div>
</template>

<script>
import writerTitle from "@/hooks/writerTitle";

export default {
  name: "CommentHeader",
  props: ['writer', 'source', 'sourceUrl'],
  mixins: [writerTitle],
}
</script>
