<template>
    <teleport to="body">
        <div class="modal" :tabindex="tabIndex" ref="modal" v-bind="$attrs" :class="modalClass">
            <div class="modal-dialog" :class="dialogClass">
                <div class="modal-content" :class="contentClass">
                    <div v-if="$slots.header" class="modal-header" :class="headerClass">
                        <slot name="header"/>
                    </div>

                    <slot v-if="$slots.manual" name="manual"/>

                    <div v-if="!$slots.manual" class="modal-body" :class="bodyClass" >
                        <slot/>
                    </div>

                    <div v-if="$slots.footer" class="modal-footer" :class="footerClass" >
                        <slot name="footer">
                            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                            <button type="button" class="btn btn-primary" @click="submit">Save</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>


<script>
  import { Modal } from 'bootstrap'

    export default {
    name: 'BaseModal',
      props: {
        visible: {
          type: Boolean,
          default: false
        },
        keyboard: {
          type: Boolean,
          default: true
        },
        tabIndex: {
          type: String,
          default: '-1'
        },
        backdrop: {
          type: [Boolean, String],
          default: true
        },
        modalClass: {
          type: String,
          default: 'fade'
        },
        dialogClass: String,
        contentClass: String,
        bodyClass: String,
        headerClass: String,
        footerClass: String,
      },
      emits: ['close','submit'],
      data() {
        return {
          isClosing: false,
        }
      },
      watch:{
        $route (to, from){
          if (to.meta.keepModalOpen && from.meta.keepModalOpen) return

          this.hideModal()
        },
        visible(val) {
          if (!val) {
            this.manuallyHideModal()
          }
        },
      },
      methods: {
        closeModal() {
          this.modal.hide()
        },
        submit() {
          this.$emit('submit');
        },
        showModal() {
          this.modal = new Modal(this.$refs.modal, {keyboard: this.keyboard, backdrop: this.backdrop})
          this.$refs.modal.addEventListener('show.bs.modal', this.onModalShowIn)
          this.modal.show()
          this.$refs.modal.addEventListener('hidden.bs.modal', this.onModalHide)
          this.$refs.modal.addEventListener('hide.bs.modal', this.onModalHideIn)
        },
        onModalHideIn() {
          this.$refs.modal.classList.remove('animating-in')
          this.$refs.modal.classList.add('animating-out')
        },
        onModalShowIn() {
          this.$refs.modal.classList.remove('animating-out')
          this.$refs.modal.classList.add('animating-in')
        },
        onModalHide() {
          if (this.isClosing) return

          this.isClosing = true
          this.$emit('close');
        },
        hideModal() {
          this.modal.hide()
          this.removeEventListeners()
        },
        manuallyHideModal() {
          this.removeEventListeners()
          this.modal.hide()
          this.onModalHide()
        },
        removeEventListeners() {
          this.$refs.modal.removeEventListener('hidden.bs.modal', this.onModalHide, false)
          this.$refs.modal.removeEventListener('hide.bs.modal', this.onModalHideIn, false)
          this.$refs.modal.removeEventListener('show.bs.modal', this.onModalShowIn, false)
        },
      },
      mounted() {
        if (this.visible) {
          this.showModal();
        }
      },
      beforeUnmount() {
        if (this.visible) {
          this.manuallyHideModal();
        }
      }

    }
</script>