<template>
  <span v-for="(uid, index) in tag?.org_uids" :key="uid">
    <base-tag-link :class="linkClass" :uid="uid" /><span v-if="tag.org_uids.length -1 !== index" :class="dotClass">, </span>
  </span>
</template>

<script>
export default {
  name: "BaseTagOrgs",
  props: {
    uid: {
      type: Number,
      required: true,
    },
    dotClass: {
      type: String,
      default: '',
    },
    linkClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    tag() {
      return this.$store.getters['tags/findTag'](this.uid)
    },
  },
}
</script>
