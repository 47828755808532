export default {
  setVariable(state, payload){
    Object.keys(payload).map((key)=> {
      state[key] = payload[key]
    })
  },
  setDarkMode(state, payload) {
    let mode = payload.dark_mode
    if (payload.dark_mode === 'day' || payload.dark_mode === 'night') {
      mode = payload.dark_mode === 'day' ? 'off' : 'on'
    }
    state.dark_mode = mode;
    localStorage.setItem('dark_mode', mode)
  },
  setTheme(state, payload) {
    state.theme = payload.theme;
    localStorage.setItem('theme', state.theme)
  },

}