export default {
  data() {
    return {
      debounceScrollTimeout: null,
    }
  },
  methods: {
    infinityScrollHandler(e) {
      clearTimeout(this.debounceScrollTimeout)
      this.debounceScrollTimeout = setTimeout(() => {
        if (e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop < 100) {
          this.more();
        }
      }, 300)
    },
  },
  unmounted() {
    clearTimeout(this.debounceScrollTimeout)
  },
}