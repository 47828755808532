<template>
  <div class="comment px-2 py-1" :class="{'leader-highlighted': comment.is_leader_highlight}">
    <div class="d-flex position-relative">
      <base-tag-link v-if="writer?.uid" :uid="writer.uid">
        <base-icon :icon="writer.icon" class="rounded-circle tag-icon" />
      </base-tag-link>
      <comment-content
          :comment="comment"
          :writer="writer"
          :edit-mode="editMode"
          :is-loading="isLoading"
          :is-reply="true"
          @toggle-edit-mode="toggleEditMode"
          @update-comment="updateComment"
          @delete-comment="deleteComment"
          @flag-comment="flagComment"
          @hide-comment="hideComment"
          :can-reply="false"
          @react="react"
      />
    </div>
  </div>
</template>

<script>
import comment from "@/hooks/comment";

export default {
  name: "TheReply",
  inject: ['post', 'postUid', 'postTagUid', 'isPostZoom'],
  mixins: [comment],
  props: {
    comment: {
      required: true
    },
    mainCommentUid: {
      type: Number,
      required: true,
    },
    parentCommentUid: Number,
  },
}
</script>
