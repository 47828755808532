import dropImages from "@/hooks/dropImages";

export default {
  mixins: [dropImages],
  data() {
    return {
      promt: false,
      charsCount: 0,
      errorTimeoutId: null,
      isActive: false,
      web_url: null,
      poster: null,
      isWebPreviewLoading: false,
    }
  },
  watch: {
    textBoxError(error) {
      if(error) {
        this.hideErrorWithDelay()
      }
    },
    isEmpty(newVal) {
      //global EDIT MODE
      this.$store.dispatch('setEditMode', !newVal)
    },
  },
  computed: {
    isEmpty() {
      return this.charsCount === 0 && this.images.length === 0 && !this.web_url
    },
  },
  methods: {
    cancelPromt() {
      this.promt = false
    },
    onImageAdd(image) {
      this.images = [...this.images, image]
    },
    onImageRemove(id) {
      this.images = this.images.filter((image)=> { return image.id !== id })
    },
    onImagesSort(newIndex, oldIndex) {
      this.images.splice(newIndex, 0, this.images.splice(oldIndex, 1)[0]);
    },
    onImageLoaded(id, src) {
      this.images.find((img)=>{
        if(img.id === id) {
          img.src = src;
          img.loading = false;
        }
      });
    },
    onImageFailed(id, error) {
      this.onImageRemove(id)
      this.textBoxError = error
    },
    onChangeText(text) {
      this.message = text
    },
    onCharsCountUpdate(num) {
      this.charsCount = num
    },
    addEmoji(emoji) {
      this.$refs.tiptap.addEmoji(emoji)
    },
    async getMeta() {
      this.isWebPreviewLoading = true
      try {
        const webPreview = await this.$store.dispatch('posts/getMeta', this.web_url )
        this.setWebPreview(webPreview)
        this.isWebPreviewLoading = false
      }catch (e) {
        this.addError(e)
        this.isWebPreviewLoading = false
      }
    },
    onLinkPaste(url) {
      if(this.web_url) return
      this.web_url = url
      this.getMeta()
    },
    setWebPreview(urlPreview) {
      this.url_preview = urlPreview
    },
    removeWebPreviewImage() {
      this.url_preview.image = null
    },
    removeWebPreview() {
      this.url_preview = null
      this.web_url = null
    },
    hideErrorWithDelay() {
      clearTimeout(this.errorTimeoutId)
      this.errorTimeoutId = setTimeout(this.hideError, 3000)
    },
  },
  beforeUnmount() {
    clearTimeout(this.errorTimeoutId)
  },
}