<template>
  <div class="comment-input mb-2 position-relative">
    <div class="py-1"></div>
    <p class="m-0 ms-2 text-around-comment-input text-grey fs-60">Leaders' dialogue highlighted above - if {{parentCommentUid ? 'reply' : 'comment'}} here.</p>
    <div class="comment-input-inner">
      <div class="d-flex">
        <img class="me-2 avatar rounded-circle" :src="imgPath + '/default-user.png'" alt="img" loading="lazy">

        <div class="input-group theme-border rounded">
          <span class="fs-110 ps-2 input-arrow-prefix comment-input-bg rounded">></span>
          <input class="form-control border-0 comment-input-bg text-truncate fs-110 ps-1" contenteditable="true" :placeholder="commentsPlaceholder"/>
          <span class="input-group-text comment-input-bg rounded-end border-0">
            <span class="lh-1 me-2">
              <i class="icon-smile link-main fs-4"/>
            </span>
             <span class="lh-1 me-1">
              <i class="icon-image link-main fs-4"/>
            </span>
          </span>
        </div>
      </div>
    </div>
    <p class="m-0 ms-2 text-around-comment-input text-grey fs-60">
      Teammates' dialogue below - every mention counts.
    </p>
  </div>
</template>

<script>
export default {
  name: "DisabledCommentInput",
  props: ['parentCommentUid']
}
</script>
