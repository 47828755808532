<template>
  <div class="dropdown twemoji-dd" ref="twemojiDD" v-bind="{class: $attrs.class}">
    <i class="icon-smile link-main" :class="iconClass" ref="iconSmile" @click="showDD" data-bs-toggle="dropdown" id="twemojiDDLink"></i>
    <div class="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="twemojiDDLink" >
      <div class="twemoji-picker" v-if="visible" @click.prevent.stop="">
        <div class="twemoji-category body-bg">
          <span v-for="headerItem in headerList" :key='headerItem.group' @click="()=> { selectCategory(headerItem.group) }" :class="{'active': selectedGroup === headerItem.group}">{{headerItem.value}}</span>
        </div>
        <div class="emoji-recent clearfix" ref="recentList"><div class="text-grey fs-80">Recently Used</div>
          <span v-for="item in recent" :key="item.name" v-html="item.value" @click="selectEmoji(item.name, item.value, $event)"></span>
        </div>
        <div>
          <input v-model="emojiQuery"
                 placeholder="Find emojis by name or description"
                 class="form-control form-control-sm rounded-0"
          />
        </div>

        <div class="emoji-list main-bg">
          <base-spinner v-if="isLoading"/>
          <div v-show="!isLoading">
            <span v-for="(item) in filteredEmojiList" :key="item.name"
                  v-show="(emojiQuery && item.visible) || !emojiQuery"
                  v-html="item.value"
                  :id="item.name"
                  :title="item.title"
                  @click="selectEmoji(item.name, item.value, $event)"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="twemoji-overlay" v-if="visible"></div>
</template>

<script>
import twemoji from 'twemoji';
import {Dropdown} from 'bootstrap';
import BaseSpinner from "@/components/UI/BaseSpinner";

export default {
  components: {BaseSpinner},
  emits: ['onSelect', 'onShow', 'onHide'],
  props: {
    iconClass: {
      type: String,
      default: '',
    },
    identifier: {
      type: String,
      default: '',
    }
  },
  created() {
    if(localStorage.getItem('recent-emoji')) {
      this.recent = JSON.parse( localStorage.getItem('recent-emoji') )
    }
  },
  mounted() {
    this.$refs.twemojiDD.addEventListener('show.bs.dropdown', this.parseEmoji)
    this.$refs.twemojiDD.addEventListener('hidden.bs.dropdown', this.hideDD)
  },
  beforeUnmount() {
    this.$refs.twemojiDD.removeEventListener('show.bs.dropdown', this.parseEmoji)
    this.$refs.twemojiDD.removeEventListener('hidden.bs.dropdown', this.hideDD)
  },
  data() {
    return {
      isLoading: false,
      visible: false,
      emojiQuery: '',
      selectedGroup: 0,
      headerList: [],
      list: [],
      recent: [],
    }
  },
  computed: {
    filteredEmojiList() {
      if (this.emojiQuery) {
        const reg = new RegExp(`${this.emojiQuery}`, "g")
        this.list[this.selectedGroup].map((item) => item.visible = reg.test(item.title))
      }
      return this.list[this.selectedGroup]
    },
  },
  methods: {
    async getEmojiList() {
      if (this.list[0]) {
        return
      }

      this.isLoading = true
      const response = await fetch(
          'https://cdn.jsdelivr.net/npm/emoji-picker-element-data@latest/en/emojibase/data.json'
      )
      const responseData = await response.json()
      this.list = responseData.reduce((memo, emjData) => {
        if (!memo[emjData.group]) {
          memo[emjData.group] = []
          this.headerList.push({group: emjData.group, value: emjData.emoji, title: emjData.annotation})
        }
        memo[emjData.group].push({name: emjData.shortcodes[0], value: emjData.emoji, visible: true, title: emjData.annotation})
        return memo
      }, {})
      this.isLoading = false
    },
    async showDD() {
      this.visible = true;
      await this.getEmojiList()
      this.parseEmoji()
      this.$emit('onShow')
      setTimeout(() => {
        const ddEl = Dropdown.getInstance(this.$refs.iconSmile)
        ddEl.update();
      }, 1)
    },
    hideDD() {
      this.visible = false;
      this.$emit('onHide')
    },
    parseEmoji() {
      twemoji.parse(
          this.$refs.twemojiDD,
          { base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/' }
      )
    },
    selectCategory(category) {
      this.selectedGroup = category;
      this.$nextTick(() => this.parseEmoji())
    },
    selectEmoji(name, code, e) {
      const img = e.currentTarget.querySelector('img')
      this.$emit('onSelect', {src: img.src, alt: img.src, code, identifier: this.identifier})
      this.addToRecent(name, code)
    },
    addToRecent(name, code) {
      const exist_index = this.recent.findIndex( (item) => { return item.name === name } )
      if(this.recent.length === 20 && exist_index < 0) {
        this.recent.pop()
      }
      if(exist_index >= 0) {
        this.recent = this.recent.filter((item)=> {return item.name !== name} )
      }
      this.recent.unshift({name: name, value: code})
      localStorage.setItem( 'recent-emoji', JSON.stringify(this.recent) )
      setTimeout(()=> {
        twemoji.parse(
            this.$refs.recentList,
            { base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/' })
      },1)
    },
  },
}
</script>
