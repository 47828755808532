import PullToRefresh from "pulltorefreshjs";
import getPosts from "@/hooks/getPosts";


export default {
  mixins: [getPosts],

  mounted() {
    this.setPullToRefresh()
  },
  beforeUnmount() {
    PullToRefresh.destroyAll();
  },

  methods: {
    setPullToRefresh() {
      PullToRefresh.init({
        mainElement: 'body',
        iconRefreshing: "<div class=\"spinner-border spinner-border-sm text-white\" role=\"status\">\n" +
          "  <span class=\"visually-hidden\">Loading...</span>\n" +
          "</div>",
        iconArrow: ' ',
        instructionsRefreshing: ' ',
        instructionsPullToRefresh: ' ',
        instructionsReleaseToRefresh: ' ',
        onRefresh: () => {
          this.$store.dispatch('posts/clearPosts');
          this.getPosts()
        },
        shouldPullToRefresh: () => {
          return !document.querySelector('.dropdown-menu.show') && !window.scrollY && !document.querySelector('.sidebar-main')
        },
      });
    },
  },
}