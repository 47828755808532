"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.Suggestion = exports.SuggestionPluginKey = void 0;
var state_1 = require("@tiptap/pm/state");
var view_1 = require("@tiptap/pm/view");
var findSuggestionMatch_1 = require("./findSuggestionMatch");
exports.SuggestionPluginKey = new state_1.PluginKey('suggestion');
function Suggestion(_a) {
    var _b = _a.pluginKey, pluginKey = _b === void 0 ? exports.SuggestionPluginKey : _b, editor = _a.editor, _c = _a.char, char = _c === void 0 ? '@' : _c, _d = _a.allowSpaces, allowSpaces = _d === void 0 ? false : _d, _e = _a.allowedPrefixes, allowedPrefixes = _e === void 0 ? [' '] : _e, _f = _a.startOfLine, startOfLine = _f === void 0 ? false : _f, _g = _a.decorationTag, decorationTag = _g === void 0 ? 'span' : _g, _h = _a.decorationClass, decorationClass = _h === void 0 ? 'suggestion' : _h, _j = _a.command, command = _j === void 0 ? function () { return null; } : _j, _k = _a.items, items = _k === void 0 ? function () { return []; } : _k, _l = _a.render, render = _l === void 0 ? function () { return ({}); } : _l, _m = _a.allow, allow = _m === void 0 ? function () { return true; } : _m;
    var props;
    var renderer = render === null || render === void 0 ? void 0 : render();
    var plugin = new state_1.Plugin({
        key: pluginKey,
        view: function () {
            var _this = this;
            return {
                update: function (view, prevState) { return __awaiter(_this, void 0, void 0, function () {
                    var prev, next, moved, started, stopped, changed, handleStart, handleChange, handleExit, state, decorationNode, _a;
                    var _this = this;
                    var _b, _c, _d, _e, _f, _g, _h;
                    return __generator(this, function (_j) {
                        switch (_j.label) {
                            case 0:
                                prev = (_b = this.key) === null || _b === void 0 ? void 0 : _b.getState(prevState);
                                next = (_c = this.key) === null || _c === void 0 ? void 0 : _c.getState(view.state);
                                moved = prev.active && next.active && prev.range.from !== next.range.from;
                                started = !prev.active && next.active;
                                stopped = prev.active && !next.active;
                                changed = !started && !stopped && prev.query !== next.query;
                                handleStart = started || moved;
                                handleChange = changed && !moved;
                                handleExit = stopped || moved;
                                // Cancel when suggestion isn't active
                                if (!handleStart && !handleChange && !handleExit) {
                                    return [2 /*return*/];
                                }
                                state = handleExit && !handleStart ? prev : next;
                                decorationNode = view.dom.querySelector("[data-decoration-id=\"".concat(state.decorationId, "\"]"));
                                props = {
                                    editor: editor,
                                    range: state.range,
                                    query: state.query,
                                    text: state.text,
                                    items: [],
                                    command: function (commandProps) {
                                        command({
                                            editor: editor,
                                            range: state.range,
                                            props: commandProps
                                        });
                                    },
                                    decorationNode: decorationNode,
                                    // virtual node for popper.js or tippy.js
                                    // this can be used for building popups without a DOM node
                                    clientRect: decorationNode
                                        ? function () {
                                            var _a;
                                            // because of `items` can be asynchrounous we’ll search for the current decoration node
                                            var decorationId = ((_a = _this.key) === null || _a === void 0 ? void 0 : _a.getState(editor.state)).decorationId; // eslint-disable-line
                                            var currentDecorationNode = view.dom.querySelector("[data-decoration-id=\"".concat(decorationId, "\"]"));
                                            return (currentDecorationNode === null || currentDecorationNode === void 0 ? void 0 : currentDecorationNode.getBoundingClientRect()) || null;
                                        }
                                        : null
                                };
                                if (handleStart) {
                                    (_d = renderer === null || renderer === void 0 ? void 0 : renderer.onBeforeStart) === null || _d === void 0 ? void 0 : _d.call(renderer, props);
                                }
                                if (handleChange) {
                                    (_e = renderer === null || renderer === void 0 ? void 0 : renderer.onBeforeUpdate) === null || _e === void 0 ? void 0 : _e.call(renderer, props);
                                }
                                if (!(handleChange || handleStart)) return [3 /*break*/, 2];
                                _a = props;
                                return [4 /*yield*/, items({
                                        editor: editor,
                                        query: state.query
                                    })];
                            case 1:
                                _a.items = _j.sent();
                                _j.label = 2;
                                if (handleExit) {
                                    (_f = renderer === null || renderer === void 0 ? void 0 : renderer.onExit) === null || _f === void 0 ? void 0 : _f.call(renderer, props);
                                }
                                if (handleChange) {
                                    (_g = renderer === null || renderer === void 0 ? void 0 : renderer.onUpdate) === null || _g === void 0 ? void 0 : _g.call(renderer, props);
                                }
                                if (handleStart) {
                                    (_h = renderer === null || renderer === void 0 ? void 0 : renderer.onStart) === null || _h === void 0 ? void 0 : _h.call(renderer, props);
                                }
                                return [2 /*return*/];
                            case 2:
                                if (handleExit) {
                                    (_f = renderer === null || renderer === void 0 ? void 0 : renderer.onExit) === null || _f === void 0 ? void 0 : _f.call(renderer, props);
                                }
                                if (handleChange) {
                                    (_g = renderer === null || renderer === void 0 ? void 0 : renderer.onUpdate) === null || _g === void 0 ? void 0 : _g.call(renderer, props);
                                }
                                if (handleStart) {
                                    (_h = renderer === null || renderer === void 0 ? void 0 : renderer.onStart) === null || _h === void 0 ? void 0 : _h.call(renderer, props);
                                }
                                return [2 /*return*/];
                        }
                    });
                }); },
                destroy: function () {
                    var _a;
                    if (!props) {
                        return;
                    }
                    (_a = renderer === null || renderer === void 0 ? void 0 : renderer.onExit) === null || _a === void 0 ? void 0 : _a.call(renderer, props);
                }
            };
        },
        state: {
            // Initialize the plugin's internal state.
            init: function () {
                var state = {
                    active: false,
                    range: {
                        from: 0,
                        to: 0
                    },
                    query: null,
                    text: null,
                    composing: false
                };
                return state;
            },
            // Apply changes to the plugin state from a view transaction.
            apply: function (transaction, prev, oldState, state) {
                var isEditable = editor.isEditable;
                var composing = editor.view.composing;
                var selection = transaction.selection;
                var empty = selection.empty, from = selection.from;
                var next = __assign({}, prev);
                next.composing = composing;
                // We can only be suggesting if the view is editable, and:
                //   * there is no selection, or
                //   * a composition is active (see: https://github.com/ueberdosis/tiptap/issues/1449)
                if (isEditable && (empty || editor.view.composing)) {
                    // Reset active state if we just left the previous suggestion range
                    if ((from < prev.range.from || from > prev.range.to) && !composing && !prev.composing) {
                        next.active = false;
                    }
                    // Try to match against where our cursor currently is
                    var match = (0, findSuggestionMatch_1.findSuggestionMatch)({
                        char: char,
                        allowSpaces: allowSpaces,
                        allowedPrefixes: allowedPrefixes,
                        startOfLine: startOfLine,
                        $position: selection.$from
                    });
                    var decorationId = "id_".concat(Math.floor(Math.random() * 0xffffffff));
                    // If we found a match, update the current state to show it
                    if (match && allow({ editor: editor, state: state, range: match.range })) {
                        next.active = true;
                        next.decorationId = prev.decorationId ? prev.decorationId : decorationId;
                        next.range = match.range;
                        next.query = match.query;
                        next.text = match.text;
                    }
                    else {
                        next.active = false;
                    }
                }
                else {
                    next.active = false;
                }
                // Make sure to empty the range if suggestion is inactive
                if (!next.active) {
                    next.decorationId = null;
                    next.range = { from: 0, to: 0 };
                    next.query = null;
                    next.text = null;
                }
                return next;
            }
        },
        props: {
            // Call the keydown hook if suggestion is active.
            handleKeyDown: function (view, event) {
                var _a;
                var _b = plugin.getState(view.state), active = _b.active, range = _b.range;
                if (!active) {
                    return false;
                }
                return ((_a = renderer === null || renderer === void 0 ? void 0 : renderer.onKeyDown) === null || _a === void 0 ? void 0 : _a.call(renderer, { view: view, event: event, range: range })) || false;
            },
            // Setup decorator on the currently active suggestion.
            decorations: function (state) {
                var _a = plugin.getState(state), active = _a.active, range = _a.range, decorationId = _a.decorationId;
                if (!active) {
                    return null;
                }
                return view_1.DecorationSet.create(state.doc, [
                    view_1.Decoration.inline(range.from, range.to, {
                        nodeName: decorationTag,
                        "class": decorationClass,
                        'data-decoration-id': decorationId
                    }),
                ]);
            }
        }
    });
    return plugin;
}
exports.Suggestion = Suggestion;
