<template>
    <div class="d-flex align-items-center" id="splash">
      <h1 class="mx-auto d-flex fw-bold position-relative">
        <div class="d-flex flex-column justify-content-between align-items-end">
          <span class="us">US</span>
          <span class="them">THEM</span>
        </div>
        <div class="position-relative splash-arrows-container">
          <svg class="splash-dotted-arrow position-absolute top-0 start-0" xmlns="http://www.w3.org/2000/svg" width="150" height="160" viewBox="0 0 150 150">
            <path d="M0,155.559H0V119.84L104.469,77.811l-19-7.67V35.709l65.463,27.2V92.851L0,155.559ZM19.966,121.5a7.5,7.5,0,1,0,7.5,7.5A7.509,7.509,0,0,0,19.966,121.5Zm20-8a7.5,7.5,0,1,0,7.5,7.5A7.509,7.509,0,0,0,39.965,113.5Zm20-8a7.5,7.5,0,1,0,7.5,7.5A7.509,7.509,0,0,0,59.965,105.5Zm20-8a7.5,7.5,0,1,0,7.5,7.5A7.508,7.508,0,0,0,79.965,97.5Zm.5-29.377h0l-9-3.632v-34.6l9,3.739V68.122Zm-14-5.65h0l-9-3.632V24.075l9,3.739V62.472Zm-14-5.65h0l-9-3.632V18.258l9,3.739V56.822Zm-14-5.65h0l-9-3.632v-35.1l9,3.739v34.99Zm-14-5.65h0l-9-3.632V6.625l9,3.739V45.521Zm-14-5.65h0L0,35.649V.2L10.465,4.548V39.871Z" transform="translate(0 -0.2)"/>
          </svg>
          <div class="splash-solid-arrow">
            <svg class="" width="50.6" height="51.9" viewBox="0 0 50.6 51.9" xmlns="http://www.w3.org/2000/svg"><g id="svgGroup" stroke-linecap="round" fill-rule="evenodd" font-size="9pt" stroke-width="0.25mm" style="stroke-width:0.25mm;"><path d="M 33.8 26.1 L 0 39.8 L 0 51.9 L 50.6 31.4 L 50.6 20.8 L 0 0 L 0 12.4 L 33.8 26.1 Z" vector-effect="non-scaling-stroke"/></g></svg>
          </div>
        </div>
        <span class="align-self-center we">WE</span>
      </h1>
    </div>
</template>

<script>
  export default {
    props: [ 'finishAnimation' ],
    mounted() {
      setTimeout( () => this.finishAnimation(), 2400)
    },
  }
</script>