<template>
  <router-link :to="{ name: 'tag', params: {slug: tag?.tag} }">
     <slot>{{ tag?.display_name }}</slot>
  </router-link>
</template>

<script>
  import fetchTagIfNotFound from "../../hooks/fetchTagIfNotFound";

  export default {
    mixins: [fetchTagIfNotFound]
  }
</script>