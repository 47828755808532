<template>
  <editor-content :editor="editor" class="tiptap"></editor-content>
</template>

<script>
  import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-3'
  import {allRegexp} from "@/functions/global";
  import tippy from 'tippy.js'
  import Document from '@tiptap/extension-document'
  import Text from '@tiptap/extension-text'
  import Placeholder from '@tiptap/extension-placeholder'
  import Mention from '../../extendedLibs/customMentons'
  import Paragraph from '@tiptap/extension-paragraph'
  import History from '@tiptap/extension-history'
  import Link from '../../extendedLibs/customLinks'
  import Twemoji from '../../extendedLibs/twemoji'
  import CharacterCount from '@tiptap/extension-character-count'
  import MentionList from './MentionList'

  export default {
    emits: ['onChangeText', 'onLinkPaste', 'onCharsCountUpdate', 'onEnterKey', 'onBlur'],
    props: {
      placeholder: {
        type: String,
      },
      content: {
        type: String,
        default: null
      },
      limit: {
        type: Number,
      },
      isComment: {
        type: Boolean,
        default: false
      },
      autoFocus: {
        type: Boolean,
        default: false
      },
      editable: {
        type: Boolean,
        default: true,
      },
    },
    components: { EditorContent, },
    data() {
      return {
        editor: null,
        isMentionActive: false,
        searchTimeout: null,
        searchQuery: '',
      }
    },
    watch: {
      placeholder(newVal, oldVal) {
        if (!this.editor || newVal === oldVal) return
        const p = this.editor.options.element.querySelector('p[data-placeholder]')
        if (!p) return
        p.dataset.placeholder = newVal
      },
      charsCount(newVal, oldVal) {
        if (newVal === oldVal) return
        this.$emit('onCharsCountUpdate', newVal)
      },
      editable() {
        this.editor.setEditable(this.editable)
        if (this.editable) {
          this.$nextTick(this.setCursorAtEnd)
        }
      },
      content() {
        this.setContent()
      },
    },
    mounted() {
      let editorProps = {}
      if(this.isComment) {
        editorProps = {
          handleKeyDown: (_view, event) => {
            if(this.isMentionActive) {
              if (!this.isLoading && !this.hasMentions && event.key === 'Enter' && !event.shiftKey) {
                this.$emit('onEnterKey')
                return true
              }
              return
            }
            if (event.key === 'Enter' && !event.shiftKey) {
              this.$emit('onEnterKey')
              return true
            } else if (event.key === 'Enter' && event.shiftKey) {
              this.editor.commands.insertContent([ { type: 'paragraph', }])
              return false
            }
          },
        }
      }
      const content = this.generateContent()

      this.editor = new Editor({
        editable: this.editable,
        content: content,
        injectCSS: false,
        autofocus: this.autoFocus ? 'end' : false,
        extensions: [
          Document,
          Text,
          Placeholder.configure({
            placeholder: () => { return this.placeholder},
          }),
          Paragraph,
          History,
          Twemoji.configure({
            HTMLAttributes: {
              class: 'emoji',
            },
            inline: true,
            draggable: false,
            selectable: true,
          }),
          CharacterCount.configure({
            limit: this.limit || 99999
          }),
          Link.configure({
            HTMLAttributes: {
              class: 'link-main',
            },
            openOnClick: false,
            validate: (href) => {
              return href !== 'x.com'
            },
            onLinkPaste: (link) => {
              this.$emit('onLinkPaste', link)
            }
          }),
          Mention.configure({
            HTMLAttributes: {
              class: 'dl-mention',
            },
            suggestion: {
              allowSpaces: true,
              render: () => {
                let component
                let popup

                return {
                  onStart: props => {
                    this.isMentionActive = true
                    component = new VueRenderer(MentionList, {
                      props: props,
                      editor: this.editor,
                    })

                    popup = tippy('body', {
                      getReferenceClientRect: props.clientRect,
                      appendTo: () => document.body,
                      content: component.element,
                      showOnCreate: true,
                      interactive: true,
                      trigger: 'manual',
                      placement: 'bottom-start',
                    })
                  },
                  onUpdate: (props) => {
                    this.searchQuery = props.query
                    clearTimeout(this.searchTimeout)
                    this.searchTimeout = setTimeout(() => {
                      this.fetchMentions()
                      component.updateProps(props)

                      popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                      })
                    }, 500)
                  },
                  onKeyDown(props) {
                    return component.ref?.onKeyDown(props)
                  },
                  onExit: () => {
                    this.isMentionActive = false
                    popup[0].destroy()
                    component.destroy()
                    this.clearMentions()
                  },
                }
              },
            },

          }),
        ],
        onUpdate: () => {
          this.$emit('onChangeText', this.editor.getJSON())
        },
        onBlur: () => {
          this.$emit('onBlur')
        },
        editorProps: editorProps,
      })
    },
    computed: {
      charsCount() {
        if(!this.editor) return
        return this.editor.storage.characterCount.characters()
      },
      hasMentions() {
        return this.$store.getters['invites/mentionList']?.length > 0
      },
      isLoading() {
        return this.$store.getters['invites/isMentionLoading']
      },
    },
    methods: {
      async fetchMentions() {
        try {
          await this.$store.dispatch('invites/getMentions', {query: this.searchQuery});
        } catch (error) {
          this.error = error.message || "InvitesForm:fetchMentions: failed!";
        }
      },
      clearMentions() {
        this.$store.dispatch('invites/clearMentions');
      },
      addEmoji(emoji) {
        // emoji = {alt, src}
        this.editor.commands.setImage(emoji)
      },
      addMention({uid, displayName}) {
        this.editor.chain().focus().insertContent([
          { type: 'mention', attrs: {uid: uid, label: displayName}, },
        ]).run()
        //this.editor.commands.insertContent("\u00a0")
      },
      clearAll() {
        this.editor.commands.clearContent()
      },
      blurEditor() {
        this.editor.commands.blur()
      },
      focusEditor() {
        this.editor.commands.focus()
      },
      setCursorAtEnd() {
        this.editor.chain().focus().setTextSelection(999999).run()
      },
      setContent() {
        this.editor.commands.setContent(this.generateContent())
      },
      generateContent() {
        try {
          return JSON.parse(this.content)
        } catch {
          return  this.content.replace(allRegexp.url, (url)=> {
            let hyperlink = url.replace(/\.$/, '')
            if (!/^https?:\/\//.test(url)) {
              hyperlink = 'https://' + url;
            }
            return `<a href="${hyperlink}" rel="noopener noreferrer nofollow" target="_blank" class="link-main">${url}</a>`
          })
        }
      },
    },

    beforeUnmount() {
      this.editor.destroy()
      clearTimeout(this.searchTimeout)
      this.searchQuery = ''
    },
  }
</script>