export const DD_COMPONENTS = {
  'user-reactions': 'user-reactions-dd',
  'invites-to': 'invites-to-dd',
  'invites-from': 'invites-from-dd',
  'people-invited': 'people-invited-dd',
  'member-of-teams': 'member-of-teams-dd',
  'teammates': 'teammates-dd',
  'mutual-teammates': 'mutual-teammates-dd',
  'teammates-invites': 'teammates-invites-dd',
  'comment-reactions': 'comment-reactions-list-dd',
  'notice-teammates': 'notice-teammates-dd',
  'resolving-conflict': 'resolving-conflict-dd',
  'followed-by': 'followed-by-dd',
  'people-like': 'people-like-dd',
  'teammates-like': 'teammates-like-dd',
  'who-commented': 'who-commented-dd',
  'mention-list': 'mention-list-dd',
}