<template>
  <div class="toast show notice" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header text-inverse theme-bg">
      <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close"></button>
    </div>
    <div class="toast-body p-0">
      <notices-item :notice="notice"/>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
  name: "ToastItem",
  components: {
    NoticesItem: defineAsyncComponent(() =>
        import("../../header/notices/NoticesItem")
    ),
  },
  props: {
    notice: Object,
  },
  data() {
    return {
      timeoutId: null,
      timeoutMS: 6000,
      isLoading: false,
    }
  },
  mounted() {
    this.timeoutId = setTimeout(()=> {
      this.hide()
    }, this.timeoutMS)
  },
  methods: {
    close() {
      clearTimeout(this.timeoutId);
      this.$store.dispatch('auth/toggleReadStatus', {
        postUid: this.notice.post_uid,
        unread: this.notice.unread,
        eventUid: this.notice.event_uid,
      })
    },
    hide() {
      clearTimeout(this.timeoutId);
      this.$store.commit('auth/toggleToastReadStatus', {
        eventUid: this.notice.event_uid,
      })
    },
  },
}
</script>
