<template>
    <img :src="iconUrl" alt="img" loading="lazy">
</template>

<script>
export default {
  name: "BaseIcon",
  props: ['icon'],
  computed: {
    iconUrl() {
      return (/(syscolor)/.test( this.icon ) ? this.icon.replace(/(syscolor)/, this.$store.state.config.theme) : this.icon) || this.imgPath + '/default-user.png'
    }
  }
}
</script>