<template>
  <div class="text-grey clearfix py-1 border-start theme-border-color ps-2">
     <span v-if="parentCommentIsPriority" @click="toggleIsCollapsed" class="align-middle pointer position-relative">
      <i class="fs-4 theme-color align-middle collapse-replies me-2 icon-plus-outline"></i>
    </span>
    <span class="align-middle">
      <span v-if="totalCount > 0">
        <base-numeral :num="totalCount"/> <base-pluralize :num="totalCount" word="Reply"/> from
        <base-dd v-if="stats.leaders_count > 0" dd="who-commented" :uids="leadersImg" who-commented="leader">
          {{stats.leaders_count > 1 ? "leaders'" : "leader's"}}
        </base-dd>{{stats.leaders_count  > 0 && stats.teammates_count  > 0 ? ',' : ''}}
        <base-dd v-if="stats.teammates_count > 0" dd="who-commented" :uids="teammatesImg" who-commented="teammate">
          {{ stats.teammates_count > 1 ? "teammates'" : "teammate's"}}
        </base-dd>
        <span v-if="stats.people_count > 0">
          {{stats.teammates_count > 0 || stats.leaders_count > 0 ? 'and' : ''}}
          Peoples'
        </span>
        dialogue
        <span v-if="stats.leaders_count > 0" class="comment-stats-icons d-inline-flex align-middle mx-2">
          <comment-stats-image v-for="uid in leadersImg" :uid="uid" :key="uid"/>
        </span>
        <span v-if="stats.teammates_count > 0" class="comment-stats-icons d-inline-flex align-middle me-2">
          <comment-stats-image v-for="uid in teammatesImg" :uid="uid" :key="uid"/>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import CommentStatsImage from "@/components/feed/partials/CommentStatsImage";

export default {
  name: "RepliesCollapsedStats",
  emits: ['toggle-is-collapsed'],
  components: {CommentStatsImage},
  props: {
    stats: {
      default: {}
    },
    isInitialState: Boolean,
    parentCommentIsPriority: Boolean,
    isCollapsed: Boolean,
  },
  computed: {
    teammatesImg() {
      return this.stats.teammates.slice(0, 3)
    },
    leadersImg() {
      return this.stats.leaders.slice(0, 3)
    },
    totalCount() {
      return this.stats.total_count;
    },
    priorityCount() {
      return this.stats.priority_count;
    },
  },
  methods: {
    toggleIsCollapsed() {
      this.$emit('toggle-is-collapsed')
    }
  },
}
</script>
