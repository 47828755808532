import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import globalProps from "@/globalProps";

export default {
  namespaced: true,
  state() {
    return {
      appIosLink: '#',
      subscriptionAllowed: true,
      feedLimit: 10,
      // commentsLimit: 6,
      updatesDelay: Number(window.config.VUE_APP_UPDATES_INTERVAL),
      // recentInvitesInterval: Number(window.config.VUE_APP_RECENT_INVITES_INTERVAL),
      liveCommentsCheckInterval: 5000,
      liveTypingPingInterval: 2000,
      videoAutoplay: true,
      subscriptionEnabled: false,
      coverVideoDelay: 2000,
      showTagShare: false,
      showPages: true,
      showBottomVideo: false,
      tagifyLimit: 6,
      theme: localStorage.getItem('theme') || globalProps.defaultThemeColor,
      dark_mode: localStorage.getItem('dark_mode') || 'off',
      innerTagUids: [1, 3, 4, 5, 6, 7, 9, 18],
    }
  },
  getters,
  actions,
  mutations
}