export default {
  setInvitesLoading(state, payload) {
    state.invites.isLoading = payload;
  },
  setInvites(state, payload) {
    state.invites.list = state.invites.list.concat(payload.list);
    state.invites.nextIndex = payload.next_index
    state.invites.totalCount = payload.total_count
    state.invites.callOutFromCount = payload.call_out_from_count
    state.invites.callOutToCount = payload.call_out_to_count
  },
  // removeByPostUid(state, payload) {
  //   //TODO ALEX REWRITE. where to get invite.invite_post_uid  AND DO WE NEED THAT AT ALL????
  //   const filteredInvites = state.invites.list.filter(invite => invite.invite_post_uid !== payload.postUid)
  //   if(state.invites.list.length > filteredInvites.length) {
  //     state.invites.totalCount = state.invites.totalCount - 1
  //     state.invites.nextIndex = state.invites.nextIndex - 1
  //   }
  //   state.invites.list = filteredInvites
  // },
  clearInvites(state) {
      state.invites.list = [];
      state.invites.nextIndex = null;
      state.invites.totalCount = null;
      state.invites.callOutFromCount = null;
      state.invites.callOutToCount = null;
      state.invites.mutual_invite_uids = null;
  },

////////////////////////  PHOTOS  ////////////////////////

  setPhotosData(state, {photos, next_index}) {
    state.photos.photos = state.photos.photos.concat(photos);
    state.photos.nextIndex = next_index;
  },
  setPhotosLoading(state, payload) {
    state.photos.isLoading = payload;
  },
  addPhotos(state, payload) {
    const newPhotos = payload.images.reduce((memo, imgObj, index) => {
      memo.push({photo_index: index, post_uid: payload.postUid, src: imgObj.src})
      return memo
    }, [])
    state.photos.photos = newPhotos.concat(state.photos.photos)
  },
  removePhotosByPostUid(state, payload) {
    const filteredPhotos = state.photos.photos.filter(photo =>  photo.post_uid !== payload.postUid );
    if (filteredPhotos.length === 0) return

    state.photos.nextIndex -= state.photos.photos.length - filteredPhotos.length
    state.photos.photos = filteredPhotos;
  },
  removePhoto(state, payload) {
    state.photos.photos = state.photos.photos.filter(photo =>  photo.src !== payload.src );
  },
  clearPhotosData(state) {
    state.photos.isLoading = true;
    state.photos.photos = [];
    state.photos.nextIndex = 0;
  },

////////////////////////  TEAMMATES  ////////////////////////

  setTeammatesLoading(state, payload) {
    state.teammates.isLoading = payload;
  },
  setTeammatesData(state, {list, next_index, total_count, leaders_count, prevUrl}) {
    state.teammates.list = state.teammates.list.concat(list);
    state.teammates.nextIndex = next_index;
    state.teammates.totalCount = total_count;
    state.teammates.leadersCount = leaders_count;
    state.teammates.prevUrl = prevUrl;
  },
  addTeammate(state, {uid, next_index, total_count}) {
    state.teammates.list = [uid, ...state.teammates.list];
    state.teammates.nextIndex = next_index;
    state.teammates.totalCount = total_count;
  },
  removeTeammate(state, payload) {
    state.teammates.list = state.teammates.list.filter(uid =>  uid !== payload.uid );
    state.teammates.nextIndex -= 1;
    state.teammates.totalCount -= 1;
  },
  clearTeammatesData(state) {
    state.teammates.list = [];
    state.teammates.nextIndex = null;
    state.teammates.totalCount = null;
    state.teammates.leadersCount = null;
    state.teammates.prevUrl = null;
  }
}