<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 363.553 333.561" :class="['heart-icon', 'theme-color', isAnimated && 'animated', size]">
    <g id="Group_1845" data-name="Group 1845" transform="translate(21024.568 23957.281)">
      <g id="SVGRepo_iconCarrier" transform="translate(-21027.568 -23961.281)">
        <path id="Path_374" data-name="Path 374" d="M184.777,41.991C204.591,18.724,234.947,4,266.576,4c55.987,0,99.977,43.99,99.977,99.977,0,68.655-61.7,124.608-155.189,209.381l-.23.208-26.358,23.995-26.358-23.813-.717-.652C64.488,228.389,3,172.513,3,103.977,3,47.99,46.99,4,102.977,4,134.606,4,164.963,18.724,184.777,41.991Z" transform="translate(0)"/>
      </g>
      <g id="Group_1844" data-name="Group 1844" transform="translate(2023.184 1.001)">
        <g id="Group_1831" data-name="Group 1831" transform="translate(-180 -57)">
          <path id="Path_373" data-name="Path 373" d="M0,52.067a1,1,0,0,1-.378-.075,1,1,0,0,1-.548-1.3L19.934-.378a1,1,0,0,1,1.3-.548,1,1,0,0,1,.548,1.3L.926,51.445A1,1,0,0,1,0,52.067Z" transform="translate(-22707.5 -23863.566)" fill="#fff"/>
          <path id="Line_559" data-name="Line 559" d="M53,80a1,1,0,0,1-.831-.443l-53-79A1,1,0,0,1-.557-.83,1,1,0,0,1,.83-.557l53,79A1,1,0,0,1,53,80Z" transform="translate(-22707.5 -23812.5)" fill="#fff"/>
        </g>
        <g id="Group_1830" data-name="Group 1830" transform="translate(-180 -55.554)">
          <text id="_" data-name="&gt;" transform="translate(-22696.037 -23834.346) rotate(13)" fill="#fff" font-size="36" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-10.8" y="0">&gt;</tspan></text>
          <text id="_2" data-name="&gt;" transform="translate(-22673.141 -23767.156) rotate(-51)" fill="#fff" font-size="36" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-10.8" y="0">&gt;</tspan></text>
          <text id="_3" data-name="&gt;" transform="matrix(-0.951, 0.309, -0.309, -0.951, -22703.729, -23809.492)" fill="#fff" font-size="36" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-10.8" y="0">&gt;</tspan></text>
          <text id="_4" data-name="&gt;" transform="translate(-22664.588 -23752.703) rotate(163)" fill="#fff" font-size="36" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-10.8" y="0">&gt;</tspan></text>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HeartIcon",
  props: {
    isAnimated: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'xl'
    }
  },
}
</script>

<style>
.heart-icon {
  fill: currentColor;
  display: block;
}
.heart-icon.xl {
  width: 40px;
  height: 40px;
}
.heart-icon.sm {
  width: 20px;
  height: 20px;
}
.heart-icon.xs {
  width: 16px;
  height: 16px;
}
.heart-icon.animated {
  animation: animateHeart 1.2s infinite;
}
@keyframes animateHeart {
  0% { transform: scale(1);}
  20% { transform: scale(1.1);}
  40% { transform: scale(1);}
  60% { transform: scale(1.1);}
  80% { transform: scale(1);}
  100% { transform: scale(1);}
}
</style>