export default {
  isVisible(state) {
    return state.isVisible
  },
  isPostBoxLoading(state) {
    return state.isLoading;
  },
  textBoxError(state) {
    return state.error
  },
  images(state) {
    return state.post.images
  },
  post(state) {
    return state.post
  },
  message(state) {
    return state.post.message
  },
  url_preview(state) {
    return state.post.url_preview
  },
}