<template>
  <div>
    <base-modal dialog-class="modal-dialog-centered modal-lg modal-fullscreen-sm-down" content-class="border-0" body-class="p-0" @close="close" visible>
      <button type="button" class="btn-close d-block position-absolute end-0 top-0 z-3" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="carousel slide h-100">
        <div class="carousel-inner h-100">
          <div class="carousel-item active h-100">
            <slider-item :media-item="currentImage"/>
          </div>
        </div>
        <button v-if="images.length > 1" class="carousel-control-prev w-50 justify-content-start" type="button" @click="prev">
          <svg xmlns="http://www.w3.org/2000/svg" class="prev-icon theme-color" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
          </svg>
        </button>
        <button v-if="images.length > 1" class="carousel-control-next w-50 justify-content-end" type="button" @click="next">
          <svg xmlns="http://www.w3.org/2000/svg" class="prev-icon theme-color" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </button>
      </div>
    </base-modal>
  </div>
</template>

<script>
  import SliderItem from "../postZoom/media/SliderItem";

  export default {
    components: {SliderItem},
    emits: ['close'],
    props: ['images', 'selectedIndex'],
    data() {
      return {
        currentIndex: this.selectedIndex || 0,
      }
    },
    computed: {
      currentImage() {
        const img = this.images[this.currentIndex]
        return { src: img.src || img, type: 'image' }
      },
    },
    methods: {
      next() {
        this.currentIndex = this.images.length - 1 < this.currentIndex + 1 ? this.currentIndex = 0 : this.currentIndex + 1;
      },
      prev() {
        this.currentIndex = this.currentIndex - 1 < 0 ? this.images.length - 1 : this.currentIndex - 1;
      },
      close() {
        this.$emit('close');
      },
    },
  }
</script>