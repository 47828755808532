<template>
  <img :src="icon" class="rounded-circle" alt="img" loading="lazy">
</template>

<script>


export default {
  props: ['uid'],
  computed: {
    icon() {
      return this.$store.getters['tags/findTag'](this.uid)?.['icon'] || this.imgPath + "/default-user.png"
    }
  }
}
</script>