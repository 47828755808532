export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setTeammatesData(state, {list, next_index, total_count, leaders_count}) {
    state.list = state.list.concat(list);
    state.next_index = next_index;
    state.total_count = total_count;
    state.leaders_count = leaders_count;
  },
  addTeammate(state, {uid, next_index, total_count}) {
    state.list = [uid, ...state.list];
    state.next_index = next_index;
    state.total_count = total_count;
  },
  removeTeammate(state, payload) {
    state.list = state.list.filter(uid =>  uid !== payload.uid );
    state.next_index -= 1;
    state.total_count -= 1;
  },
  clearTeammatesData(state) {
    state.list = [];
    state.next_index = null;
    state.total_count = null;
    state.leaders_count = null;
  }
}