<template>
  <span @click="navigateToTag" class="founded-item dropdown-item d-flex" :class="{active: selectedIndex === index}">
    <span class="me-2 flex-shrink-0">
       <img :src="tagIcon" class="rounded-circle img-fluid founded-img" alt="img" loading="lazy">
    </span>
    <span class="d-flex flex-column line-height-90">
      <span>
        <router-link :to="{ name: 'tag', params: {slug: tag.tag} }" class="link-main no-decor fs-80 fw-bold">
          <span v-html="hightlightQueryMatch(tag.long_display_name)" ></span>
        </router-link>
        <i class="icon-check-square theme-color fs-80" v-if="tag.verified"></i>
      </span>
      <span class="text-truncate">
        <base-tag-link v-if="tag.user_employee_title" class="link-main fs-70" :uid="tag.uid" >
          {{tag.user_employee_title}}
        </base-tag-link>

         <base-tag-orgs :uid="tag.uid" link-class="link-main fs-70"/>
      </span>
      <span>
        <router-link :to="{name: 'friends', params: {slug: tag.tag }}" class="no-nav">
          <base-teammates class="no-nav link-main fs-60" :uid="tag.uid" :is-org="isOrg" :mutual-teammates-count="tag.user_mutual_teammates_count" :teammates-count="tag.teammates_count"></base-teammates>
        </router-link>
      </span>
    </span>
  </span>
</template>

<script>
import {typesObject} from "@/functions/global";
import tagIcon from "../../../hooks/tagIcon";
import BaseTeammates from "../../UI/BaseTeammates";

export default {
  name: "TagListItem",
  emits: ['navigateToTag', 'onClose'],
  props: ['mode', 'tag', 'selectedIndex', 'index'],
  components: {BaseTeammates},
  mixins: [tagIcon],
  methods: {
    hightlightQueryMatch(tag_string) {
      if (this.mode === 'history') {
        return this.tag.long_display_name
      }
      if (this.$store.state.tagbar.query.length) {
        return tag_string.replace(new RegExp(this.$store.state.tagbar.query, 'ig'),
            `<span class='theme-color text-capitalize'>${this.$store.state.tagbar.query.toLowerCase()}</span>`)
      }
    },
    navigateToTag(e) {
      if (e.target.classList.contains('no-nav')) {
        this.$emit('onClose')
        return
      }

      this.$emit('navigateToTag')
    },
  },
  computed: {
    isOrg() {
      return typesObject(this.tag).isOrg
    },
  },
}
</script>
