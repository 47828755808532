<template>
    {{ numeralNumb }}
</template>

<script>
    import { toNumeral } from '../../functions/global'

    export default {
      props: ['num'],
      computed: {
        numeralNumb() {
          return toNumeral(this.num) || ''
        }
      }
    }
</script>