// import {SYS_UIDS} from "@/const/config";

export default {
  async getInvites({dispatch, commit, rootState}, payload) {
    if (payload.more && rootState.invites.nextIndex === -1) return

    if (!payload.keep) commit('setLoading', true)

    const count = payload.count ? payload.count : 15
    let version = 3;

    let url = `tags/${payload.uid}/called_out`;

    if (payload.isLeadershipGroupsPage) {
      url = 'tags/leadership_groups';
    }
    if (payload.useOrgsUrl) {
      version = 2
      url = `tags/${payload.uid}/orgs`;
    } else if (payload.useUserUrl) {
      url = `tags/${payload.uid}/called_out_by`;
    }

    url += `?count=${count}`
    if (payload.more) url += `&start_index=${rootState.invites.nextIndex}`
    if (payload.query) url += `&query=${payload.query}`

    const responseData = await dispatch('makeFetch', {
      url: url,
      version: version,
      method: 'GET',
      errorMessage: 'Failed to Get Invites!'
    }, { root: true } )

    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('setInvites', responseData);
    commit('setLoading', false)
    return responseData
  },
  async getMentions({dispatch, commit}, payload) {
    commit('setMentionLoading', true)
    const url = `tags/tagbar/meta_autocomplete?query=${payload.query}&count=10`;

    const responseData = await dispatch('makeFetch', {
      url: url,
      method: 'GET',
      errorMessage: 'Failed to Get Mention!'
    }, { root: true } )

    commit('setMentionList', {list: responseData.list, metas: responseData.metas});
    commit('setMentionLoading', false)
  },
  async getPostData({dispatch}, payload) {
    return await dispatch('makeFetch', {
      url: `tags/${payload.inviteTagUid}/call_out_by_post_from/${payload.uid}`,
      version: 3,
      method: 'GET',
      errorMessage: 'Failed to getPostData!'
    }, { root: true } )
  },
  removeByPostUid({commit}, payload) {
    commit('removeByPostUid', payload)
  },
  clearInvites({commit}) {
    commit('setLoading', true)
    commit('clearInvites')
  },
  clearMentions({commit}) {
    commit('setMentionList', {list: [], metas: []});
  },
}