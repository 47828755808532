export default {
  data() {
    return {
      selectedIndex: -1,
    }
  },
  methods: {
    zoomImages(index) {
      this.selectedIndex = index
    },
    closeModal() {
      this.selectedIndex = -1
    },
  },
}