<template>
  <div class="dropdown fs-80">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport" link-id="invitesToDD"></slot>

    <ul class="dropdown-menu" aria-labelledby="invitesToDD">
      <li class="dropdown-header px-2 py-1 fs-80">
        <base-numeral :num="totalCount"></base-numeral>
        <base-pluralize :num="totalCount" word="Person"></base-pluralize>
        called-out leaders to work together.
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item-text p-0">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list :list="list"></tags-list>
          <base-spinner v-if="isLoading || moreIsLoading" class="p-3"></base-spinner>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import ddFetchData from "../../hooks/ddFetchData";
  import TagsList from "./parts/TagsList";

  export default {
    name: 'invitesToDD',
    components: {TagsList},
    mixins: [ddFetchData],
    props: ['uid'],
    data() {
      return {
        errorMessage: 'Failed to Get invites_to!',
      }
    },
    computed: {
      url() {
        return `tags/${this.uid}/invites_to?count=30`;
      },
    },
  }
</script>