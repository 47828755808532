<template>
  <span>
    <template v-if="showMutual">
      <base-numeral :num="mutualTeammatesCount"></base-numeral>
      mutual
      <base-pluralize word="teammate" :num="mutualTeammatesCount"></base-pluralize>
    </template>

    <template v-if="showTeammates">
       <base-numeral :num="teammatesCount"></base-numeral> <base-pluralize :word="isOrg ? 'leader' : 'teammate'" :num="teammatesCount"></base-pluralize>
    </template>
  </span>
</template>


<script>
export default {
  props: ['uid', 'isOrg', 'mutualTeammatesCount', 'teammatesCount'],

  computed: {
    showMutual() {
      const isMe = this.$store.state.auth.currentUser.uid === this.uid;
      const isLoggedIn = this.$store.state.auth.isLoggedIn;
      return this.mutualTeammatesCount && isLoggedIn && !this.isOrg && !isMe
    },
    showTeammates() {
      return !this.showMutual && this.teammatesCount > 0
    }
  }
}
</script>