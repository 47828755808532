<template>
  <node-view-wrapper as="span" data-mention class="dl-mention">
    <base-tag-dd-link :uid="this.node.attrs.uid" class="link-color fw-bold">{{this.node.attrs.label}}</base-tag-dd-link>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import BaseTagDdLink from "@/components/UI/BaseTagDdLink";

export default {
  name: "MentionTagDdLink",
  components: {
    BaseTagDdLink,
    NodeViewWrapper,
  },
  props: nodeViewProps,
}
</script>
