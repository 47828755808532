import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      isMentionLoading: false,
      list: [],
      nextIndex: null,
      totalCount: null,
      callOutFromCount: null,
      callOutToCount: null,
      mentionList: [],
    }
  },
  getters,
  actions,
  mutations,
}
