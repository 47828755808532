export default {
  async getProfile({commit, dispatch}, payload) {
    commit('setLoading', true)

    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/profile`,
      method: 'GET',
      version: 3,
      errorMessage: 'Failed to get Profile!',
    }, { root: true } )

    commit('setProfile', responseData)
    commit('setLoading', false)
  },
  async saveProfile({dispatch, commit}, payload) {
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/${payload.uid}/profile`,
        method: 'PUT',
        version: 3,
        body: payload.data,
        errorMessage: 'Failed to Save Profile!',
      }, {root: true})

      if (!responseData.success) {
        throw responseData;
      }
      commit('resetInitialProfileData')
      dispatch('tags/addTags', [responseData.tag], {root: true})
      return responseData
    } catch (e) {
      dispatch('setNotice', {
        title: "Profile",
        message: e.fields_errors ? e.fields_errors.map(item => item.join(' ')).join(', ') : e.message,
        type: 'alert'
      }, {root: true})
    }
  },
  async getTitles({commit, dispatch}, payload) {
    commit('setTitlesLoading', true)

    let url = `auth/positions_availability?profile_uid=${payload.uid}`
    if (payload.orgUid && payload.orgUid > 0) {
      url += `&org_uid=${payload.orgUid}`
    }

    const responseData = await dispatch('makeFetch', {
      full_url: url,
      method: 'GET',
      errorMessage: 'Failed to get Titles!',
    }, { root: true } )

    commit('setTitles', responseData)
    commit('setTitlesLoading', false)
  },
  clearData({commit}) {
    commit('clearData')
  },
}