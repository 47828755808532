import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      controller: null,
      isLoading: false,
      isHistoryLoading: false,
      query: null,
      founded_next_index: 0,
      // founded_total_count: null,
      foundedTags: [],
      historyTags: [],
      notices: [],
    }
  },
  getters,
  actions,
  mutations,
}