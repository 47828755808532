import { SYS_UIDS } from '@/const/config'

export default {
  async getTeammates({dispatch, commit, rootState}, payload) {

    if (payload.more && rootState.teammates.next_index === -1) return

    commit('setLoading', true)

    let version = 2;

    const count = payload.count ? payload.count : 15
    let url
    if (payload.mode === 'leaders') {
      url = payload.uid !== -1 ? `tags/${payload.uid}/teammates?count=${count}` : `tags/by_type/5/teammates?count=${count}`
    } else {
      let mode = payload.mode || 'teammates';
      if (payload.mode === 'recent') mode = 'recent_teammates';
      if (payload.mode === 'mutual') mode = 'mutual_teammates';
      url = `tags/${payload.uid}/${mode}?count=${count}`;
      if(['home', 'custom'].indexOf(rootState.tags.currentTag.type) >= 0) {
        url = `tags/${SYS_UIDS.home}/mdt?count=${count}`;
      }
    }
    //TODO ALEX REMOVE isLeadershipGroupsPage and useOrgUrls
    if (payload.isLeadershipGroupsPage) {
      version = 3;
      url = `tags/leadership_groups?count=${count}`;
    }
    if (payload.useOrgsUrl) {
      url = `tags/${payload.uid}/orgs?count=${count}`;
    }
    if (payload.more)  url += `&start_index=${rootState.teammates.next_index}`
    if (payload.query) url += `&query=${payload.query}`


    const {tags, list, next_index, total_count, leaders_count} = await dispatch('makeFetch', {
      url: url,
      method: 'GET',
      version: version,
      errorMessage: 'Failed to Get Teammates!'
    }, { root: true } )

    await dispatch('tags/addTags', tags, { root: true })
    commit('setTeammatesData', {list, next_index, total_count, leaders_count})
    commit('setLoading', false)
  },

  async teamDown({dispatch, commit, rootState}, payload) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/as_teammate`,
      method: 'DELETE',
      version: 3,
      errorMessage: 'Failed to Remove User from Teammates!'
    }, { root: true } )
    await dispatch('tags/addTags', responseData.tags, { root: true })

    if (rootState.auth.currentUser.uid === rootState.tags.currentTag.uid) {
      commit('removeTeammate', {uid: responseData.to_tag_uid})
      dispatch('col3/removeTeammate', {uid: responseData.to_tag_uid}, {root: true})
    }
  },

  async teamUpRequest({dispatch}, payload) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/teamup_request`,
      method: 'POST',
      version: 3,
      errorMessage: 'Failed to Request User to Teammates!'
    }, { root: true } )

    if (responseData.success) {
      await dispatch('tags/addTags', responseData.tags, { root: true })
      dispatch('setNotice', {
        title: "Teamup",
        message: 'Request Sent',
        type: 'success'
      }, {root: true})
    } else {
      console.error('teammates/teamUpRequest : ', responseData)
    }
  },
  async acceptRequest({dispatch, commit, state, rootState}, {uid}) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/${uid}/accept_teamup_request`,
      method: 'POST',
      version: 3,
      errorMessage: 'Failed to Accept Team Up Request!'
    }, { root: true } )

    if (responseData.success) {
      await dispatch('tags/addTags', responseData.tags, { root: true })
      commit('auth/acceptTeamupRequest', {uid}, {root: true})
      if ([responseData.from_tag_uid, responseData.to_tag_uid].indexOf(rootState.tags.currentTag.uid) >= 0) {
        commit('addTeammate', {
          uid,
          next_index: state.next_index > 0 ? state.next_index + 1 : -1,
          total_count: state.total_count + 1,
        })
        commit('col3/addTeammate', {
          uid,
          next_index: rootState.col3.teammates.nextIndex < 0 ? rootState.col3.teammates.list.length : rootState.col3.teammates.nextIndex,
          total_count: rootState.col3.teammates.totalCount + 1,
        }, {root: true})
      }
    } else {
      dispatch('setError', responseData?.message, {root: true})
    }
  },
  async declineRequest({dispatch, commit}, {uid}) {

    const responseData = await dispatch('makeFetch', {
      url: `tags/${uid}/decline_teamup_request`,
      method: 'POST',
      version: 3,
      errorMessage: 'Failed to Decline User Teammates!'
    }, { root: true } )

    if (responseData.success) {
      await dispatch('tags/addTags', responseData.tags, { root: true })
      commit('auth/declineTeamupRequest', {uid}, {root: true})
    } else {
      dispatch('setError', responseData?.message, {root: true})
    }
  },

  clearTeammates({commit}) {
    commit('clearTeammatesData')
  },

}