<template>
  <base-modal
      dialog-class="modal-dialog-centered modal-fullscreen-sm-down qr-code-modal"
      content-class="border-0"
      @close="hideModal"
      id="qr-code-modal"
      visible
  >
    <div><button type="button" class="btn-close d-block position-absolute end-0 top-0 z-3 mt-1 me-1" data-bs-dismiss="modal" aria-label="Close"></button></div>
    <div class="qr-modal-header w-100 text-center fs-4 mt-3">
      <div class="qr-modal-header-inner me-3">
        <double-dude2 width="30" height="30" class="align-middle me-1 theme-color"/>
        <span class="align-middle fw-bold">Team up to build trust.</span>
      </div>
    </div>
    <div class="qr-modal-body pb-4">
      <div class="qr-modal-navs p-2" :class="activeTab === MY_QR_CODE_TAB ? 'padded-navs' : ''">
        <nav class="nav nav-pills">
          <a @click.prevent.stop="goQrTab(MY_QR_CODE_TAB)" class="flex-fill nav-link text-center fs-4 no-decor" :class="activeTab === MY_QR_CODE_TAB ? 'link-color': 'link-main'"  aria-current="page" href="#">My Code</a>
          <a @click.prevent.stop="goQrTab(SCAN_QRCODE_TAB)" class="flex-fill nav-link text-center fs-4 no-decor" :class="activeTab === SCAN_QRCODE_TAB ? 'link-color': 'link-main'"  aria-current="page" href="#">Scan Code</a>
        </nav>
      </div>
      <qr-my-code v-if="activeTab === MY_QR_CODE_TAB" :on-change-qr-code="onChangeQrCode"/>
      <qr-code-scan v-if="activeTab === SCAN_QRCODE_TAB" :on-success-scan="onSuccessScan"/>
    </div>

    <div v-if="activeTab === MY_QR_CODE_TAB && !isUploading" class="d-flex flex-row text-center mt-3 fs-130">
      <div v-if="shareButtonVisible" class="flex-fill"><a @click="shareQr" class="link-main no-decor"><i class="icon-upload"/> Share my code</a></div>
      <div class="flex-fill"><a @click="downloadFile" class="link-main no-decor"><i class="icon-load"/> Download my code</a></div>
    </div>
    <div v-if="activeTab === MY_QR_CODE_TAB && isUploading" class="mt-3 d-flex justify-content-evenly">
      <span>Preparing Your QrCode</span><base-spinner class="ms-2" display-inline/>
    </div>

  </base-modal>
</template>

<script>
import DoubleDude2 from "@/components/icons/DoubleDude2";
import QrMyCode from "@/components/modals/qrCode/QrMyCode";
import QrCodeScan from "@/components/modals/qrCode/QrCodeScan";
import qrCreator from "@/hooks/qrCreator";

const MY_QR_CODE_TAB = 'myCode'
const SCAN_QRCODE_TAB = 'scanQr'

export default {
  name: "QrCodeModal",
  mixins: [qrCreator],
  components: {QrCodeScan, QrMyCode, DoubleDude2},
  props: {
    hideModal: Function
  },
  data() {
    return {
      MY_QR_CODE_TAB: MY_QR_CODE_TAB,
      SCAN_QRCODE_TAB: SCAN_QRCODE_TAB,
      activeTab: MY_QR_CODE_TAB,
    }
  },
  methods: {
    goQrTab(tabName) {
      this.activeTab = tabName
    },
    onSuccessScan() {
      this.goQrTab(MY_QR_CODE_TAB)
    },
  },
}
</script>
