<template>
    <li class="nav-item col dropdown position-relative" id="teammup-requests-menu">
      <a role="button"
         data-bs-toggle="dropdown"
         data-bs-auto-close="outside"
         aria-expanded="false"
         class="nav-link text-white d-flex flex-column align-items-center p-0"
         ref="dropdownToggle"
         title="Team Up Requests"
      >
        <double-dude class="menu-icon"/>
        <span v-show="teamupRequestsCount > 0" class="new-count rounded theme-color fw-bold shadow-sm keep-color">{{ teamupRequestsCount }}</span>
        <span class="mt-1 desc d-block d-lg-none">Team ups</span>
      </a>
      <ul class="sub-drop dropdown-menu dropdown-menu-end position-absolute dropdown-full-size-sm" aria-labelledby="teammup-requests-menu-toggle">
        <async-requests v-if="isVisible" :hide-dd="hideDD"/>
      </ul>
    </li>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';
import ddIsVisible from "@/hooks/ddIsVisible";
import fetchOnScroll from "@/hooks/fetchOnScroll";
import { Dropdown } from "bootstrap";
import DoubleDude from "@/components/icons/DoubleDude";

export default {
  name: 'TeamupRequests',
  mixins: [fetchOnScroll, ddIsVisible],
  components: {
    DoubleDude,
    asyncRequests: defineAsyncComponent(() => import('./teamupRequests/TeamupRequestList' /* webpackChunkName: "NoticesList"*/) )
  },
  data() {
    return {
      isVisible: false,
      isAnimated: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['teamupRequestsCount', 'teamupDDIsOpen']),
  },
  watch: {
    teamupDDIsOpen(val) {
      this.$nextTick( () => {
        this.isVisible = val
      })
    },
    teamupRequestsCount(val, oldVal) {
      if (val > oldVal) {
        this.playSound()
      }
    },
  },
  methods: {
    hideDD() {
      Dropdown.getInstance(this.$refs.dropdownToggle).hide()
    },
    afterDDClose() {
      this.$store.dispatch('auth/teamupDDToggle', false)
    },
    playSound() {
      const audio = new Audio(this.teamupAudioUrl)
      audio.play()
    },
  },
}
</script>