<template>
  <div class="row row-cols-5 g-0 images-preview-container" ref="sortableList">
    <span v-for="(image, index) in images" :key="image.id" class="image-preview col" :data-id="image.id">
      <button type="button"
              class="btn-close position-absolute fs-50"
              aria-label="Close"
              @click="removeImage(image.id)"
              v-if="!image.loading && showRemove"
      ></button>
      <base-spinner v-if="image.loading"></base-spinner>
      <img v-else :src="image.src" @click="zoomImages(index)" class="img-thumbnail shadow-sm p-0">
    </span>
    <images-modal v-if="selectedIndex >= 0" :images="images" :selected-index="selectedIndex" @close="closeModal"></images-modal>
  </div>
</template>

<script>
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import ImagesModal from "../modals/ImagesModal";
import zoomImages from "../../hooks/zoomImages";

export default {
  components: {ImagesModal},
  emits: ['removeImage', 'onSort'],
  mixins: [zoomImages],
  props: {
    images: {
      default: [],
    },
    showRemove: {
      default: true
    }
  },
  data() {
    return {
      sortable: null,
    }
  },
  mounted() {
    this.sortable = Sortable.create(this.$refs.sortableList, {
      direction: 'horizontal',
      draggable: '.image-preview',
      dataIdAttr: 'data-id',
      animation: 150,
      onSort: (evt) => {
        this.$emit('onSort', evt.newIndex, evt.oldIndex)
      },
    });
  },
  beforeUnmount() {
    this.sortable.destroy()
  },
  methods: {
    removeImage(id) {
      this.$emit('removeImage', id)
    },
  },
}
</script>

<style scoped>

</style>