export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setError(state, payload) {
    state.error = payload
  },
  setVisible(state, payload) {
    state.isVisible = payload
  },
  addImage(state, payload) {
    state.post.images = [...state.post.images, payload]
    if (state.post.images.length > 0) {
      state.isVisible = true
    }
  },
  setImageLoaded(state, {src, id}) {
    let img = state.post.images.find(img => img.id === id);
    if (!img) return

    Object.assign(img, {src, loading: false})
  },
  removeImage(state, {id}) {
    state.post.images = state.post.images.filter( image =>  image.id !== id )
  },
  sortImages(state, {newIndex, oldIndex}) {
    state.post.images.splice(newIndex, 0, state.post.images.splice(oldIndex, 1)[0]);
  },
  setMessage(state, payload) {
    state.post.message = payload
  },
  setUrlPreview(state, payload) {
    state.post.url_preview = {...state.post.url_preview, payload}
  },
  clearWebPreview(state) {
    state.post.url_preview = {}
  },
  setPosterUid(state, payload) {
    state.post.poster_uid = payload
  },
  resetPost(state) {
    state.isVisible = false
    state.post = {
      images: [],
      message: {
        "type": "doc",
        "content": [],
      },
      url_preview: {},
      poster_uid: null,
    }
  },
}