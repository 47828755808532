<template>
  <div class="card" :class="cardClass">
    <div class="card-header" v-if="$slots.title || $slots.subtitle">
      <div class="header-title" v-if="$slots.title">
        <h5 class="card-title" :class="titleClass"><slot name="title"></slot></h5>
      </div>
      <h6 class="card-subtitle mb-2 text-muted" :class="subTitleClass" v-if="$slots.subtitle"><slot name="subtitle"></slot></h6>
    </div>
      <div class="card-body" :class="bodyClass">
          <slot></slot>
      </div>
  </div>
</template>

<script>
    export default {
      props: ['subTitleClass', 'titleClass', 'cardClass', 'bodyClass']
    }
</script>