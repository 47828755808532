import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      details: {
        subscription_start_date: null,
        subscription_message: null,
        tag_domain: null,
        subscribe_allowed: null,
        current_tier: null,
        subscriber: null,
        renewal_date: null,
        plans: null,
        extra_plans: null,
      },
      isLoading: false,
      subscriptionAttempt: {
        tier: -1,
        price: 0,
        reservation_token: null,
        stripe_client_key: null,
        change_token: null,
        action: null,
        amount: null,
        renewal_date: null,
      },
    }
  },
  getters,
  actions,
  mutations,
}