<template>
  <div id="tagbar" class="h-100">
    <div class="d-flex h-100 align-items-center">
      <back-button v-if="!isHome" class="text-white"/>
      <form class="w-100 z-3" @submit.prevent>
          <div class="input-group">
            <span class="input-group-text bg-white border-0 rounded-end rounded-pill pe-0" >
              <i class="pointer icon-search" :class="{'opacity-0': isDDVisible}"/>
            </span>
            <input class="form-control border-0" id="tagbarInput" type="text" aria-label="Search" autocomplete="off"
                   ref="queryInput"
                   :placeholder="placeholder"
                   :value='query'
                   @input="onChangeInput"
                   @focus="onFocus"
                   @keydown.down="selectFoundedNext"
                   @keydown.up="selectFoundedPrev"
                   @keydown.enter="navigateToTag"
            >
            <span class="input-group-text bg-white border-0 rounded-start rounded-pill">
                <i v-show="isLoggedIn && !isDDVisible" @click="showQrCodeModal" class="icon-qr-code pointer pe-1"/>
                <i v-show="isDDVisible" @click="resetSearch" class="icon-cancel pointer"/>
            </span>
          </div>
      </form>
      <camera-button class="text-white"/>
    </div>

    <founded-tags
        :active-list="activeList"
        :is-loading="isLoading"
        :selected-index="selectedIndex"
        :is-dd-visible="isDDVisible"
        :mode="mode"
        @onMore="more"
        @onSelect="setSelectedFounded"
        @onClearHistory="clearHistory"
        @onClose="onDDClose"
        @navigateToTag="navigateToTag"
    ></founded-tags>
    <div v-if="isDDVisible" @click="onDDClose" class="w-100 h-100 z-2 position-fixed top-0 start-0 dd-overlay"></div>
    <qr-code-modal
      v-if="isQrCodeModalVisible"
      :hide-modal="hideQrCodeModal"
    />
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import FoundedTags from './foundedTags/FoundedTags';
  import CameraButton from "@/components/buttons/CameraButton";
  import BackButton from "@/components/buttons/BackButton";
  import QrCodeModal from "@/components/modals/qrCode/QrCodeModal";

  const HISTORY = 'history'
  const SEARCH = 'search'

  export default {
    props: ['currentTag'],
    components: {QrCodeModal, BackButton, CameraButton, FoundedTags},
    created() {
      this.setQuery()
      this.getHistory()
    },
    data() {
      return {
        query: '',
        isDDVisible: false,
        isQrCodeModalVisible: false,
        mode: HISTORY,
        selectedIndex: 0,
        shouldSelectQuery: false,
      }
    },
    computed: {
      ...mapGetters('auth', ['isLoggedIn']),
      ...mapGetters('tagbar', ['isTagbarLoading', 'isHistoryLoading', 'foundedTags', 'historyTags']),
      placeholder() {
        return this.isHome ? 'Search' : this.currentTag.long_display_name
      },
      activeList() {
        return this.mode === HISTORY ? this.historyTags : this.foundedTags
      },
      isLoading() {
        return this.mode === HISTORY ? this.isHistoryLoading : this.isTagbarLoading
      },
      isHome() {
        return this.currentTag.type === 'home'
      },
    },
    watch: {
      'currentTag.uid'() {
        this.resetSearch();
      },
      query(val) {
        if (val.length === 0 || val === this.currentTag.long_display_name) {
          this.mode = HISTORY
        } else {
          this.mode = SEARCH
        }
      },
      selectedIndex() {
        this.scrollToItem()
      },
    },
    methods: {
      setQuery() {
        this.query = this.isHome ? '' : this.currentTag.long_display_name
        if (this.shouldSelectQuery) {
          this.setSelectionInputRange(this.query.length)
          this.shouldSelectQuery = false
        }
      },
      onChangeInput(e) {
        const oldVal = this.query
        this.query = e.target.value
        if (this.query.length > 0 && this.query !== oldVal) {
          this.search()
        }
      },
      getHistory() {
        this.$store.dispatch('tagbar/getHistory')
      },
      onDDClose() {
        this.isDDVisible = false;
      },
      onFocus (e) {
        this.isDDVisible = true;
        this.setSelectionInputRange(e.target.value.length)
      },
      setSelectionInputRange(endIndex) {
        this.$refs.queryInput.setSelectionRange(0, endIndex)
      },
      selectFoundedPrev() {
        if (this.selectedIndex === 0) {
          return this.selectedIndex = this.activeList.length - 1
        }
        this.selectedIndex -= 1;
      },
      selectFoundedNext() {
        if (this.activeList.length - 1 === this.selectedIndex) {
          return this.selectedIndex = 0
        }
        this.selectedIndex += 1;
      },
      setSelectedFounded(index) {
        this.selectedIndex = index;
      },
      scrollToItem() {
        const scrolledEl = document.getElementById('founded-tags-list-wrapper')
        const listItem = document.querySelectorAll('#founded-tags-list li')[this.selectedIndex]
        if (listItem.offsetTop > scrolledEl.scrollTop + scrolledEl.offsetHeight) {
          return scrolledEl.scrollTo({
            top: listItem.offsetTop - 60,
            behavior: 'smooth',
          })

        }
        if (scrolledEl.scrollTop > listItem.offsetTop - 10) {
          return scrolledEl.scrollTo({
            top: listItem.offsetTop - 60,
            behavior: 'smooth',
          })
        }
      },
      navigateToTag() {
        this.shouldSelectQuery = true
        this.$router.push({ name: 'tag', params: { slug: this.activeList[this.selectedIndex].tag } })
        this.$store.dispatch('tagbar/addHistoryTag', this.activeList[this.selectedIndex])
        this.mode = HISTORY
        this.setSelectedFounded(0)
      },
      search() {
        this.selectedIndex = 0
        this.$store.dispatch('tagbar/search', {query: this.query})
      },
      more() {
        this.$store.dispatch('tagbar/search', {query: this.query, more: true})
      },
      clearHistory() {
        this.$store.dispatch('tagbar/clearHistory', {query: this.query})
      },
      resetSearch() {
        this.setQuery()
        this.onDDClose()
      },
      showQrCodeModal() {
        this.isQrCodeModalVisible = true
      },
      hideQrCodeModal() {
        this.isQrCodeModalVisible = false
      },
    }
  }
</script>