import { createRouter, createWebHistory } from "vue-router";
import { App } from '@capacitor/app';
import store from "./store/index";
import globalProps from "./globalProps";
import {
  ROUTE_NAME_LEADERS_MAP,
  // ROUTE_NAME_INSPIRATION,
  ROUTE_NAME_PDS,
  ROUTE_NAME_TAG,
  ROUTE_NAME_TERMS,
  ROUTE_NAME_FAQ,
  ROUTE_NAME_LANDING,
  ROUTE_NAME_FRIENDS,
  ROUTE_NAME_PHOTOS,
  ROUTE_NAME_PROFILE,
  ROUTE_NAME_LEADERS,
  ROUTE_NAME_ABOUT,
  ROUTE_NAME_FOCUS_GROUPS,
  ROUTE_NAME_SIGN_IN,
  ROUTE_NAME_SIGN_UP,
  ROUTE_NAME_FORGOT_PASSWORD,
  ROUTE_NAME_RESET_PASSWORD,
  ROUTE_NAME_POST,
  ROUTE_NAME_PHOTO_ZOOM,
  ROUTE_NAME_FRIENDS_POST,
  ROUTE_NAME_PHOTO_POST,
  ROUTE_NAME_PROFILE_POST,
  ROUTE_NAME_LEADERS_POST,
  ROUTE_NAME_ABOUT_POST,
  ROUTE_NAME_LEADERSHIP_POST,
  ROUTE_NAME_LOGO,
  ROUTE_NAME_WORLDPEACE,
  FIXED_SCROLL_FOR_ROUTES,
  ROUTE_NAME_SETTINGS,
  ROUTE_NAME_SUBSCRIPTION,
} from "./const/routeNames";

const TagPage = () => import("./pages/tag/TagPage" /* webpackChunkName: "TagPage"*/)
const TheRightBar = () => import("./components/layout/TheRightBar" /* webpackChunkName: "TagPage"*/)
const LeadersPage = () => import("./pages/tag/subPages/leaders/LeadersPage" /* webpackChunkName: "LeadersPage"*/)
const FeedPage = () => import("./pages/tag/subPages/feed/FeedPage" /* webpackChunkName: "FeedPage"*/)
const FriendsPage = () => import("./pages/tag/subPages/friends/FriendsPage" /* webpackChunkName: "FriendsPage"*/)
const FriendsMap = () => import("./pages/tag/subPages/friends/FriendsMap" /* webpackChunkName: "FriendsMap"*/)
const PhotosPage = () => import("./pages/tag/subPages/photos/PhotosPage" /* webpackChunkName: "PhotosPage"*/)
const AuthPage = () => import("./pages/auth/AuthPage" /* webpackChunkName: "SingInPage"*/)
const SingIn = () => import("./components/auth/SingIn" /* webpackChunkName: "SingInPage"*/)
const SingUp = () =>  import("./components/auth/SingUp" /* webpackChunkName: "SingUpPage"*/)
const ForgotPassword = () =>  import("./components/auth/ForgotPassword" /* webpackChunkName: "ForgotPassword"*/)
const ResetPassword = () =>  import("./components/auth/ResetPassword" /* webpackChunkName: "ResetPassword"*/)
const PostZoomPage = () =>  import("./pages/tag/subPages/PostZoomPage" /* webpackChunkName: "PostZoomPage"*/)
// const FavoritesPage = () =>  import("./pages/tag/subPages/favs/FavsPage" /* webpackChunkName: "FavoritesPage"*/)
const PdsPage = () =>  import("./pages/pds/PdsPage" /* webpackChunkName: "PdsPage"*/)
const FaqPage = () =>  import("./pages/faq/FaqPage" /* webpackChunkName: "FaqPage"*/)
const LandingPage = () =>  import("./pages/landing/LandingPage" /* webpackChunkName: "LandingPage"*/)
// const InspirationPage = () =>  import("./pages/inspiration/InspirationPage" /* webpackChunkName: "InspirationPage"*/)
const AboutPage = () =>  import("./pages/about/AboutPage" /* webpackChunkName: "AboutPage"*/)
const TermsPage = () =>  import("./pages/terms/TermsPage" /* webpackChunkName: "TermsPage"*/)
const ProfilePage = () =>  import("./pages/profile/ProfilePage" /* webpackChunkName: "ProfilePage"*/)
const FocusGroupsPage = () => import("./pages/tag/subPages/focusGroups/FocusGroupsPage" /* webpackChunkName: "FocusGroupsPage"*/)
const SettingsPage = () => import("./pages/settings/SettingsPage" /* webpackChunkName: "SettingsPage"*/)
const SubscriptionPage = () => import("./pages/subscription/SubscriptionPage" /* webpackChunkName: "SubscriptionPage"*/)


const routes = [
  { path: '/leaders-map', components: {singleComponent: FriendsMap}, name: ROUTE_NAME_LEADERS_MAP},
  // { path: '/inspiration', component: InspirationPage, name: ROUTE_NAME_INSPIRATION, props: true, meta: {keepModalOpen: true, scrollFixed: true, blackBg: true}},
  { path: '/pds', component: PdsPage, name: ROUTE_NAME_PDS, props: true, meta: {keepModalOpen: true, scrollFixed: true, blackBg: true}},

  // { path: '/home-friends', components: { default: FriendsPage, fullWidthTop: FriendsMap }, name: 'home-friends' },
  { path: '/:slug?', component: TagPage, children: [
      { path: 'settings', components: {default: FeedPage, rightBar: TheRightBar, modal: SettingsPage }, name: ROUTE_NAME_SETTINGS,
        props: true, meta: {keepModalOpen: true, scrollFixed: true}},
      { path: 'subscribe', components: {default: FeedPage, rightBar: TheRightBar, modal: SubscriptionPage }, name: ROUTE_NAME_SUBSCRIPTION,
        props: true, meta: {keepModalOpen: true, scrollFixed: true}},
      { path: '', components: {default: FeedPage, rightBar: TheRightBar}, name: ROUTE_NAME_TAG,
        meta: {rightBarVisible: true, scrollFixed: true}},
      { path: 'post/:post_uid', components: {default: FeedPage, postZoom: PostZoomPage, rightBar: TheRightBar}, name: ROUTE_NAME_POST,
        meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
      { path: 'photo/:post_uid', components: {default: FeedPage, postZoom: PostZoomPage, rightBar: TheRightBar}, name: ROUTE_NAME_PHOTO_ZOOM,
        meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
      { path: 'terms', component: TermsPage, name: ROUTE_NAME_TERMS, props: true,
        meta: {keepModalOpen: true}},
      { path: '/faq', components: { default: FeedPage, rightBar: TheRightBar, modal: FaqPage }, name: ROUTE_NAME_FAQ, props: true,
        meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true}
      },
      { path: '/landing', components: { default: FeedPage, rightBar: TheRightBar, modal: LandingPage }, name: ROUTE_NAME_LANDING, props: true,
        meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true}
      },
      // { path: 'favorites', components: {default: FavoritesPage, rightBar: TheRightBar}, name: 'favs',
      //   meta: {rightBarVisible: true}},
      { path: 'friends', components: {default: FriendsPage, rightBar: TheRightBar}, name: ROUTE_NAME_FRIENDS,
        meta: {rightBarVisible: true, scrollFixed: true}, children: [
          { path: 'post/:post_uid', component: PostZoomPage, name: ROUTE_NAME_FRIENDS_POST,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
        ]
      },
      { path: 'photos', components: {default: PhotosPage, rightBar: TheRightBar}, name: ROUTE_NAME_PHOTOS,
        meta: {rightBarVisible: true, scrollFixed: true},
        children: [
          { path: ':post_uid', component: PostZoomPage, name: ROUTE_NAME_PHOTO_POST,
             meta: {keepModalOpen: true, scrollFixed: true}, alias: 'post/:post_uid'},
        ]},
      { path: 'profile/:section', components: {default: ProfilePage, rightBar: TheRightBar}, name: ROUTE_NAME_PROFILE,
        meta: {rightBarVisible: true, scrollFixed: true}, children: [
          { path: 'post/:post_uid', component: PostZoomPage, name: ROUTE_NAME_PROFILE_POST,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
        ]
      },
      { path: 'leaders', components: { default: LeadersPage, rightBar: TheRightBar }, name: ROUTE_NAME_LEADERS,
        meta: {rightBarVisible: true, scrollFixed: true}, children: [
          { path: 'post/:post_uid', component: PostZoomPage, name: ROUTE_NAME_LEADERS_POST,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
        ]
      },
      { path: 'about', components: { default: AboutPage, rightBar: TheRightBar }, name: ROUTE_NAME_ABOUT,
        meta: {rightBarVisible: true, scrollFixed: true}, children: [
          { path: 'post/:post_uid', component: PostZoomPage, name: ROUTE_NAME_ABOUT_POST,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
        ]
      },
      { path: 'leadership_groups', components: { default: FocusGroupsPage, rightBar: TheRightBar }, name: ROUTE_NAME_FOCUS_GROUPS,
        meta: {rightBarVisible: true, scrollFixed: true}, children: [
          { path: 'post/:post_uid', component: PostZoomPage, name: ROUTE_NAME_LEADERSHIP_POST,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
        ]
      },
      { path: 'auth', components: { default: FeedPage, rightBar: TheRightBar, modal: AuthPage },
        meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true},
        children: [
          { path: 'sign_in', component: SingIn, name: ROUTE_NAME_SIGN_IN,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
          { path: 'sign_up', component: SingUp, name: ROUTE_NAME_SIGN_UP,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
          { path: 'forgot_password', component: ForgotPassword, name: ROUTE_NAME_FORGOT_PASSWORD,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
          { path: 'reset_password', component: ResetPassword, name: ROUTE_NAME_RESET_PASSWORD,
            meta: {keepModalOpen: true, rightBarVisible: true, scrollFixed: true} },
        ],
      },
    ]
  },
  { path: '/logo', name: ROUTE_NAME_LOGO, redirect: {name: ROUTE_NAME_POST, params: {post_uid: globalProps.logoPostUid}}  },
  { path: '/worldpeace', name: ROUTE_NAME_WORLDPEACE, redirect: {name: ROUTE_NAME_POST, params: {post_uid: globalProps.worldPeacePostUid}}  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior (to, from) {
    if (to.fullPath === from.fullPath && to.fullPath === '/') {
      return new Promise((resolve) => {
        const options = to.query?.scroll_to ? {el: to.query.scroll_to, top: to.query.top || 0 } : {top: 0}
        setTimeout(() => {
          resolve(options)
        }, 500)
      })
    }
    if (to.meta.scrollFixed &&
      (FIXED_SCROLL_FOR_ROUTES.indexOf(from.name) >= 0 || (from.name === 'tag' && ['leadership_groups', 'leaders', 'photos', 'friends', 'profile', 'about', 'photo-zoom', 'post', 'focus-groups', 'sign-in', 'sign-up', 'faq'].indexOf(to.name) >= 0)) &&
      (to.params.slug || 'home') === (from.params.slug || 'home')
    ) {
      if (to.query?.scroll_to) {
        return new Promise((resolve) => {
          const options = to.query?.scroll_to ? {el: to.query.scroll_to, top: to.query.top || 0} : {top: 0}
          setTimeout(() => {
            resolve(options)
          }, 500)
        })
      } else {
        return
      }
    }

    let options = to.query?.scroll_to ? {el: to.query.scroll_to, top: to.query.top || 0 } : {top: 0}
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(options)
      }, 500)
    })
  }
})


router.beforeEach((to, from)=> {
  const to_uid = to.params.slug || 'home';
  const from_uid = from.params.slug || 'home';

  if (store.getters['editMode'] && to_uid !== from_uid) {
    store.commit('setGoToPage', to)
    return false
  }
  if (to_uid !== from_uid ) {
    store.dispatch('cover/clearData');
    store.dispatch('tagbar/clearFoundData');
    store.dispatch('teammates/clearTeammates');
    store.dispatch('photos/clearPhotos');
    store.dispatch('posts/clearPosts');
    store.dispatch('invites/clearInvites');
    store.dispatch('col3/clearInvites');
    store.dispatch('col3/clearPhotos');
    store.dispatch('col3/clearTeammates');
    // store.dispatch('favs/clearFavs');
  }
})

App.addListener('appUrlOpen', function (event) {
  const accessToken = event.url.split('social?access_token=').pop();
  if (accessToken) {
    router.push({name: router.currentRoute.value.name, params: {slug: router.currentRoute.value.params.slug}, query: {access_token: accessToken }})
  }
});

//TODO ALEX pause all requests
// App.addListener('appStateChange', ({ isActive }) => {
//   console.log('App state changed. Is active?', isActive);
// });

App.addListener('backButton', () => {
  if (router.options.history.state.back) {
    router.back()
  } else {
    store.commit('setShouldExit', true)
  }
});
export default router;