import {typesObject} from "@/functions/global";

export default {
  setCurrentTag(state, payload) {
    state.currentTag = {...payload, ...typesObject(payload)};
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setFetchingUids(state, payload) {
    const newUids = payload.slice(0)
    state.fetchingUids.map((oldUid) => {
      const index = newUids.indexOf(oldUid)
      if (index > -1) {
        newUids.splice(index, 1);
      }
    })
    state.fetchingUids = state.fetchingUids.concat(newUids)
  },
  removeFetchingUids(state, payload) {
    state.fetchingUids = state.fetchingUids.filter((uid) => {
      return payload.indexOf(uid) === 0
    });
  },
  addTags(state, payload) {
    if(payload.reset){
      let tagsToKeep = {}
      if (payload.importantUids.length > 0) {
        tagsToKeep = payload.importantUids.reduce((memo, uid) => {
          memo[uid] = state.tags[uid]
          return memo
        },{})
      }
      return state.tags = payload.tags.reduce((memo, new_tag) => {
        memo[new_tag.uid] = {...new_tag, ...typesObject(new_tag)}
        return memo
      }, tagsToKeep)
    }
    payload.tags.map((new_tag) => {
      state.tags[new_tag.uid] = {...new_tag, ...typesObject(new_tag)};
      if (state.currentTag?.uid === new_tag.uid) {
        state.currentTag = {...new_tag, ...typesObject(new_tag)}
      }
    })
  },

  updateTag(state, payload) {
    const tag = state.tags[payload.uid]
    if (tag) {
      payload.attrs.map((item) => {
        if (Array.isArray(item.key)) {
          if(item.key[2]) {
            tag[item.key[0]][item.key[1]][item.key[2]] = item.value
          } else {
            tag[item.key[0]][item.key[1]] = item.value
          }
        } else {
          tag[item.key] = item.value
        }
      })
    }
    //TODO ALEX REWRITE
    if ( state.currentTag.uid === payload.uid) {
      payload.attrs.map((item) => {
        if (Array.isArray(item.key)) {
          if(item.key[2]) {
            state.currentTag[item.key[0]][item.key[1]][item.key[2]] = item.value
          } else {
            state.currentTag[item.key[0]][item.key[1]] = item.value
          }
        } else {
          state.currentTag[item.key] = item.value
        }
      })
    }


  },
}