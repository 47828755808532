import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      toggleLoading: false,
      favs: [],
      landingList: [],
      counts: {
        ceosCount: 0,
        companyCount: 0,
        conflictsCount: 0,
        countryCount: 0,
        headsOfStateCount: 0,
        nonProfitsCount: 0,
      },
      ceosList: [],
      companyList: [],
      conflictsList: [],
      countryList: [],
      headsOfStateList: [],
      nonProfitsList: [],
    }
  },
  getters,
  actions,
  mutations,
}