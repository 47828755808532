<template>
<router-link v-bind="$attrs" :to="{ name: 'tag', params: {slug: tag?.tag} }"
    @mouseenter="showDD"
    @mouseleave="hideDD"
    v-touch:tap="onClick"
    v-touch:hold="showDD"
    ref="popupLink"
    :class="[isVisible && 'z-3 position-relative']"
  ><template v-if="tag"><slot name="prefix"/><slot>{{ tag.display_name }}</slot><slot name="affix"/></template></router-link><slot v-if="tag" name="afterLink"/><transition name="opacity" mode="out-in"><span ref="popupEl" v-if="isVisible" @mouseenter="showDD" @mouseleave="hideDD" class="popup-dd-container lh-base position-absolute"><tag-dd :uid="uid"/></span></transition><div v-if="isVisible" @click="hideDD" class="w-100 h-100 z-2 dd-overlay position-fixed top-0 start-0"></div>
</template>

<script>
  import {defineAsyncComponent} from "vue";
  import popup from "../../hooks/popup";
  import fetchTagIfNotFound from "../../hooks/fetchTagIfNotFound";

  export default {
    mixins: [popup, fetchTagIfNotFound],
    components: {
      TagDd: defineAsyncComponent(() =>
          import("../../components/dd/TagDd")
      ),
    },
    created() {
      this.delayToShow = 1500
      this.delayToHide = 600
    },
    methods: {
      onClick(e) {
        this.canShow = false
        e.currentTarget.blur()
      },
    }
  }
</script>