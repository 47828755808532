import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import {getCookie} from "@/functions/cookie";

export default {
  namespaced: true,
  state() {
    return {
      token:  localStorage.getItem('token') || getCookie('access_token'),
      isLoggedIn: false,
      currentUser: null,
      isLoading: true,
      dd: {
        teamupIsOpen: false,
        noticesIsOpen: false,
      },
      notices: {
        isLoading: false,
        nextIndex: 0,
        events: [],
      },
      toasts: [],
      requests: {
        isLoading: false,
        readTimestamp: 0,
        previousReadTimestamp: 0,
        history: [],
        incoming: [],
        suggestion: [],
      },
      follows: {
        isLoading: false,
        nextIndex: 0,
        list: [],
      },
      blocked: {
        isLoading: false,
        nextIndex: 0,
        list: [],
      },
    }
  },
  getters,
  actions,
  mutations,
}