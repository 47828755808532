import { createStore } from "vuex";
import {createConsumer} from "@/ws";
import globalProps from "@/globalProps";
import TagsModule from './tags/index'
import AuthModule from './auth/index'
import ConfigModule from './config/index'
import PostsModule from './posts/index'
import PhotoPostModule from './photoPost/index'
import TeammatesModule from './teammates/index'
import PhotosModule from './photos/index'
import TagbarModule from './tagbar/index'
import FavsModule from './favs/index'
import CoverModule from './cover/index'
import DdModule from './dd/index'
import InvitesModule from './invites/index'
import PageModule from './page/index'
import ProfileModule from './profile/index'
import Col3Module from './col3/index'
import ManageTeammatesModule from './manageTeammates/index'
import SubscriptionModule from './subscription/index'

import {dataUriToBlob} from "@/functions/global";
import {Capacitor} from "@capacitor/core";

const store = createStore({
  modules: {
    tags: TagsModule,
    auth: AuthModule,
    config: ConfigModule,
    posts: PostsModule,
    photoPost: PhotoPostModule,
    teammates: TeammatesModule,
    photos: PhotosModule,
    tagbar: TagbarModule,
    page: PageModule,
    favs: FavsModule,
    cover: CoverModule,
    dd: DdModule,
    invites: InvitesModule,
    profile: ProfileModule,
    col3: Col3Module,
    manageTm: ManageTeammatesModule,
    subscription: SubscriptionModule,
  },
  state() {
    return {
      goToPage: null,
      shouldExit: null,
      editMode: false,
      error: null,
      timeOffset:  new Date().getTimezoneOffset(),
      notices: [],
      toasts: [],
      consumer: null,
    }
  },
  actions: {
    async makeFetch({rootState}, payload) {
      const headers = {
        'Authorization': "Basic " + rootState.auth.token,
        'Timezone-offset': rootState.timeOffset
      }
      if (payload.body && !payload.content_type) {
        headers['Content-Type'] = 'application/json'
      }
      if (Capacitor.isNativePlatform()) {
        headers['Req-From'] = 'app'
      } else {
        headers['Req-From'] = 'web'
      }
      if (payload.content_type) {
        headers['Content-Type'] = payload.content_type
      }
      const body = payload.strict_data ? payload.body : JSON.stringify(payload.body)
      const versionPath = `/v${payload.version || '2'}/`
      const url = globalProps.apiPath + (payload.full_url ? '/'+payload.full_url : versionPath + payload.url)

      const response = await fetch(url , {
        method: payload.method,
        headers: headers,
        body: body,
        signal: payload.signal,
      })

      if (!response.ok){
        throw new Error(response.message || payload.errorMessage);
      }

      if (payload.is_text) {
        return eval("window.temp_setting =" + await response.text());
      } else {
        return await response.json();
      }
    },
    async uploadToS3({dispatch}, el, file_type) {
      const timestamp = Date.now()
      const type = file_type || el.type || 'image/jpeg';

      const responseData = await dispatch('makeFetch', {
        url: 'images/file_sign/' + timestamp,
        method: 'GET',
        errorMessage: 'Failed to Sign a File!',
      })

      return new Promise((resolve, reject) => {
        const fd = new FormData();
        const xhr = new XMLHttpRequest();
        fd.append("key", responseData.fields.key);
        fd.append("AWSAccessKeyId", responseData.fields.AWSAccessKeyId);
        fd.append("acl", "public-read");
        fd.append("policy", responseData.fields.policy);
        fd.append("signature", responseData.fields.signature);
        fd.append("Content-Type", type);
        fd.append("success_action_status", "201");
        const blob = dataUriToBlob(el, type);
        fd.append("file", blob);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 201) {
            const src = xhr.responseXML.getElementsByTagName("Location")[0].firstChild.data;
            return resolve({src: src})
          } else if (xhr.status === 400) {
            return reject("Image wasn't uploaded.")
          }
        };
        xhr.onerror = function () {
          return reject("Image wasn't uploaded.")
        };
        xhr.open("POST", responseData.url, true);
        xhr.send(fd);
      })
    },
    setEditMode({commit}, payload) {
      commit('setEditMode', payload)
    },
    setNotice({commit}, payload) {
      payload.id = Date.now()
      commit('setNotice', payload)
    },
    removeNotice({commit}, payload) {
      commit('removeNotice', payload)
    },
    setError({commit}, payload) {
      commit('setError', payload)
    },
    connectWs({commit, rootGetters}) {
      const consumer = createConsumer({token: rootGetters['auth/token']})
      commit('connectWs', consumer)
    },
    disconnectWs({commit}) {
      commit('disconnectWs')
    },
  },
  mutations: {
    setEditMode(state, payload) {
      state.editMode = payload
    },
    setGoToPage(state, payload) {
      state.goToPage = payload
    },
    setShouldExit(state, payload) {
      state.shouldExit = payload
    },
    setNotice(state, payload) {
      state.notices.push(payload)
    },
    setToasts(state, payload) {
      state.toasts.push(payload)
    },
    removeNotice(state, payload) {
      state.notices = state.notices.filter(notice =>  notice.id !== payload );
    },
    removeToast(state, payload) {
      state.toasts = state.toasts.filter(toast =>  toast.id !== payload );
    },
    setError(state, payload) {
      state.error = payload
    },
    connectWs(state, payload) {
      state.consumer = payload
    },
    disconnectWs(state) {
      state.consumer.disconnect()
    },
  },
  getters: {
    notices(state) {
      return state.notices
    },
    error(state) {
      return state.error
    },
    consumer(state) {
      return state.consumer
    },
    goToPage(state) {
      return state.goToPage
    },
    shouldExit(state) {
      return state.shouldExit
    },
    editMode(state) {
      return state.editMode
    },
  }

});

export default store;