export default {
  isTeammatesLoading(state) {
    return state.isLoading;
  },
  teammates(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.list)
  },
  totalCount(state) {
    return state.total_count;
  },
  leadersCount(state) {
    return state.leaders_count;
  },
  // isInnerTag: (state, _getters, rootState) => (uid) => {
  //
  //   // config.inner_tag_uids.indexOf(uid) > -1 and config.sys_uids.breaking_news isnt uid
  // }
}