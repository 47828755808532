<template>
  <component :is="ddComponent" :uid="uid" :slug="slug">
    <template v-slot="slotProps">
      <a
        href="#"
        :class="linkClass"
        aria-expanded="false"
        :data-bs-toggle="slotProps.bsToggle"
        :data-bs-auto-close="slotProps.bsAutoClose"
        :data-bs-offset="slotProps.bsOffset"
        :id="slotProps.linkId"
      >
         <slot></slot>
      </a>
    </template>
  </component>
</template>

<script>
    import {DD_COMPONENTS} from "../../const/dd";
    import UserReactionsDd from "@/components/dd/UserReactionsDd";
    import InvitesToDd from "@/components/dd/InvitesToDd";
    import InvitesFromDd from "@/components/dd/InvitesFromDd";
    import PeopleInvitedDd from "@/components/dd/PeopleInvitedDd";
    import MemberOfTeamsDd from "@/components/dd/MemberOfTeamsDd";
    import TeammatesDd from "@/components/dd/TeammatesDd";
    import MutualTeammatesDd from "@/components/dd/MutualTeammatesDd";
    import NoticeTeammatesDd from "@/components/dd/NoticeTeammatesDd";
    import TeammatesInvitesDd from "@/components/dd/TeammatesInvitesDd";
    import CommentReactionListDd from "../dd/CommentReactionListDd";
    import ResolvingConflictDd from "../dd/ResolvingConflictDd";
    import FollowedByDd from "../dd/FollowedByDd";
    import PeopleLikeDd from "../dd/PeopleLikeDd";
    import TeammatesLikeDd from "../dd/TeammatesLikeDd";
    import WhoCommentedDd from "../dd/post/WhoCommentedDd";
    import MentionListDd from "@/components/dd/MentionListDd";

    export default {
      components: {
        TeammatesLikeDd,
        PeopleLikeDd,
        FollowedByDd,
        ResolvingConflictDd,
        CommentReactionListDd,
        InvitesToDd,
        InvitesFromDd,
        UserReactionsDd,
        MemberOfTeamsDd,
        TeammatesDd,
        MutualTeammatesDd,
        PeopleInvitedDd,
        TeammatesInvitesDd,
        NoticeTeammatesDd,
        WhoCommentedDd,
        MentionListDd,
      },
      props: {
        uid: {
          type: Number,
          required: false
        },
        slug: {
          type: String,
          required: false
        },
        dd: {
          type: String,
          required: true,
        },
        linkClass: {
          type: String,
          default: 'link-main no-decor'
        }
      },
      computed: {
        ddComponent() {
          return DD_COMPONENTS[this.dd]
        },
      },
    }
</script>