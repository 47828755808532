import ddIsVisible from "./ddIsVisible";
import fetchOnScroll from "./fetchOnScroll";

export default {
  mixins: [ddIsVisible, fetchOnScroll],
  data() {
    return {
      moreIsLoading: false,
      isLoading: false,
      totalCount: 0,
      nextIndex: -1,
      list: [],
    }
  },
  methods: {
    afterDDOpen() {
      this.fetchData()
    },
    afterDDClose() {
      this.list = []
      this.totalCount = 0
      this.nextIndex = -1
    },
    async fetchData(more = false) {
      let url = this.url
      if (more) {
        this.moreIsLoading = true
        url += `&start_index=${this.nextIndex}`
      } else {
        this.isLoading = true
      }

      try {
        const responseData = await this.$store.dispatch('makeFetch', {
          url: url,
          method: 'GET',
          version: this.version || 2,
          errorMessage: this.errorMessage
        })
        this.nextIndex = responseData.next_index
        this.totalCount = responseData.total_count
        if (responseData.tags.length) {
          this.$store.dispatch('tags/addTags', responseData.tags)
        }
        if (more) {
          this.list = this.list.concat(responseData.list)
          this.moreIsLoading = false
        } else {
          this.list = responseData.list
          this.isLoading = false
        }
      } catch (error) {
        this.error = error.message || `${this.name}: ${this.errorMessage}`;
      }
    },
    more() {
      if (this.nextIndex === -1 || this.isLoading || this.moreIsLoading) return

      this.fetchData(true)
    }

  },
}