
export default {
  async getPageTypes({dispatch, commit}) {
    commit('setLoading', true)

    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/page_types',
      method: 'GET',
      errorMessage: 'Failed to Get getPageTypes!'
    }, { root: true } )

    commit('setPageTypes', responseData);
    commit('setLoading', false)
  },
  async savePage({dispatch, commit}, payload) {
    commit('setLoading', true)

    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/page',
      method: 'POST',
      body: payload,
      errorMessage: 'Failed to savePage!'
    }, { root: true } )

    commit('setLoading', false)
    return responseData
  },
  clearPageTypes({commit}) {
    commit('setPageTypes', []);
  },
}