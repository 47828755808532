<template>
  <div class="dropdown fs-80 d-inline-block">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport" link-id="FollowedByDd"></slot>

    <ul class="dropdown-menu " aria-labelledby="FollowedByDd">
      <li class="dropdown-header px-2 py-1 fs-80">
        <base-numeral :num="tag.stats.leaders_followers_count"></base-numeral>
        <base-pluralize :num="tag.stats.leaders_followers_count" word="Leader"></base-pluralize>
        and
        <base-numeral :num="tag.stats.people_followers_count"></base-numeral>
        <base-pluralize :num="tag.stats.people_followers_count" word="Person"></base-pluralize>
        following {{tagDisplayName}}
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item-text p-0">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list v-if="!isLoading" :list="list"></tags-list>
          <base-spinner v-if="isLoading" class="p-3"></base-spinner>
        </div>
        <div v-if="list.length === 0 && !isLoading" class="text-grey text-center pt-2 pb-3">
          No Items
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {SPECIAL_PAGE_NAMES} from "@/const/tags";
import ddFetchData from "../../hooks/ddFetchData";
import TagsList from "./parts/TagsList";

  export default {
    mixins: [ddFetchData],
    components: {TagsList},
    props: ['uid'],
    data() {
      return {
        errorMessage: 'Failed to Get followers',
      }
    },
    computed: {
      url() {
        return `tags/${this.uid}/followers?count=30`
      },
      tag() {
        return this.$store.getters['tags/findTag'](this.uid)
      },
      tagDisplayName() {
        let display_name = this.tag.display_name
        if (this.tag.type === 'page') {
          display_name = SPECIAL_PAGE_NAMES[this.uid]
        } else if (this.tag.isOrg) {
          display_name += ` ${this.tag.team_word || this.teamWord }`
        }
        if (this.tag.isOrg) {
          display_name = 'the ' + display_name
        }
        return display_name
      }
    },

  }
</script>