import { mapGetters } from "vuex";

export default {
  mounted() {
    if (!this.wsInitOnMount) return

    this.initChannel()
  },
  beforeUnmount() {
    this.disconnectChannel()
  },
  watch: {
    isLoggedIn(signed_in, was_signed_in) {
      if (!this.wsShouldBeAuth) return

      if (signed_in !== was_signed_in) {
        if (signed_in) return this.initChannel()
        if (!signed_in) return this.disconnectChannel()
      }
    },
  },
  data() {
    return {
      channel: null,
      wsInitOnMount: true,
      wsShouldBeAuth: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    consumer() {
      return this.$store.getters['consumer']
    }
  },
  methods: {
    initChannel() {
      if (this.wsShouldBeAuth && !this.isLoggedIn) return

      this.channel = this.consumer.subscriptions.create(
        { channel: this.channelName },
        { received: this._onMessage.bind(this)},
      )
    },
    disconnectChannel() {
      this.channel?.unsubscribe()
    },
    onMessage() {},
    onConnected() {},
    _onMessage(data) {
      if (!data) return

      return this.onMessage(data)
    },
    _onConnected(data) {
      if (data) {
        const parsedData = JSON.parse(data)
        return this.onConnected(parsedData)
      }
      this.onConnected()
    },
  },
}