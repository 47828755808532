<template>
  <i id="backButton" class="icon-arrow-left d-block align-self-center ms-0 me-2" :class="className" @click="goBack"/>
</template>

<script>
import {Capacitor} from "@capacitor/core";

export default {
  name: "BackButton",
  computed: {
    className() {
      return Capacitor.isNativePlatform() ? '' : 'd-lg-none'
    },
  },
  methods: {
    goBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
 #backButton {
   font-size: calc(2.69205rem + 1.7046vw) !important;
 }
</style>
