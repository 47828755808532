import { createApp } from 'vue'
import router from './router';
import store from './store/index';
import App from './App.vue'
import Vue3TouchEvents from "vue3-touch-events";
import './configs/index';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import globalProps from "./globalProps";

import { CircleStencil } from 'vue-advanced-cropper'

import BaseModal from './components/UI/BaseModal';
import BaseSpinner from './components/UI/BaseSpinner';
import BaseCard from './components/UI/BaseCard';
import BaseTagLink from './components/UI/BaseTagLink';
import BaseTagDdLink from './components/UI/BaseTagDdLink';
import BaseDd from './components/UI/BaseDd';
import BaseTimeAgo from "./components/UI/BaseTimeAgo";
import BaseNumeral from './components/UI/BaseNumeral';
import BasePluralize from './components/UI/BasePluralize';
import BaseImage from './components/UI/BaseImage';
import BaseIcon from './components/UI/BaseIcon';
import BaseTagOrgs from './components/UI/BaseTagOrgs';

import InviteButton from './components/buttons/InviteButton';
import TeamupButton from './components/buttons/TeamupButton';
import TeammatesDd from './components/dd/TeammatesDd';
import TheReplies from './components/feed/comments/replies/TheReplies';



const app = createApp(App);
app.config.compilerOptions.whitespace = 'preserve'
app.config.performance = true
app.config.unwrapInjectedRef = true

app.use(router);
app.use(store);
app.use(Vue3TouchEvents);
app.component('base-modal', BaseModal)
app.component('base-spinner', BaseSpinner)
app.component('base-card', BaseCard)
app.component('base-tag-link', BaseTagLink)
app.component('base-tag-dd-link', BaseTagDdLink)
app.component('base-dd', BaseDd)
app.component('teammates-dd', TeammatesDd)
app.component('base-image', BaseImage)
app.component('base-time-ago', BaseTimeAgo)
app.component('base-numeral', BaseNumeral)
app.component('base-pluralize', BasePluralize)
app.component('invite-button', InviteButton)
app.component('teamup-button', TeamupButton)
app.component('base-icon', BaseIcon)
app.component('base-tag-orgs', BaseTagOrgs)
app.component('circle-stencil', CircleStencil)
app.component('the-replies', TheReplies)
Object.assign(app.config.globalProperties, globalProps)
store.globalProps = app.config.globalProperties

app.config.errorHandler = function (err, vm, info) {
  console.error(err, vm, info)
}
app.mount('body');

defineCustomElements(window);