export default {
  mounted() {
    this.$el.addEventListener('hidden.bs.dropdown', this.onDdClose)
    this.$el.addEventListener('shown.bs.dropdown', this.onDdOpen)
  },
  beforeUnmount() {
    this.$el.removeEventListener('shown.bs.dropdown', this.onDdOpen)
    this.$el.removeEventListener('hidden.bs.dropdown', this.onDdClose)
  },
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    onDdOpen(e) {
      e.stopPropagation()
      e.preventDefault()
      this.isVisible = true
      this.$nextTick(() => {this.afterDDOpen()})
    },
    onDdClose(e) {
      e.stopPropagation()
      e.preventDefault()
      this.isVisible = false
      this.$nextTick(() => {this.afterDDClose()})
    },
    afterDDOpen() {},
    afterDDClose() {},
  },
}