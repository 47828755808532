<template>
  <div class="dropdown">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport"  link-id="noticeTeammatesDD"></slot>

    <ul class="dropdown-menu dropdown-menu-full-size-sm" aria-labelledby="noticeTeammatesDD">
      <template v-if="isVisible">
        <li class="dropdown-header px-2 py-1 fs-80">
          <base-numeral :num="uids.length"/> <base-pluralize :num="uids.length" word="Teammates"/>
        </li>
        <li class="dropdown-divider"></li>
        <li class="dropdown-item-text p-0">
          <div class="tag-list tag-list-dd">
            <tags-list v-if="!isLoading" :list="uids"></tags-list>
            <base-spinner v-if="isLoading" class="p-3"></base-spinner>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
  import TagsList from "@/components/dd/parts/TagsList";
  import ddFetchData from "@/hooks/ddFetchData";

  export default {
    name: 'NoticeTeammatesDd',
    components: {TagsList},
    props: ['uids'],
    mixins: [ddFetchData],
    methods: {
      async fetchData() {
        this.isLoading = true
        await this.$store.dispatch('tags/fetchTagsByUid', this.uids)
        this.isLoading = false
      }
    },

  }
</script>