<template>
  <base-modal
      :dialog-class="dialogClass"
      backdrop="static"
      :keyboard="false"
      :content-class="contentClass"
      v-bind="$attrs"
      @close="close"
      visible
  >
    <template #header>
      <slot name="header"></slot>
    </template>
    <template v-if="$slots.body"><slot name="body"></slot></template>
    <template #footer>
      <slot name="footer">
        <button type="button" class="btn btn-o-main btn-sm px-3 fs-80" data-bs-dismiss="modal" @click="cancelFunc"><slot name="cancelText">Close</slot></button>
        <button type="button" class="btn btn-color btn-sm px-3 fs-80" data-bs-dismiss="modal" @click="okFunc"><slot name="okText">Ok</slot></button>
      </slot>
    </template>
  </base-modal>
</template>

<script>
export default {
  name: "ThePromt",
  props: {
    okFunc: Function,
    cancelFunc: Function,
    close: Function,
    contentClass: {
      type: String,
      default: 'border-0'
    },
    dialogClass: {
      type: String,
      default: 'modal-dialog-centered'
    },
  }
}
</script>
