"use strict";
exports.__esModule = true;
exports.findSuggestionMatch = void 0;
var core_1 = require("@tiptap/core");
function findSuggestionMatch(config) {
  var _a;
  var char = config.char, allowSpaces = config.allowSpaces, allowedPrefixes = config.allowedPrefixes, startOfLine = config.startOfLine, $position = config.$position;
  const escapedChar = (0, core_1.escapeForRegEx)(char);
  var suffix = new RegExp("\\s".concat(escapedChar, "$"));
  var prefix = startOfLine ? '^' : '';
  var regexp = allowSpaces
    ? new RegExp("".concat(prefix).concat('(@|>)', "\\w+\\s?\\w+"), 'gm')
    : new RegExp("".concat(prefix, "(?:^)?").concat(escapedChar, "[^\\s").concat(escapedChar, "]*"), 'gm');
  var text = ((_a = $position.nodeBefore) === null || _a === void 0 ? void 0 : _a.isText) && $position.nodeBefore.text;

  if (!text) {
    return null;
  }
  var textFrom = $position.pos - text.length;
  var match = Array.from(text.matchAll(regexp)).pop();
  if (!match || match.input === undefined || match.index === undefined) {
    return null;
  }
  // JavaScript doesn't have lookbehinds. This hacks a check that first character
  // is a space or the start of the line
  var matchPrefix = match.input.slice(Math.max(0, match.index - 1), match.index);
  var matchPrefixIsAllowed = new RegExp("^[".concat(allowedPrefixes === null || allowedPrefixes === void 0 ? void 0 : allowedPrefixes.join(''), "\0]?$")).test(matchPrefix);
  if (allowedPrefixes !== null && !matchPrefixIsAllowed) {
    return null;
  }
  // The absolute position of the match in the document
  var from = textFrom + match.index;
  var to = from + match[0].length;
  // Edge case handling; if spaces are allowed and we're directly in between
  // two triggers

  if (allowSpaces && suffix.test(text.slice(to - 1, to + 1))) {
    match[0] += ' ';
    to += 1;
  }
  // If the $position is located within the matched substring, return that range
  if (from < $position.pos && to >= $position.pos) {
    return {
      range: {
        from: from,
        to: to
      },
      query: match[0].slice(char.length),
      text: match[0]
    };
  }
  return null;
}
exports.findSuggestionMatch = findSuggestionMatch;
