import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      posts: [],
      next_time: 0,
      total_count: 0,
      isLoading: true,
      isPostLoading: true,
      isPostBoxLoading: false,
      textBoxError: null,
      currentPost: null,
      controller: null,
    }
  },
  getters,
  actions,
  mutations,
}