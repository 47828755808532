export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setPhotosData(state, {photos, next_index}) {
    state.photos = state.photos.concat(photos);
    state.next_index = next_index;
  },
  addPhotos(state, payload) {
    const newPhotos = payload.images.reduce((memo, imgObj, index) => {
      memo.push({photo_index: index, post_uid: payload.postUid, src: imgObj.src})
      return memo
    }, [])
    state.photos = newPhotos.concat(state.photos)
  },
  removePhoto(state, payload) {
    state.photos = state.photos.filter(photo =>  photo.src !== payload.src );
    state.next_index =- 1;
  },
  removeByPostUid(state, payload) {
    const filteredPhotos = state.photos.filter(photo =>  photo.post_uid !== payload.postUid );
    if (filteredPhotos.length > 0) {
      state.next_index -= state.photos.length - filteredPhotos.length
      state.photos = filteredPhotos;
    }
  },
  clearPhotosData(state) {
    state.photos = [];
    state.isLoading = false;
    state.next_index = 0;
  }
}