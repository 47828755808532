import Image from '@tiptap/extension-image'

export default Image.extend({
  draggable: false,
  selectable: true,
  // onSelectionUpdate({editor, transaction}) {
  //   if (transaction.curSelection.node?.type?.name === 'image') {
  //     editor.chain().focus().setTextSelection(transaction.curSelection.$head.pos).run()
  //   }
  // },
});
