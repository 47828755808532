import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      invites: {
        isLoading: true,
        list: [],
        nextIndex: null,
        totalCount: null,
        callOutFromCount: null,
        callOutToCount: null,
      },
      photos: {
        isLoading: true,
        photos: [],
        nextIndex: 0,
      },
      teammates: {
        isLoading: true,
        list: [],
        nextIndex: 0,
        totalCount: null,
        leadersCount: null,
        prevUrl: null,
      },
    }
  },
  getters,
  actions,
  mutations,
}
