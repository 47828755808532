<template>
    <div class="card web-page-preview">
      <div class="row g-0">
        <div v-if="image" :class="{'col-md-4': !fullSize}">
<!--          <i v-if="resizable" :class="fullSize ? 'icon-collapse' : 'icon-expand'"  @click="resize"></i>-->
          <base-video-js v-if='video' :video="{src: video}"></base-video-js>
          <a v-if="!video && !editMode && url" :href="urlWithProtocol" target="_blank"><img :src="image" class="card-img-top"></a>
          <img v-else-if='!video' :src="image" class="card-img-top">
          <i v-if="editMode" class="icon-cancel link-main fs-90" @click="removeImage"></i>
        </div>
        <div :class="{'col-md-8 main-bg border-bottom': !fullSize}">
          <div class="card-body main-bg p-2 position-relative">
            <i v-if="editMode" class="icon-cancel link-main fs-90" @click="removeAll"></i>
            <h6 v-if="title" class="card-title fs-90" >
              <a v-if="!editMode && url" :href="urlWithProtocol" target="_blank" class="link-main">{{title}}</a>
              <span v-else>{{title}}</span>
            </h6>
            <a v-if="fullSize && !editMode && url" :href="urlWithProtocol" target="_blank" class="link-main"><p class="card-text fs-80" v-html="message"></p></a>
            <p v-else-if="fullSize" class="card-text fs-80" v-html="message"></p>
          </div>
        </div>
        <div v-if="!fullSize" class="card-body main-bg px-2">
          <a v-if="!editMode && url" :href="urlWithProtocol" target="_blank" class="link-main"><p class="card-text fs-80" v-html="message"></p></a>
          <p v-else class="card-text fs-80" v-html="message"></p>
        </div>
      </div>
    </div>
</template>

<script>
import BaseVideoJs from "@/components/UI/BaseVideoJs";
import {allRegexp} from "@/functions/global";

export default {
  components: {BaseVideoJs},
  emits: ['removeWebPreviewImage', 'removeWebPreviewAll'],
  props: {
    description: String,
    desc: String,
    image: String,
    title: String,
    url: String,
    video: String,
    editMode: {
      type: Boolean,
      default: false,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'big'
    },
  },
  data() {
    return {
      fullSize: this.size === 'big'
    }
  },
  computed: {
    message() {
      return (this.desc || this.description)?.replace(allRegexp.url, (el)=> {
        return `<a href="${el}" target="_blank" class="link-main">${el}</a>`
      })
    },
    urlWithProtocol() {
      let url = this.url 
      let hyperlink = url.replace(/\.$/, '')
      if (!/^https?:\/\//.test(url)) {
        hyperlink = 'https://' + url;
      }
      return hyperlink
    },
  },
  methods: {
    removeImage() {
      this.$emit('removeWebPreviewImage')
    },
    removeAll() {
      this.$emit('removeWebPreviewAll')
    },
    resize() {
      this.fullSize = !this.fullSize
    },
  },
}
</script>

<style scoped>

</style>