export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setToggleLoading(state, payload) {
    state.toggleLoading = payload;
  },
  setFavs(state, payload) {
    state.favs = payload.list
  },
  setLandingData(state, payload) {
    state.landingList = payload.list
  },
  toggleFav(state, payload) {
    if (payload.flag) {
      state.favs.unshift(payload.uid)
    } else {
      state.favs = state.favs.filter((uid) => uid !== payload.uid)
    }
  },
  setSuggestions(state, payload) {
    state.counts.ceosCount = payload.ceos_count
    state.ceosList = payload.ceos_list
    state.counts.companyCount = payload.company_count
    state.companyList = payload.company_list
    state.counts.conflictsCount = payload.conflicts_count
    state.conflictsList = payload.conflicts_list
    state.counts.countryCount = payload.country_count
    state.countryList = payload.country_list
    state.counts.headsOfStateCount = payload.heads_of_state_count
    state.headsOfStateList = payload.heads_of_state_list
    state.counts.nonProfitsCount = payload.non_profits_count
    state.nonProfitsList = payload.non_profits_list
  },
}