export default {
  saveStatus(state) {
    return state.saveStatus;
  },
  isLoading(state) {
    return state.isLoading;
  },
  list(state) {
    return state.list
  },
  wdu(state) {
    return state.wdu
  },
}