import { mergeAttributes } from "@tiptap/core";
import Mention from "@tiptap/extension-mention";
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import MentionTagDdLink from './MentionTagDdLink'
import Suggestion from "./suggestion/index";

export default Mention.extend({

  addAttributes() {
    return {
      uid: {
        default: null,
        parseHTML: element => {
          return Number(element.getAttribute('data-uid'));
        },
        renderHTML: attributes => {
          if (!attributes.uid) {
            return {};
          }
          return {
            'data-uid': Number(attributes.uid.uid ? attributes.uid.uid : attributes.uid),
          };
        },
      },
      label: {
        default: null,
        parseHTML: element => {
          return element.getAttribute('data-label');
        },
        renderHTML: attributes => {
          if (!attributes.label) {
            return {};
          }
          return {
            'data-label': attributes.label.label ? attributes.label.label : attributes.label,
          };
        },
      },
      title: {
        default: null,
        parseHTML: element => {
          return element.getAttribute('title');
        },
        renderHTML: attributes => {
          if (!attributes.title) {
            return {};
          }
          return {
            title: attributes.title.title ? attributes.title.title : attributes.title,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'span[data-mention]',
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    let text = node.attrs.label

    if (this.options.limit) {
      if (this.options.limit.charsCount <= 0) {
        return ''
      } else {
        text = this.options.limit.charsCount < node.attrs.label.length ? text.substring(0, this.options.limit.charsCount) + '...' : text
        this.options.updateLimit(this.options.limit.charsCount - node.attrs.label.length)
      }
    }
    return [
      'span',
      mergeAttributes({ 'data-mention': '' }, this.options.HTMLAttributes, HTMLAttributes, {title: node.attrs.label}),
      text,
    ];
  },
  addNodeView() {
    return VueNodeViewRenderer(MentionTagDdLink)
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
});
