<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.047 50.504" class="d-dude2">
    <g id="Group_1874" data-name="Group 1874" transform="translate(-23069.254 10980.527)">
      <path id="Ellipse_761" data-name="Ellipse 761" d="M10-1A11,11,0,0,1,21,10,11,11,0,0,1,10,21,11,11,0,0,1-1,10,11,11,0,0,1,10-1Zm0,20a9,9,0,1,0-9-9A9.01,9.01,0,0,0,10,19Z" transform="translate(23075.785 -10979.527)" class="curr-color"/>
      <path id="Ellipse_762" data-name="Ellipse 762" d="M7-1a7.892,7.892,0,0,1,8,7.76,7.891,7.891,0,0,1-8,7.76A7.891,7.891,0,0,1-1,6.76,7.892,7.892,0,0,1,7-1ZM7,12.519a5.89,5.89,0,0,0,6-5.76A5.89,5.89,0,0,0,7,1,5.89,5.89,0,0,0,1,6.76,5.89,5.89,0,0,0,7,12.519Z" transform="translate(23094.482 -10966.254)" class="curr-color"/>
      <path id="Union_12" data-name="Union 12" d="M27.033,28.713H3.865A4.748,4.748,0,0,1-1,24.1V14.435A14.673,14.673,0,0,1,1.5,6.262,16.192,16.192,0,0,1,7.964.7,17.14,17.14,0,0,1,15.448-1h.012A17.338,17.338,0,0,1,22.934.7a16.326,16.326,0,0,1,5.874,4.738,12.877,12.877,0,0,1,1.3-.066h.006a12.739,12.739,0,0,1,5.417,1.212,11.716,11.716,0,0,1,4.7,3.979,10.425,10.425,0,0,1,1.824,5.871v6.739a3.636,3.636,0,0,1-3.738,3.516H31.062A4.913,4.913,0,0,1,27.033,28.713ZM15.449,1A15.156,15.156,0,0,0,8.83,2.5,14.191,14.191,0,0,0,3.162,7.374,12.679,12.679,0,0,0,1,14.435V24.1a2.749,2.749,0,0,0,2.865,2.61H27.033a2.917,2.917,0,0,0,2.587-1.488l.281-.535h8.409a1.64,1.64,0,0,0,1.738-1.516V16.435A8.98,8.98,0,0,0,34.67,8.393a10.726,10.726,0,0,0-4.561-1.02H30.1a10.84,10.84,0,0,0-1.6.119l-.609.091-.356-.5A14.259,14.259,0,0,0,22.067,2.5,15.326,15.326,0,0,0,15.461,1Z" transform="translate(23070.254 -10958.736)" class="curr-color"/>
      <text id="_" data-name="&gt;" transform="translate(23092.061 -10937.047)" class="curr-color" font-size="12" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="-3.6" y="0">&gt;</tspan></text>
      <path id="Subtraction_20" data-name="Subtraction 20" d="M38.309,26.69H28.851l.769-1.465A2.391,2.391,0,0,0,29.9,24.1V14.435a12.685,12.685,0,0,0-2.364-7.354L26.6,5.755,28.2,5.514a12.846,12.846,0,0,1,1.9-.142h.006a12.739,12.739,0,0,1,5.417,1.212,11.716,11.716,0,0,1,4.7,3.979,10.425,10.425,0,0,1,1.824,5.871v6.739A3.636,3.636,0,0,1,38.309,26.69Zm-6.45-2h6.45a1.64,1.64,0,0,0,1.738-1.516V16.435a8.432,8.432,0,0,0-1.478-4.748,9.716,9.716,0,0,0-3.9-3.295,10.726,10.726,0,0,0-4.561-1.02h-.035A14.593,14.593,0,0,1,31.9,14.435V24.1A4.444,4.444,0,0,1,31.859,24.69Z" transform="translate(23070.254 -10959.107)" class="curr-color"/>
      <path id="Path_394" data-name="Path 394" d="M51.147,43.281a16.252,16.252,0,0,0-2.282-.914l-4.9,4.64-4.9-4.64a16.252,16.252,0,0,0-2.282.914,15.516,15.516,0,0,0-6.882,6.673,32.327,32.327,0,0,0,28.135,0A15.512,15.512,0,0,0,51.147,43.281Z" transform="translate(23041.57 -10999.678)"/>
      <path id="Path_394_-_Outline" data-name="Path 394 - Outline" d="M39.188,41.8l4.774,4.517L48.735,41.8l.28.088a16.805,16.805,0,0,1,2.352.943,16.109,16.109,0,0,1,7.1,6.889l.239.457-.464.225A32.832,32.832,0,0,1,44,53.675h-.085A32.832,32.832,0,0,1,29.676,50.4l-.464-.225.24-.457a16.113,16.113,0,0,1,7.1-6.889,16.81,16.81,0,0,1,2.353-.943ZM57.336,49.73a15.14,15.14,0,0,0-6.41-6,15.8,15.8,0,0,0-1.932-.8L43.961,47.7,38.93,42.934a15.812,15.812,0,0,0-1.933.8,15.144,15.144,0,0,0-6.409,6,31.839,31.839,0,0,0,13.332,2.946H44A31.84,31.84,0,0,0,57.336,49.73Z" transform="translate(23041.57 -10999.678)" class="curr-color"/>
      <g id="Group_1869" data-name="Group 1869" transform="translate(-634.746 -1046.029)">
        <circle id="Ellipse_640" data-name="Ellipse 640" cx="0.5" cy="0.5" r="0.5" transform="translate(23714.453 -9909.369) rotate(1)" fill="#fff"/>
        <path id="Ellipse_640_-_Outline" data-name="Ellipse 640 - Outline" d="M.5,0A.5.5,0,1,1,0,.5.5.5,0,0,1,.5,0Z" transform="translate(23714.453 -9909.369) rotate(1)" class="curr-color"/>
        <circle id="Ellipse_641" data-name="Ellipse 641" cx="0.5" cy="0.5" r="0.5" transform="translate(23715.438 -9908.354) rotate(1)" fill="#fff"/>
        <path id="Ellipse_641_-_Outline" data-name="Ellipse 641 - Outline" d="M.5,0A.5.5,0,1,1,0,.5.5.5,0,0,1,.5,0Z" transform="translate(23715.438 -9908.354) rotate(1)" class="curr-color"/>
        <circle id="Ellipse_642" data-name="Ellipse 642" cx="0.5" cy="0.5" r="0.5" transform="translate(23716.42 -9907.336) rotate(1)" fill="#fff"/>
        <path id="Ellipse_642_-_Outline" data-name="Ellipse 642 - Outline" d="M.5,0A.5.5,0,1,1,0,.5.5.5,0,0,1,.5,0Z" transform="translate(23716.42 -9907.336) rotate(1)" class="curr-color"/>
        <circle id="Ellipse_643" data-name="Ellipse 643" cx="0.5" cy="0.5" r="0.5" transform="translate(23717.402 -9906.318) rotate(1)" fill="#fff"/>
        <path id="Ellipse_643_-_Outline" data-name="Ellipse 643 - Outline" d="M.5,0A.5.5,0,1,1,0,.5.5.5,0,0,1,.5,0Z" transform="translate(23717.402 -9906.318) rotate(1)" class="curr-color"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DoubleDude2"
}
</script>

<style scoped>
.d-dude2 .curr-color{
  fill: currentColor;
  font-weight: normal;
  font-style: normal;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>