<template>
  <header class="navbar sticky-top navbar-expand navbar-dark theme-bg p-0 py-1" id="header" tabindex="-1" :class="[!isLoggedIn && 'signed-out']">
    <nav class="d-flex w-100 flex-wrap flex-md-row flex-sm-column flex-md-nowrap justify-content-between px-2" aria-label="Main navigation">
      <div class="container">
        <div class="row">
          <div class="col-auto g-0">
            <div class="d-none d-md-flex align-items-center justify-content-center h-100" style="width: 250px; max-width: 250px;">
              <div class="position-relative p-md-0" id="concept">
                <router-link
                    :to="{name: 'tag', params: {slug: ''}}" active-class="fake-active"
                    class="text-white d-flex fw-bold text-decoration-none align-items-center position-relative"
                    @click="refreshHomeFeed"
                >
                  <span>Dual</span><i class='icon-dt'></i><span>Dialogue</span>
                </router-link>
                <a href='mailto:contactus@dualdialogue.com?subject=Report a bug - description/steps and screenshots.' target="_blank" class="z-2 no-decor text-white position-absolute fw-normal fs-50 end-0 top-70">beta</a>
              </div>
            </div>
          </div>

          <div class="col-auto g-0 me-md-0 me-2" id="tagbar-col">
            <search-form :current-tag="currentTag"></search-form>
          </div>

          <div class="col-auto collapse navbar-collapse theme-bg mt-0" id="header-right">
            <div class="w-100">
              <ul class="navbar-nav flex-row flex-nowrap me-lg-auto align-items-center justify-content-lg-around justify-content-between">
                <li class="nav-item col left-col-toggler">
                  <span class="nav-link pointer text-white d-flex flex-column align-items-center" @click="toggleLeftBar" aria-label="Toggle navigation">
                    <i class="icon-menu text-white fs-145 menu-icon"></i>
                    <span class="desc mt-1 d-block d-lg-none">Menu</span>
                  </span>
                </li>
                <router-link :to="{name: 'tag', params: {slug: ''}}" custom v-slot="{ href, navigate, route }">
                  <li class="nav-item col" @click="refreshHomeFeed" :class="[$route.path === route.path && 'active', !isLoggedIn && 'd-md-none']">
                    <a :href="href" @click="navigate" class="nav-link text-white d-inline-block d-flex flex-column align-items-center p-0">
                      <globe-icon class="text-white home-icon" title="Home"/>
                      <span class="desc mt-1 d-block d-lg-none">Home</span>
                    </a>
                  </li>
                </router-link>
                <template v-if="isLoggedIn">
                  <router-link :to="{name: 'tag', params: {slug: currentUser.tag}}" custom v-slot="{ href, navigate, isActive }">
                    <li class="nav-item col" :class="{active: isActive}" title="Profile">
                      <a :href="href" @click="navigate" class="nav-link text-white d-flex flex-lg-row flex-column align-items-center py-0">
                        <img class="rounded-circle me-0 me-lg-2 menu-avatar" :src="currentUserAvatar" width="27" height="27" />
                        <span class="desc align-middle mt-1 mt-lg-0">{{ currentUser.firstName }}</span>
                      </a>
                    </li>
                  </router-link>

<!--                  <li class="nav-item col">-->
<!--                    <div class="btn-group align-items-center">-->
<!--                      <router-link :to="{name: 'tag', params: {slug: currentUser.tag}}" class="nav-link text-white d-flex flex-lg-row flex-column align-items-center py-0"  title="Profile">-->
<!--                        <img class="rounded-circle me-0 me-lg-2 menu-avatar" :src="currentUser.icon" width="27" height="27" />-->
<!--                        <span class="desc align-middle mt-1 mt-lg-0">{{ currentUser.firstName }}</span>-->
<!--                      </router-link>-->
<!--                      <i class="icon-caret-down dropdown-toggle-split text-white pointer fs-4" data-bs-toggle="dropdown" aria-expanded="false"></i>-->
<!--                      <ul class="dropdown-menu dropdown-menu-end">-->
<!--                        <li><a class="dropdown-item" href="#" @click="switchAccount">Switch to USA account</a></li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </li>-->

                  <teamup-requests></teamup-requests>
                  <main-notices></main-notices>
                </template>

                <li v-if="!isLoggedIn" class="nav-item col">
                  <router-link :to="{name: 'sign-in', params: {slug: currentTagSlugNoHome}}" class="sign-in-button nav-link text-white d-flex flex-lg-row flex-column align-items-center justify-content-center float-lg-end" active-class="fake-active">
                    <img class="rounded-circle me-0 me-lg-2" :src="imgPath + '/default-user.png'" width="30" />
                    <span class="desc align-middle mt-1 mt-lg-0 text-center">Sign in for Dual Dialogue</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>


<script>
    import SearchForm from "../header/SearchForm";
    import TeamupRequests from "../header/TeamupRequests";
    import MainNotices from "../header/MainNotices";
    import {NOTICE_NEW} from "../../const/wsEventNames";
    import { mapGetters } from "vuex";
    import GlobeIcon from "@/components/UI/GlobeIcon";
    import refreshHomeFeed from "../../hooks/refreshHomeFeed";
    import hideLeftBar from "../../hooks/hideLeftBar";
    import currentUserAvatar from "../../hooks/currentUserAvatar";
    import ws from "../../hooks/ws";

    export default {
      components: {
        GlobeIcon,
        SearchForm,
        TeamupRequests,
        MainNotices,
      },
      mixins: [refreshHomeFeed, hideLeftBar, currentUserAvatar, ws],
      mounted() {
        document.addEventListener('swiped-left', this.hideLeftBar);
        document.addEventListener('swiped-right', this.showLeftBar);
      },
      beforeUnmount() {
        document.removeEventListener('swiped-left', this.hideLeftBar);
        document.removeEventListener('swiped-right', this.showLeftBar);
      },
      data() {
        return {
          channelName: 'notifications',
          wsShouldBeAuth: true,
        }
      },
      computed: {
        ...mapGetters('tags', ['currentTag', 'isTagLoading', 'currentTagSlugNoHome', 'currentTagUid']),
        ...mapGetters('auth', ['isLoggedIn','currentUser']),
      },
      watch: {
        $route (to, from) {
          if (to.fullPath !== from.fullPath) {
            this.hideLeftBar()
          }
        },
      },
      methods: {
        // switchAccount() {
        //   this.$store.commit('auth/switchUser', this.$store.getters['tags/findTag'](2220));
        // },
        showLeftBar() {
          document.getElementsByTagName('body')[0].classList.add('sidebar-main')
        },
        toggleLeftBar() {
          document.getElementsByTagName('body')[0].classList.toggle('sidebar-main')
        },
        async onMessage(data) {
          await this.$store.dispatch('tags/addTags',  data.tags)
          if (data.type === NOTICE_NEW) {
            this.$store.commit('auth/setToasts', data.notices)
          }
          this.$store.dispatch('auth/updateNotifications', data)
        },
      }
    }
</script>