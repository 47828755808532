export default {
  data() {
    return {
      isDragActive: false,
      dragLeaveTimeout: null,
    }
  },
  methods: {
    dragEnter() {
      this.isActive = true
      clearTimeout(this.dragLeaveTimeout)
      this.isDragActive = true
    },
    dragLeave() {
      clearTimeout(this.dragLeaveTimeout)
      this.dragLeaveTimeout = setTimeout(() => {
        this.isDragActive = false
        if(this.isEmpty) {
          this.isActive = false
        }
      }, 1000)
    },
    onDrop() {
      clearTimeout(this.dragLeaveTimeout)
      this.isDragActive = false
    },
  },
  beforeUnmount() {
    clearTimeout(this.dragLeaveTimeout)
  },
}