<template>
  <div class="typing-comment d-flex align-items-center">
    <div class="lds-ellipsis me-2"><div></div><div></div><div></div><div></div></div>
    <transition-group name="opacity">
      <base-icon v-for="(user, index) in truncatedTypingUsers" :key="user.uid" :icon="user.icon"
                 class="rounded-circle tag-icon border"
                 :class="{'icons-overlap': index !== 0}"
      />
    </transition-group>
    <div class="ms-2">
      <template v-for="(user, index) in truncatedTypingUsers" :key="user.uid">
        <span class="theme-color fw-bold">{{user.display_name}}</span><span v-if="truncatedTypingUsers.length -1 !== index">, </span>
      </template>
      <span v-if="otherTypingUsersCount > 0">
        & <base-numeral :num="otherTypingUsersCount"/> <base-pluralize :num="otherTypingUsersCount" word="person"/>
      </span>
      talking...
    </div>
  </div>
</template>

<script>
import BasePluralize from "../../UI/BasePluralize";
import BaseNumeral from "../../UI/BaseNumeral";
export default {
  name: "TypingUsersComment",
  components: {BaseNumeral, BasePluralize},
  props: {
    typingUsers: {
      default: [],
    }
  },
  computed: {
    truncatedTypingUsers() {
      return this.typingUsers.slice(0, 3)
    },
    otherTypingUsersCount() {
      return this.typingUsers.length - 3
    },
  },

}
</script>
