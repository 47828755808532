export default {
  isFavsLoading(state) {
    return state.isLoading;
  },
  isToggleLoading(state) {
    return state.toggleLoading
  },
  list(state) {
    return state.favs;
  },
  signedOutList(state) {
    const jsonFavs = localStorage.getItem('signedOutFavs')
    return jsonFavs === null ? state.favs : JSON.parse(jsonFavs)
  },
  landingList(state) {
    return state.landingList
  },
  landingData(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.landingList)
  },
  favs(state, _getters, rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.favs)
  },
  ceos(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.ceosList)
  },
  company(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.companyList)
  },
  conflicts(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.conflictsList)
  },
  country(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.countryList)
  },
  headsOfState(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.headsOfStateList)
  },
  nonProfits(state, _getters, _rootState, rootGetters) {
    return rootGetters['tags/getTags'](state.nonProfitsList)
  },
  counts(state) {
    return state.counts
  },
}