export const ROUTE_NAME_LEADERS_MAP = 'leaders-map';
export const ROUTE_NAME_INSPIRATION = 'inspiration';
export const ROUTE_NAME_PDS = 'pds';
export const ROUTE_NAME_HOME_FRIENDS = "home-friends";
export const ROUTE_NAME_TAG = "tag";
export const ROUTE_NAME_TERMS = "terms";
export const ROUTE_NAME_FAQ = "faq";
export const ROUTE_NAME_LANDING = "landing";
export const ROUTE_NAME_SETTINGS = "settings";
export const ROUTE_NAME_SUBSCRIPTION = "subscribe";
export const ROUTE_NAME_FRIENDS = "friends";
export const ROUTE_NAME_PHOTOS = "photos";
export const ROUTE_NAME_PROFILE = "profile";
export const ROUTE_NAME_LEADERS = "leaders";
export const ROUTE_NAME_ABOUT = "about";
export const ROUTE_NAME_FOCUS_GROUPS = "focus-groups";
export const ROUTE_NAME_SIGN_IN = "sign-in";
export const ROUTE_NAME_SIGN_UP = "sign-up";
export const ROUTE_NAME_FORGOT_PASSWORD = "forgot-password";
export const ROUTE_NAME_RESET_PASSWORD = "reset-password";
export const ROUTE_NAME_POST = 'post';
export const ROUTE_NAME_PHOTO_ZOOM = 'photo-zoom';
export const ROUTE_NAME_FRIENDS_POST = "friends-post";
export const ROUTE_NAME_PHOTO_POST = "photo-post";
export const ROUTE_NAME_PROFILE_POST = "profile-post";
export const ROUTE_NAME_LEADERS_POST = "leaders-post";
export const ROUTE_NAME_ABOUT_POST = "about-post";
export const ROUTE_NAME_LEADERSHIP_POST = "leadership-post";
export const ROUTE_NAME_LOGO = "logo";
export const ROUTE_NAME_WORLDPEACE = "worldpeace";

export const ZOOM_ROUTE_NAMES = [
  ROUTE_NAME_POST,
  ROUTE_NAME_PHOTO_ZOOM,
  ROUTE_NAME_FRIENDS_POST,
  ROUTE_NAME_PHOTO_POST,
  ROUTE_NAME_PROFILE_POST,
  ROUTE_NAME_LEADERS_POST,
  ROUTE_NAME_ABOUT_POST,
  ROUTE_NAME_LEADERSHIP_POST,
]

export const FIXED_SCROLL_FOR_ROUTES = [
  ROUTE_NAME_POST,
  ROUTE_NAME_LEADERS_POST,
  ROUTE_NAME_PROFILE_POST,
  ROUTE_NAME_PHOTOS,
  ROUTE_NAME_PHOTO_POST,
  ROUTE_NAME_LEADERSHIP_POST,
  ROUTE_NAME_ABOUT_POST,
  ROUTE_NAME_FRIENDS_POST,
  ROUTE_NAME_PHOTO_ZOOM,
  ROUTE_NAME_FRIENDS,
  ROUTE_NAME_PROFILE,
  ROUTE_NAME_LEADERS,
  ROUTE_NAME_FOCUS_GROUPS,
  ROUTE_NAME_ABOUT,
  ROUTE_NAME_INSPIRATION,
  ROUTE_NAME_PDS,
  ROUTE_NAME_SIGN_IN,
  ROUTE_NAME_SIGN_UP,
  ROUTE_NAME_FORGOT_PASSWORD,
  ROUTE_NAME_RESET_PASSWORD,
  ROUTE_NAME_FAQ,
  ROUTE_NAME_LANDING,
]

export const VIDEO_ROUTES = [
    "humanmovie",
    "hewasmyrealfather",
    "jalal",
    "iwouldwanttoknowwhy",
    "aziz",
    "strongertogether",
    "clinton",
    "marietherese",
    "whyamibreathing",
    "putin",
    "khadija",
    "nesrine",
    "aloispleva",
    "brunohanson",
    "amporn",
    "shultz",
    "aidambaye",
    "thatswhyilefthiminsyria",
    "florevasseur",
    "pattentuthill",
    "pedropabloopeka",
    "thepossibledream",
    "maryrobinson",
    "shahwali",
    "kirk",
    "pike",
    "madeaconsciousdecision",
    "josemujica",
    "justpeopledoingpeoplethings",
    "mamela",
    "sovath",
    "gore",
    "mitchell",
    "bankimoon",
    "hiba",
    "xiaomei",
    "considermeahumanbeing",
    "thunberg",
    "luis2",
    "unfortunatelythatfeeling",
    "santiago",
    "runakhan",
    "luis",
    "oswaldo",
    "alejandrotoledo",
    "beasley",
    "prasad",
    "ahnoineverthoughtaboutit",
    "mihal",
    "bryanstevenson",
    "wealthofanation",
    "charlesandinow",
    "juangordillo",
    "josegil",
    "francinechristophe",
    "hume",
    "luttig",
    "peter",
    "ian",
    "itgivesusfreshheart",
    "samuel",
    "michelrocard",
    "gandhi",
    "wangechingige",
    "moussa",
    "vandemba",
    "cook",
    "jose",
    "jobs",
    "volodymyr",
    "sally",
    "beautifulglass",
    "didier",
    "campdadaabrefugees",
    "whatcanidotohelpyou",
    "dadaab",
    "howwillibereceived",
    "fdr",
    "lincoln",
    "teddy",
    "sharon",
    "thenalzheimercamealong",
    "mostafa",
    "paythepriceforpeace",
    "philippecroizon",
    "bassam",
    "lula",
    "carolyn",
    "valuesthatareperfectlyconventional",
    "bissengul",
    "modi",
    "ressa",
    "exploreyourself",
    "picard",
    "takeherhand",
    "shetaughtmewhatitwas",
    "whaticantforget",
    "abdullah",
    "saulmorgana",
    "bloomberg",
    "denismukwege",
    "mounira",
    "philippe",
    "campbell",
    "mlk",
    "jfk",
    "masaratdaud",
    "jeromekerviel",
    "sonnydothingsrightlikeme",
    "woinshet",
    "mbera",
    "chaplin",
    "eleanor",
    "nobodytohelpyou",
    "washington",
    "mattie",
    "hanssilvester",
    "santos",
    "isadora",
    "jinping",
    "reagan",
    "colm",
    "guterres",
    "hedidntlistentome",
    "happywhenitrains",
    "sidnea",
    "why",
    "tristanlecomte",
    "wellplaygolf",
    "sophiemaxwell",
    "janegoodall",
    "hartley",
    "paulettepetit",
    "gates",
    "ardern",
    "haiyan",
    "thatwaslovetome",
    "hughhurr",
    "mandela",
    "constantinos",
    "maria",
    "mbeki",
    "humanvol1",
    "kosal",
    "humanvol3",
    "zelensky",
    "humanvol2",
    "itriedtofillinforhim",
    "stephen",
    "harris",
    "aygoul",
    "awa",
    "lookingatthecows",
    "zuckerberg",
    "valentina",
    "saintcyr",
    "youcantbuylife",
    "katherinerobinson",
    "letmeliveman",
    "churchill",
    "camerondiaz",
    "regardlessofthedifficulties",
    "chiara",
    "liveaid85",
    "angelique",
    "muhammadyunus",
    "merkel",
    "iwouldhavestoppedit"
].reduce(
  (accumulator, val) => {
    accumulator.push({ path: `/${val}`, redirect: {name: 'inspiration', query: {video: val}} })
    return accumulator
  },
  []
);