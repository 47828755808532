export default {
  async whoTalked({dispatch, commit}, {uid, type}) {
    commit('setLoading', true)
    let url = `tags/${uid}/who_talked?count=30`
    if (type) url += `&filter=${type}`
    const responseData = await dispatch('makeFetch', {
      url: url,
      method: 'GET',
      errorMessage: 'Failed to Get whoTalked!'
    }, { root: true } )
    if (responseData.items.length) {
      const tag_uids = responseData.items.map(item => item.uid);
      await dispatch('tags/fetchTagsByUid', [tag_uids], { root: true })
    }
    commit('setWhoTalked', responseData)
    commit('setLoading', false)
  },
  async geCovertData({dispatch}, {uid}) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/${uid}/cover_data`,
      method: 'GET',
      errorMessage: 'Failed to Get Cover DD Data!'
    }, { root: true } )

    if(responseData.teammate_uids?.length) {
      await dispatch('tags/fetchTagsByUid', responseData.teammate_uids, { root: true });
    }

    return responseData
  },

}