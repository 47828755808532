<template>
  <li class="nav-item col dropdown" id="main-notices-menu"  ref="dropdown">
    <a
      role="button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
      class="nav-link text-white d-flex flex-column align-items-center p-0"
      ref="dropdownToggle"
      title="Notifications for United Nations"
    >
      <i v-show="noticesCount > 0" class="icon-sdg fs-160 menu-icon" :class="notificationClass" ></i>
      <span v-show="noticesCount > 0" class="sdg-new-count fw-bold keep-color" :class="`chars-count-${noticesCountNumeralChars.length}`">
        <span v-for="numb in noticesCountNumeralChars" :key="numb">{{numb}}</span>
      </span>
      <empty-notices-icon v-if="noticesCount === 0" class="empty-sdg-icon" :class="notificationClass"/>
      <span class="mt-1 desc d-block d-lg-none">Notices</span>
    </a>
    <ul class="sub-drop dropdown-menu dropdown-menu-end position-absolute dropdown-full-size-sm" aria-labelledby="main-notices-menu-toggle">
      <async-notices v-if="isVisible" :hide-dd="hideDD"/>
    </ul>
  </li>
</template>


<script>
  import { defineAsyncComponent } from 'vue';
  import { mapGetters } from 'vuex';
  import { toNumeral } from '@/functions/global'
  import ddIsVisible from "@/hooks/ddIsVisible";
  import { Dropdown } from "bootstrap";
  import EmptyNoticesIcon from "@/components/icons/EmptyNoticesIcon";

  export default {
    mixins: [ddIsVisible],
    components: {
      EmptyNoticesIcon,
      asyncNotices: defineAsyncComponent(() => import('./notices/NoticesList' /* webpackChunkName: "NoticesList"*/) )
    },
    mounted() {
      if (this.noticesCount > 0) {
        this.animateIcon()
      }
    },
    data() {
      return {
        isAnimated: false,
      }
    },
    computed: {
      ...mapGetters('auth', ['noticesCount']),
      notificationClass() {
        return this.isAnimated ? 'sdg-animated' : ''
      },
      noticesCountNumeralChars() {
        return (toNumeral(this.noticesCount) || '').split('')
      },
    },
    watch: {
      noticesCount(val, old_val) {
        if (val > old_val) {
          this.animateIcon()
        }
      },
    },
    methods: {
      animateIcon() {
        this.isAnimated = true
        setTimeout(() => {
          this.isAnimated = false
        }, 5000)
      },
      hideDD() {
        Dropdown.getInstance(this.$refs.dropdownToggle).hide()
      },
    },
   }
</script>