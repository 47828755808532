<template>
  <div class="dropdown fs-80 d-inline-block">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport" link-id="WhoCommentedDd"/>

    <ul class="dropdown-menu" aria-labelledby="WhoCommentedDd">
      <li class="dropdown-header px-2 py-1 fs-80">
        <base-numeral :num="uids.length"/>
        <base-pluralize :num="uids.length" :word="whoCommented"/>
        commented
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item-text p-0">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list v-if="!isLoading" :list="uids"/>
          <base-spinner v-if="isLoading" class="p-3"/>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import TagsList from "@/components/dd/parts/TagsList";
  import ddFetchData from "@/hooks/ddFetchData";

  export default {
    name: 'WhoCommentedDd',
    components: {TagsList},
    mixins: [ddFetchData],
    emits: ['update-position'],
    props: {
      uids: {
        type: Array,
        required: true,
      },
      whoCommented: String,
    },
    data() {
      return {
        isLoading: true,
        nextIndex: -1,
      }
    },
    methods: {
      async fetchData() {
        this.isLoading = true
        await this.$store.dispatch('tags/fetchTagsByUid', this.uids)
        this.isLoading = false
      }
    },
  }
</script>