import {buildSearchParams} from "@/functions/global";
import {removeBlankParagraphs} from "@/functions/post";

export default {
  async getPosts({dispatch, commit, state}, payload) {
    if (state.controller) {
      state.controller.abort()
    }

    commit('setLoading', true)

    const controller = new AbortController()
    const signal = controller.signal

    commit('setController', controller)

    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/feed?count=10&start_time=0`,
      method: 'GET',
      version: 3,
      signal: signal,
      errorMessage: 'Failed to get Feed!',
    }, { root: true } )

    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('setPosts', responseData)
    commit('setLoading', false)

  },
  async getPost({dispatch, commit}, payload) {
    commit('setPostLoading', true)

    const searchParamsString = buildSearchParams({
      event_uid: payload.eventUid,
      about_tag_uid: payload.aboutTagUid,
      comments_count: payload.commentsCount,
      is_post_zoom: payload.isPostZoom,
    })

    const responseData = await dispatch('makeFetch', {
      url: `tags/posts/${payload.postUid}${searchParamsString}` ,
      method: 'GET',
      errorMessage: `Post with UID '${payload.postUid}' was not found`,
    }, { root: true } )

    await dispatch('tags/addTags', responseData.tags, { root: true });
    commit('setPost', responseData);
    commit('updatePostAttrs', {
      uid: responseData.posts[0].uid,
      attrs: [
        {key: ['stats', 'post_zoomin_count'], value: responseData.posts[0].stats.post_zoomin_count},
      ]
    });
    commit('setPostLoading', false);
  },
  async morePosts({dispatch, commit, rootState, getters}, payload) {
    if (rootState.posts.next_time === -1 || getters['isPostsLoading']) {
      return
    }
    const lastPostUid = rootState.posts.posts[rootState.posts.posts.length - 1].uid
    commit('setLoading', true)

    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/feed?count=10&start_time=${rootState.posts.next_time}&post_uid=${lastPostUid}&feed_size=${rootState.posts.posts.length}`,
      method: 'GET',
      version: 3,
      errorMessage: 'Failed to Get Feed!',
    }, { root: true } )

    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('addPosts', responseData)
    commit('setLoading', false)
  },

  async createPost({dispatch, commit, rootGetters}, payload) {
    payload.data.message = removeBlankParagraphs(payload.data.message)
    commit('setPostBoxLoading', true)
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/${rootGetters['tags/currentTagUid']}/post`,
        method: 'POST',
        errorMessage: 'Failed to Create Post!',
        body: payload.data,
      }, { root: true } )
      commit('setPostBoxLoading', false)
      if(responseData.success) {
        await dispatch('tags/addTags', responseData.tags, { root: true })
        commit('addPost', responseData.post_obj)
        if (responseData.driven_uids?.length ) {
          dispatch('tags/incrementInviteToCount', responseData.driven_uids, { root: true } )
        }
        return responseData
      } else {
        throw responseData.message;
      }
    }catch (error) {
      commit('setPostBoxLoading', false)
      commit('setPostBoxError', error)
    }
  },
  async updatePost({dispatch, commit}, payload) {
    payload.data.message = removeBlankParagraphs(payload.data.message)
    commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'loading', value: true }]})
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/${payload.uid}`,
        method: 'PUT',
        errorMessage: 'Failed to Update Post!',
        body: payload.data,
      }, { root: true } )
      await dispatch('tags/addTags', responseData.tags, { root: true })
      commit('updatePost', { loading: false, ...responseData.post_obj })
      return responseData
    }catch (error) {
      commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'error', value: error }, {key: 'loading', value: false}]})
    }
  },
  async deletePost({dispatch, commit}, payload) {
    commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'loading', value: true }]})
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/${payload.uid}`,
        method: 'DELETE',
        errorMessage: 'Failed to DELETE Post!',
      }, { root: true } )

      if (responseData.remove) {
        dispatch('invites/removeByPostUid', {postUid: payload.uid}, {root: true})
        dispatch('photos/removeByPostUid', {postUid: payload.uid}, {root: true})
      }
      commit('deletePost', payload.uid)
      if (responseData.undriven_uids?.length ) {
        dispatch('tags/decrementInviteToCount', responseData.undriven_uids, { root: true } )
      }
    }catch (error) {
      commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'error', value: error }, {key: 'loading', value: false}]})
    }
  },
  async getMeta({dispatch}, payload) {
    return dispatch('makeFetch', {
      full_url: `og/get_meta?url=${payload}`,
      method: 'GET',
      errorMessage: 'Failed to get Meta!',
    }, { root: true } )
  },
  async flagPost({dispatch, commit}, payload) {
    try {
      await dispatch('makeFetch', {
        url: `tags/posts/${payload.uid}/flag`,
        method: 'POST',
        errorMessage: 'Failed to FLAG Post!',
      }, { root: true } )

      commit('deletePost', payload.uid)
    }catch (error) {
      dispatch('setError', error, {root: true})
    }
  },
  async hidePost({dispatch, commit}, payload) {
    try {
      await dispatch('makeFetch', {
        url: `tags/posts/${payload.uid}/hide`,
        method: 'POST',
        errorMessage: 'Failed to HIDE Post!',
      }, { root: true } )

      commit('deletePost', payload.uid)
    }catch (error) {
      dispatch('setError', error, {root: true})
    }
  },
  async toggleStickyPost({dispatch, commit}, payload) {
    commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'loading', value: true }]})
    const method = payload.sticky_flag ? 'DELETE' : 'POST'

    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/${payload.uid}/sticky`,
        method,
        body: {
          sticky_flag: !payload.sticky_flag,
        },
        errorMessage: 'Failed to Flag Post as Sticky!',
      }, { root: true } )
      if(responseData.success) {
        commit('updatePostAttrs', { uid: payload.uid, attrs:
            [
              {key: 'loading', value: false },
              {key: ['prefs', 'sticky_flag'], value: !payload.sticky_flag },
            ]
        })
        if (payload.sticky_flag && payload.canBeRemoved) {
          commit('deletePost', payload.uid)
        }
      } else {
        commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'loading', value: false }]})
        dispatch('setError', 'Failed to Flag Post as Sticky!', {root: true})
      }
    }catch (error) {
      commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'loading', value: false }]})
      dispatch('setError', error, {root: true})
    }
  },
  async toggleBookmarkPost({dispatch, commit}, payload) {
    commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'loading', value: true }]})
    const method = payload.bookmark_flag ? 'DELETE' : 'POST'

    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/${payload.uid}/bookmark`,
        method,
        body: {
          bookmark_flag: !payload.bookmark_flag,
        },
        errorMessage: 'Failed to bookmark Post!',
      }, { root: true } )
      if(responseData.success) {
        commit('updatePostAttrs', { uid: payload.uid, attrs:
              [
                {key: 'loading', value: false },
                {key: ['prefs', 'bookmark_flag'], value: !payload.bookmark_flag },
              ]
        })
        if (payload.bookmark_flag && payload.canBeRemoved) {
          commit('deletePost', payload.uid)
        }
      } else {
        commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'loading', value: false }]})
        dispatch('setError', 'Failed to bookmark Post!', {root: true})
      }
    }catch (error) {
      commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'loading', value: false }]})
      dispatch('setError', error, {root: true})
    }
  },
  async sharePost({dispatch, commit}, payload) {

    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/${payload.uid}/share`,
        method: payload.newShareFlag ? 'PUT' : 'DELETE',
        errorMessage: 'Failed to SHARE Post!',
      }, { root: true } )

      if(responseData.success) {
        commit('updatePostAttrs', { uid: payload.uid, attrs:
            [
              {key: ['prefs', 'share_flag'], value: payload.newShareFlag },
            ]
        })
      } else {
        dispatch('setError', 'Failed to share Post!', {root: true})
      }
    }catch (error) {
      dispatch('setError', error, {root: true})
    }
  },
  async reactOnPost({dispatch, commit}, payload) {
    commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'reactionLoading', value: true}]})

    const requestOptions = await dispatch('reactionData', payload)

    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/${payload.uid}/drive`,
        errorMessage: 'Failed to React On Post!',
        ...requestOptions,
      }, { root: true } )
      if(responseData.success) {
        commit('updatePostAttrs', {
          uid: payload.uid,
          attrs: [
            {key: 'reactionLoading', value: false },
            {key: ['stats', 'reactions'], value: responseData.reactions },
            {key: ['stats', 'drive_click_count'], value: responseData.drive_click_count },
            {key: ['prefs', 'reaction_flag'], value: responseData.reaction_flag || 'none' },
          ],
        })

        if (responseData.driven_uids?.length ) {
          dispatch('tags/incrementInviteToCount', responseData.driven_uids, { root: true } )
        }
        if (responseData.undriven_uids?.length ) {
          dispatch('tags/decrementInviteToCount', responseData.undriven_uids, { root: true } )
        }
      } else {
        throw responseData.message;
      }
    } catch (error) {
      commit('updatePostAttrs', { uid: payload.uid, attrs: [{key: 'reactionLoading', value: false}]})
      dispatch('setError', error, {root: true})
    }
  },
  async getCoverPost({dispatch}, payload) {
    try {
      return await dispatch('makeFetch', {
        url: `tags/${payload.uid}/${payload.type}_post_uid`,
        method: 'GET',
        errorMessage: `Failed to GET COVER (${payload.type}) Post!`,
      }, {root: true})
    }catch (error) {
      dispatch('setError', error, {root: true})
    }
  },
  setPostBoxError({commit}, payload) {
    commit('setPostBoxError', payload)
  },
  setPostError({commit}, payload) {
    commit('updatePostAttrs', payload)
  },
  clearPostBoxError({commit}) {
    commit('setPostBoxError', null)
  },
  clearPosts({commit}) {
    commit('clearPosts');
  },

  //COMMENTS
  async moreComments({commit, dispatch}, {postUid, nextIndex, comment_type, previous_comment_uid, is_post_zoom, count}) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/posts/${postUid}/comments2?start_index=${nextIndex}&comment_type=${comment_type}&previous_comment_uid=${previous_comment_uid}&count=${count}&post_zoom=${is_post_zoom}`,
      method: 'GET',
      version: 3,
      errorMessage: 'Failed to GET Comments!',
    }, { root: true } )
    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('addComments', {postUid, comment_type, ...responseData})
  },
  async moreReplies({commit, dispatch}, {postUid, nextIndex, reply_type, previous_comment_uid, parent_comment_uid, parent_comment_type, is_post_zoom, count}) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/posts/${postUid}/comments2?start_index=${nextIndex}&comment_type=${reply_type}&previous_comment_uid=${previous_comment_uid}&parent_comment_uid=${parent_comment_uid}&count=${count}&post_zoom=${is_post_zoom}`,
      method: 'GET',
      version: 3,
      errorMessage: 'Failed to GET Replies!',
    }, { root: true } )
    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('addReplies', {postUid, reply_type, parent_comment_type, parent_comment_uid,  ...responseData})
  },
  async createComment({commit, dispatch}, payload) {
    payload.data.message = removeBlankParagraphs(payload.data.message)
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/${payload.postUid}/comment`,
        method: 'POST',
        errorMessage: 'Failed to Create Comment!',
        body: payload.data,
      }, { root: true } )
      if(responseData.success) {
        await dispatch('tags/addTags', responseData.tags, { root: true })
        commit('addComment', {postUid: payload.postUid, data: responseData.comment_obj})
        commit('updatePostAttrs', { uid: payload.postUid, attrs: [
            {key: 'mentions', value: [...responseData.post_obj.mentions]},
            {key: 'prefs', value: {...responseData.post_obj.prefs}},
            {key: 'promo_tag_uids', value: [...responseData.post_obj.promo_tag_uids]},
            {key: 'stats', value: {...responseData.post_obj.stats}},
        ]})

        if (responseData.parent_comment_obj) {
          commit('updateCommentAttrs',
              {
                postUid: payload.postUid,
                uid: responseData.parent_comment_obj.uid,
                attrs: [
                  {key: 'stats', value: {...responseData.parent_comment_obj.stats}},
                  {key: ['replies', 'stats'], value: {...responseData.parent_comment_obj.replies.stats}},
                  {key: ['replies', 'next_stats'], value: {...responseData.parent_comment_obj.replies.next_stats}},
                  {key: ['replies', 'priority', 'total_count'], value: responseData.parent_comment_obj.replies.priority.total_count},
                  {key: ['replies', 'normal', 'total_count'], value: responseData.parent_comment_obj.replies.normal.total_count},
                ]
              })
        }
        if (responseData.driven_uids?.length ) {
          dispatch('tags/incrementInviteToCount', responseData.driven_uids, { root: true } )
        }
        return {success: true}
      } else {
        throw responseData.message;
      }
    }catch (error) {
      return {success: false, message: error}
    }
  },
  async updateComment({commit, dispatch}, payload) {
    payload.data.message = removeBlankParagraphs(payload.data.message)
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/comments/${payload.commentUid}`,
        method: 'PUT',
        errorMessage: 'Failed to Update Comment!',
        body: payload.data,
      }, { root: true } )
      if(responseData.success) {
        await dispatch('tags/addTags', responseData.tags, { root: true })
        commit('updateComment', {
          commentUid: payload.commentUid,
          postUid: payload.postUid,
          ...responseData.comment_obj
        })
        commit('updatePost', { ...responseData.post_obj })
        return {success: true}
      } else {
        throw responseData.message;
      }
    }catch (error) {
      commit('updateComment', {
        commentUid: payload.commentUid,
        postUid: payload.postUid,
        error: error
      })
    }
  },
  async deleteComment({commit, dispatch}, payload) {
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/comments/${payload.commentUid}`,
        method: 'DELETE',
        errorMessage: 'Failed to Delete Comment!',
      }, { root: true } )
      if(responseData.success) {
        commit('deleteComment', payload)
        // commit('updatePostAttrs', {
        //   uid: payload.postUid,
        //   attrs: [
        //     {key: ['stats', 'comments_count'], value: payload.commentsCount - 1},
        //   ],
        // })
        commit('updatePost', { ...responseData.post_obj })
        if (responseData.undriven_uids?.length ) {
          dispatch('tags/decrementInviteToCount', responseData.undriven_uids, { root: true } )
        }
        return responseData
      } else {
        throw responseData.message;
      }
    }catch (error) {
      this.dispatch('setError', error, {root: true})
    }
  },
  async flagComment({commit, dispatch}, payload) {
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/comments/${payload.commentUid}/flag`,
        method: 'POST',
        errorMessage: 'Failed to Flag Comment!',
      }, { root: true } )
      if(responseData.success) {
        commit('deleteComment', payload)
        return responseData
      } else {
        throw responseData.message;
      }
    }catch (error) {
      this.dispatch('setError', error, {root: true})
    }
  },
  async hideComment({commit, dispatch}, payload) {
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/comments/${payload.commentUid}/hide`,
        method: 'POST',
        errorMessage: 'Failed to Hide Comment!',
      }, { root: true } )
      if(responseData.success) {
        commit('deleteComment', payload)
        return responseData
      } else {
        throw responseData.message;
      }
    }catch (error) {
      this.dispatch('setError', error, {root: true})
    }
  },
  async reactOnComment({dispatch, commit}, payload) {
    const payloadParams = { postUid: payload.postUid, commentUid: payload.commentUid, parent_comment_uid: payload.parent_comment_uid, priority: payload.priority }

    commit('updateComment', { ...payloadParams, reactionLoading: true })
    const requestOptions = await dispatch('reactionData', payload)

    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/posts/comments/${payloadParams.commentUid}/drive`,
        errorMessage: 'Failed to React On Comment!',
        ...requestOptions,
      }, { root: true } )
      if(responseData.success) {
        commit('updateComment', {
          ...payloadParams,
          reactionLoading: false,
          stats: { reactions: responseData.reactions, drive_click_count: responseData.drive_click_count },
          prefs: { reaction_flag: responseData.reaction_flag || 'none' }
        })
      } else {
        throw responseData.message;
      }
    } catch (error) {
      commit('updateComment', { ...payloadParams, reactionLoading: false })
      dispatch('setError', error, {root: true})
    }
  },

  // NOTICES
  async turnOffNotices({dispatch, commit}, payload) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/posts/${payload.postUid}/notice_status`,
      method: 'DELETE',
      errorMessage: 'Failed to Change Notice Status on Post!',
    }, { root: true } )

    if (responseData.success) {
      commit('updatePostAttrs', {uid: payload.postUid, attrs: [{key: ['prefs', 'notice_status'], value: 'off'}]})
      dispatch('auth/removeNoticesByPostUid', payload, {root: true})
    } else {
      dispatch('setError', responseData.message, {root: true})
    }
  },

  reactionData(_, payload) {
    const requestOptions = { method: 'DELETE' }
    if ( !((!payload.reaction || payload.reaction === payload.prevReaction) && payload.prevReaction !== 'none')) {
      requestOptions.method = 'PUT'
      requestOptions.body = {
        reaction: !payload.reaction && payload.prevReaction === 'none' ? 'like' : payload.reaction,
        promo_tag_uids: payload.promoTagUids
      }
    }
    return requestOptions
  },
}