export default {
  async getPhotos({dispatch, commit, state}, payload) {

    if (payload.more && state.next_index === -1) return
    const count = payload.count || 20

    let url = `tags/${payload.uid}/photo_posts?count=${count}`
    if (payload.more)  url += `&start_index=${state.next_index}`

    commit('setLoading', true)
    const responseData = await dispatch('makeFetch', {
      url: url,
      method: 'GET',
      errorMessage: 'Failed to Get Photos!'
    }, { root: true } )

    commit('setPhotosData', responseData)
    commit('setLoading', false)
  },

  async removePhoto({commit, dispatch}, payload) {
    commit('setLoading', true)
    const responseData = await dispatch('makeFetch', {
      url: `tags/posts/remove_photo`,
      method: 'PUT',
      body: payload,
      errorMessage: 'Failed to Remove Photo!'
    }, { root: true } )

    if (responseData.images_count === 0) {
      dispatch('posts/deletePost', {uid: responseData.post_obj.uid}, { root: true })
    } else {
      commit('updatePost', responseData.post_obj, {root: true})
    }
    dispatch('col3/removePhoto', payload, {root: true})
    commit('removePhoto', payload)
    commit('setLoading', false)
  },
  addPhotos({commit, dispatch}, payload) {
    dispatch('col3/addPhotos', payload, {root: true})
    commit('addPhotos', payload)
  },
  removeByPostUid({dispatch, commit}, payload) {
    dispatch('col3/removePhotosByPostUid', payload, {root: true})
    commit('removeByPostUid', payload)
  },

  clearPhotos({commit}) {
    commit('clearPhotosData')
  },

}