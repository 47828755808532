<template>
    <div v-if="visible" class="dropdown teamup-dropdown" :class="dropdownClass" >
        <button class="teamup-button" type="button" :id="idString" :data-bs-toggle="dataBs.dropdown" :data-bs-offset="dataBs.offset" aria-expanded="false" :class="btnClass" :title="title" @click="action">
            <slot>
                <template v-if="status === 'none'">
                  <double-dude width="20" height="16" class="align-middle me-1"/>
                  <span>{{addText}}</span></template>
                <template v-if="status === 'request'"><span>Teammate request sent</span></template>
                <template v-if="status === 'incoming_request'"><span>Accept team up request</span></template>
                <template v-if="status === 'accept'"><i class="icon-checkmark align-middle fw-bold me-1"></i> <span>Teammates</span></template>
            </slot>
          <base-spinner v-if="isLoading" class="ms-2" display-inline/>
        </button>
        <ul class="dropdown-menu dropdown-menu-end" :aria-labelledby="idString" v-if="status === 'accept' || status === 'incoming_request'">
            <li><a class="lh-sm fs-80 dropdown-item p-3 py-1 main-link" href="#" @click.prevent="showPromt">{{status === 'accept' ? removeText : cancelText}}</a></li>
        </ul>
    </div>

    <the-promt v-if="promt"
               :ok-func="teamDown"
               :cancel-func="cancelPromt"
               header-class="border-0 theme-color pb-2"
               body-class="p-0 border-0"
               footer-class="justify-content-center border-0 pt-0"
               :close="cancelPromt"
    >
      <template #header><h5 class="text-center w-100">Remove from my team. Are you sure?</h5></template>
      <template #okText>Confirm</template>
      <template #cancelText>Cancel</template>
    </the-promt>
</template>

<script>

    import BaseSpinner from "@/components/UI/BaseSpinner";
    import ThePromt from "@/components/modals/ThePromt";
    import DoubleDude from "@/components/icons/DoubleDude";
    export default {
      components: {DoubleDude, ThePromt, BaseSpinner},
      props: {
        uid: {
          required: true,
        },
        visible: {
          type: Boolean,
          default: true,
        },
        status: {
          required: true,
        },
        classNames: {
          default: ''
        },
        dropdownClass: {
          default: ''
        },
        addText: {
          default: 'Team Up'
        },
        removeText: {
          default: 'Team Down'
        },
        cancelText: {
          default: 'Cancel'
        },
      },
      data() {
        return {
          promt: false,
          isLoading: false,
        }
      },
      computed: {
        title() {
          if (this.status === 'none') {
            return 'Request to become teammates'
          } else if (this.status === 'request') {
            return 'Cancel request to talk'
          }
          return ''
        },
        btnClass() {
          let classString = this.isLoading ? 'disabled ' : ''
          if (this.status === 'request' || this.status === 'accept') {
            return classString += 'btn btn-o-color ' + this.classNames
          }
          return classString += 'btn btn-color ' + this.classNames
        },
        idString() {
          return 'teamdownMenu_' + this.uid
        },
        dataBs() {
          const options = { dropdown: '', offset: '' }
          if (this.status === 'accept') {
            options.dropdown = 'dropdown'
            options.offset = '0,5'
          }
          return options
        }
      },
      methods: {
        showPromt() {
          this.promt = true
        },
        cancelPromt() {
          this.promt = false
        },
        async teamUpRequest() {
          this.isLoading = true
          await this.$store.dispatch('teammates/teamUpRequest', {uid: this.uid})
          this.isLoading = false
        },
        async acceptTeamUpRequest() {
          this.isLoading = true
          await this.$store.dispatch('teammates/acceptRequest', {uid: this.uid})
          this.isLoading = false
        },
        teamDown() {
          this.isLoading = true
          this.$store.dispatch('teammates/teamDown', {uid: this.uid})
          this.isLoading = false
        },
        action() {
          if (this.status === 'accept') return
          if (this.status === 'none') return this.teamUpRequest()
          if (this.status === 'request') return this.teamDown()
          if (this.status === 'incoming_request') return this.acceptTeamUpRequest()
        }
      }
    }
</script>
