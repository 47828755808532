export default {
  setPosts(state, payload) {
    state.posts = payload.posts;
    state.next_time = payload.next_time;
    state.total_count = payload.total_count;
  },
  addPosts(state, payload) {
    const oldUids = state.posts.reduce((mem, item) => {
      mem.push(item.uid)
      return mem
    }, [])
    const filteredPosts = payload.posts.filter(({uid}) => oldUids.indexOf(uid) < 0 )
    state.posts = state.posts.concat(filteredPosts);
    if(payload.next_time) state.next_time = payload.next_time;
    if(payload.total_count) state.total_count = payload.total_count;
  },
  addPost(state, post) {
    state.posts = [post, ...state.posts];
    state.total_count = state.total_count + 1
  },
  updatePost(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.uid})
    if (!posts.length) return

    for (let i = 0; i < posts.length; i++) {
      Object.assign(posts[i], payload)
    }
  },
  updatePostAttrs(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.uid})
    if (!posts.length) return

    for (let i = 0; i < posts.length; i++) {
      payload.attrs.map((item) => {
        if (Array.isArray(item.key)) {
          if (item.key[2]) {
            posts[i][item.key[0]][item.key[1]][item.key[2]] = item.value
          } else {
            posts[i][item.key[0]][item.key[1]] = item.value
          }
        } else {
          posts[i][item.key] = item.value
        }
      })
    }
  },
  updatePostCommentsDetails(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.uid})
    if (!posts.length) return

    for (let i = 0; i < posts.length; i++) {
      posts[i].comments.total_count = posts[i].comments.total_count + payload.newCommentsCount
      posts[i].comments.next_index = posts[i].comments.next_index === -1 ? -1 : posts[i].comments.next_index + payload.newCommentsCount
      posts[i].stats.comments_count = posts[i].stats.comments_count + payload.newCommentsCount
    }
  },
  deletePost(state, postUid) {
    state.posts = state.posts.filter(({uid}) => uid !== postUid );
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setPostBoxLoading(state, payload) {
    state.isPostBoxLoading = payload;
  },
  setPostBoxError(state, payload) {
    state.textBoxError = payload
  },
  setPostLoading(state, payload) {
    state.isPostLoading = payload;
  },
  setPost(state, payload) {
    state.currentPost = payload.posts[0];
  },
  //COMMENTS
  addComment(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.postUid})
    if (!posts.length) return

    for (let i = 0; i < posts.length; i++) {
      if (payload.data.parent_comment_uid ) {
        //  REPLY
        let parentComment;
        parentComment = posts[i].comments.priority.list.find(comment => comment.uid === payload.data.parent_comment_uid);
        if (!parentComment) {
          parentComment = posts[i].comments.normal.list.find(comment => comment.uid === payload.data.parent_comment_uid);
        }

        if (!parentComment) return

        if (payload.data.priority) {
          const oldComment = parentComment.replies.priority.list.find(comment => comment.uid === payload.data.uid);
          if (oldComment) return

          parentComment.replies.priority.list.push(payload.data)
        } else {
          const oldComment = parentComment.replies.normal.list.find(comment => comment.uid === payload.data.uid);
          if (oldComment) return

          parentComment.replies.normal.list.unshift(payload.data)
        }

      } else {
      //BASIC COMMENT
        if (payload.data.priority) {
          const oldComment = posts[i].comments.priority.list.find(comment => comment.uid === payload.data.uid);
          if (oldComment) return

          posts[i].comments.priority.list.push(payload.data)
        } else {
          const oldComment = posts[i].comments.normal.list.find(comment => comment.uid === payload.data.uid);
          if (oldComment) return

          posts[i].comments.normal.list.unshift(payload.data)
        }
      }
    }
  },
  addComments(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.postUid})
    if (!posts.length) return

    for (let i = 0; i < posts.length; i++) {
      const oldUids = posts[i].comments[payload.comment_type].list.reduce((mem, item) => {
        mem.push(item.uid)
        return mem
      }, [])

      const filteredPosts = payload.list.filter(({uid}) => oldUids.indexOf(uid) < 0 )

      if (payload.comment_type === 'priority') {
        posts[i].comments[payload.comment_type].list = [...filteredPosts, ...posts[i].comments[payload.comment_type].list]
      } else {
        posts[i].comments[payload.comment_type].list = [...posts[i].comments[payload.comment_type].list, ...filteredPosts.reverse()]
      }

      posts[i].comments[payload.comment_type].next_index = payload.next_index
      posts[i].comments[payload.comment_type].total_count = payload.total_count
      posts[i].comments_page_count = payload.page_count
    }
  },
  addReplies(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.postUid})
    if (!posts.length) return

    for (let i = 0; i < posts.length; i++) {
      const parentComment = posts[i].comments[payload.parent_comment_type].list.find(comment => payload.parent_comment_uid === comment.uid );
      if (payload.reply_type === 'priority') {
        parentComment.replies[payload.reply_type].list = [...payload.list, ...parentComment.replies[payload.reply_type].list]
      } else {
        parentComment.replies[payload.reply_type].list = [...parentComment.replies[payload.reply_type].list, ...payload.list.reverse()]
      }
      parentComment.replies[payload.reply_type].next_index = payload.next_index
      parentComment.replies[payload.reply_type].total_count = payload.total_count
      posts[i].comments_page_count = payload.page_count
    }
  },
  updateCommentAttrs(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.postUid})
    if (!posts.length) return
    let comment;

    for (let i = 0; i < posts.length; i++) {
      comment = posts[i].comments.priority.list.find(comment => comment.uid === payload.uid);
      if (!comment) {
        comment = posts[i].comments.normal.list.find(comment => comment.uid === payload.uid);
      }
      payload.attrs.map((item) => {
        if (Array.isArray(item.key)) {
          if (item.key[2]) {
            comment[item.key[0]][item.key[1]][item.key[2]] = item.value
          } else {
            comment[item.key[0]][item.key[1]] = item.value
          }
        } else {
          comment[item.key] = item.value
        }
      })
    }
  },
  updateComment(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.postUid})
    if (!posts.length) return
    let comment;

    delete payload.postUid
    for (let i = 0; i < posts.length; i++) {
      if (payload.parent_comment_uid) {

        let parentComment;
        parentComment = posts[i].comments.normal.list.find(comment => comment.uid === payload.parent_comment_uid);

        if (!parentComment) {
          parentComment = posts[i].comments.priority.list.find(comment => comment.uid === payload.parent_comment_uid);
        }

        if (payload.priority) {
          comment = parentComment.replies.priority.list.find(comment => comment.uid === payload.commentUid);
        } else {
          comment = parentComment.replies.normal.list.find(comment => comment.uid === payload.commentUid);
        }

      } else {
        comment = posts[i].comments.priority.list.find(comment => comment.uid === payload.commentUid);
        if (!comment) {
          comment = posts[i].comments.normal.list.find(comment => comment.uid === payload.commentUid);
        }
      }
      Object.assign(comment, payload)
    }
  },
  deleteComment(state, payload) {
    const posts = this.getters['posts/findPosts']({uid: payload.postUid})
    if (!posts.length) return

    for (let i = 0; i < posts.length; i++) {
      if (payload.parent_comment_uid) {
        let parentComment;
        parentComment = posts[i].comments.normal.list.find(comment => comment.uid === payload.parent_comment_uid);

        if (!parentComment) {
          parentComment = posts[i].comments.priority.list.find(comment => comment.uid === payload.parent_comment_uid);
        }
        if (payload.priority) {
          const comments = parentComment.replies.priority.list.filter(comment => comment.uid !== payload.commentUid);
          parentComment.replies.priority.list = [].concat(comments)
        } else {
          const comments = parentComment.replies.normal.list.filter(comment => comment.uid !== payload.commentUid);
          parentComment.replies.normal.list = [].concat(comments)
        }
      } else {

        if (payload.priority) {
          const comments = posts[i].comments.priority.list.filter(comment => comment.uid !== payload.commentUid);
          posts[i].comments.priority.list = [].concat(comments)
        } else {
          const comments = posts[i].comments.normal.list.filter(comment => comment.uid !== payload.commentUid);
          posts[i].comments.normal.list = [].concat(comments)
        }
      }
    }
  },
  clearPosts(state) {
    state.posts = [];
    state.next_time = null;
    state.total_count = null;
  },
  setController(state, payload) {
    state.controller = payload
  },
}