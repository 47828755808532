export default {
  findTag: (state) => (uid) => {
    // if (!state.tags[uid]) console.warn(`CAN'T find tag ${uid}`);
    return state.tags[uid]
  },
  currentTag(state) {
    return state.currentTag;
  },
  currentTagUid(state) {
    return state.currentTag.uid || 1;
  },
  currentTagType(state) {
    return state.currentTag.type;
  },
  currentTagSlug(state) {
    return state.currentTag.tag;
  },
  currentTagSlugNoHome(state) {
    return state.currentTag.tag === 'home' ? '' : state.currentTag.tag;
  },
  isTagLoading(state) {
    return state.isLoading;
  },
  getTags: (state) => (uids) => {
    return uids.reduce((memo, uid) => {
      const tag = state.tags[uid]
      if (tag) {
        memo.push(tag)
      }
      // else {
      //   console.warn(`getTags: CAN'T find tag ${uid}`);
      // }
      return memo
    }, [])
  },
  getFetchingUids(state) {
    return state.fetchingUids
  },
}