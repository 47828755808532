export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setWhoTalked(state, payload) {
    state.whoTalked.items = payload.items
    state.whoTalked.title = payload.title
    state.whoTalked.subline = payload.subline
    state.whoTalked.startIndex = payload.start_index
    state.whoTalked.nextIndex = payload.next_index
  },
}