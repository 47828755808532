export default {
  setVariable({ commit }, payload) {
    commit('setVariable', payload)
  },
  async setDarkMode({ commit, dispatch, rootGetters }, payload) {
    if (!rootGetters['auth/isLoggedIn']) {
      return commit('setDarkMode', payload)
    }

    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/settings',
      method: 'PUT',
      body: {color_mode: payload.dark_mode === 'on' ? 'night' : 'day'},
      errorMessage: 'Failed to setDarkMode!'
    }, { root: true } )

    if (responseData && responseData.success) {
      commit('setDarkMode', payload)
    } else {
      dispatch('setNotice', {
        title: "Settings",
        message: 'Failed to save dark mode!' ,
        type: 'alert'
      }, {root: true})
    }
  },
  async setTheme({ commit, dispatch, rootGetters }, payload) {
    if (!rootGetters['auth/isLoggedIn']) {
      return commit('setTheme', payload)
    }

    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/settings',
      method: 'PUT',
      body: {color_theme: payload.theme},
      errorMessage: 'Failed to setTheme!'
    }, { root: true } )

    if (responseData && responseData.success) {
      commit('setTheme', payload)
    } else {
      dispatch('setNotice', {
        title: "Settings",
        message: 'Failed to save color theme!' ,
        type: 'alert'
      }, {root: true})
    }
  },

}