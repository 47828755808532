export default {
  async getFavs({dispatch, commit, rootGetters}) {
    let version = 2;
    let url = 'users/current_user/favorites';

    if (!rootGetters['auth/isLoggedIn']) {
      if (localStorage.getItem('signedOutFavs') !== null) {
        const savedUids = JSON.parse(localStorage.getItem('signedOutFavs'))
        await dispatch('tags/fetchTagsByUid', savedUids, { root: true })
        return commit('setFavs', {list: savedUids});
      }

      version = 3;
      url = 'tags/suggestions';
    } else {
      if (localStorage.getItem('signedOutFavs') !== null && window.config.VUE_APP_FAV_AFTER_SIGN_IN) {
        const savedUids = JSON.parse(localStorage.getItem('signedOutFavs'))
        localStorage.removeItem('signedOutFavs')
        await dispatch('batchFavToggle', {uids: savedUids, value: true})
      }
    }
    url += '?count=100'
    const responseData = await dispatch('makeFetch', {
      url,
      method: 'GET',
      version,
      errorMessage: 'Failed to Get Favs!'
    }, { root: true } )

    if (responseData) {
      await dispatch('tags/addTags', responseData.tags, { root: true })
      commit('setFavs', {list: responseData.list});
      if (!rootGetters['auth/isLoggedIn'] && localStorage.getItem('signedOutFavs') === null) {
        dispatch('syncLocalStorage')
      }
    } else {
      dispatch('setNotice', {
        title: "Pinned",
        message: 'Failed to getFavs!' ,
        type: 'alert'
      }, {root: true})
    }
  },
  async getLandingSuggestions({dispatch, commit}) {
    const responseData = await dispatch('makeFetch', {
      url: 'tags/suggestions?count=100',
      method: 'GET',
      version: 3,
      errorMessage: 'Failed to Get Favs!'
    }, { root: true } )
    if (responseData) {
      await dispatch('tags/addTags', responseData.tags, { root: true })
      commit('setLandingData', {list: responseData.list});
    } else {
      dispatch('setNotice', {
        title: "Landing Data",
        message: 'Failed to getLandingSuggestions!' ,
        type: 'alert'
      }, {root: true})
    }
  },
  async getSuggestions({dispatch, commit}) {
    commit('setLoading', true)
    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/suggestions',
      method: 'GET',
      errorMessage: 'Failed to Get Fav Suggestions!'
    }, { root: true } )

    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('setSuggestions', responseData);
    commit('setLoading', false)
  },

  async toggleFav({dispatch, commit, state, rootGetters}, {uid, value}) {
    if (state.toggleLoading) {
      return
    }

    if (!rootGetters['auth/isLoggedIn']) {
      dispatch('signedOutToggleFav', {uid, value})
      return dispatch('syncLocalStorage')
    }

    commit('setToggleLoading', true)
    const method = value ? 'PUT' : 'DELETE'
    const responseData = await dispatch('makeFetch', {
      url: `tags/${uid}/favourite`,
      method: method,
      errorMessage: 'Failed to toggle Fav Tag!'
    }, { root: true } )

    commit('setToggleLoading', false)

    if (responseData.success) {
      dispatch('tags/updateTag', {uid: uid, attrs: [
        { key: ['prefs','favourite_flag'], value: value }
        ]}, { root: true })
      await commit('toggleFav', {uid: uid, flag: value})
    }
  },
  async batchFavToggle({dispatch, commit, state, rootGetters}, {uids, value}) {
    if (state.toggleLoading) {
      return
    }

    if (!rootGetters['auth/isLoggedIn']) {
      return dispatch('signedOutBatchFavToggle', {uids, value})
    }

    const tag_uids = uids.concat().reverse()
    commit('setToggleLoading', true)
    const method = value ? 'POST' : 'DELETE'
    const responseData = await dispatch('makeFetch', {
      url: `users/current_user/favorites`,
      method: method,
      body: {tag_uids: tag_uids},
      errorMessage: 'Failed to batch Fav Toggle!'
    }, { root: true } )

    commit('setToggleLoading', false)

    if (responseData.success) {
      uids.map((uid, index) => {
        dispatch('tags/updateTag', {uid: uid, attrs: [
            { key: ['prefs','favourite_flag'], value: value }
          ]}, { root: true })
        const revertedUid = uids[uids.length - index - 1]
        commit('toggleFav', {uid: revertedUid, flag: value})
      })
    }
  },
  clearFavs({commit}) {
    commit('setFavs', {list: []});
  },
  clearLandingData({commit}) {
    commit('setLandingData', {list: []});
  },

//  SIGNED OUT ACTIONS

  syncLocalStorage({state}) {
    localStorage.setItem('signedOutFavs', JSON.stringify(state.favs))
  },
  signedOutBatchFavToggle({dispatch}, {uids, value}) {
    uids.slice(0).reverse().map((uid) => {
      dispatch('signedOutToggleFav', {uid, value})
    })
    dispatch('syncLocalStorage')
  },
  signedOutToggleFav({dispatch, commit}, {uid, value}) {
    dispatch('tags/updateTag', {uid: uid, attrs: [
        { key: ['prefs','favourite_flag'], value: value }
      ]}, { root: true })
    commit('toggleFav', {uid: uid, flag: value})
  },
}