<template>
  <div v-if="tag">
    <span class="me-2 flex-shrink-0 position-relative">
      <img :src="tagIcon" class="rounded-circle img-fluid founded-img avatar">
      <span v-if='reaction && reaction !== "none"' class="reaction-img-wrapper small" :class="reaction+'-wrapper'">
        <heart-icon v-if="reaction === 'love'" size="xs"/>
        <i v-else :class='reaction'></i>
      </span>
    </span>
    <span class="d-flex justify-content-between w-100 min-width-0">
      <span class="d-flex flex-column line-height-90 min-width-0">
        <span class="text-truncate">
          <base-tag-link class="link-color fs-80 fw-bold" :uid="tag.uid"/>
          <i class="icon-check-square theme-color ms-1 fs-80" v-if="tag.verified"></i>
        </span>
        <span class="text-truncate">
          <base-tag-link v-if="tagTitle" class="link-main fs-70" :uid="tag.uid" >
            {{tagTitle}}
          </base-tag-link>
          <base-tag-link v-if="tag.org_uid > 0" class="link-main fs-70" :uid="tag.org_uid" />
        </span>
        <span>
          <x-teammates-dd-link link-class="link-main fs-70" :uid="tag.uid" :is-org="tag.isOrg" :mutual-teammates-count="tag.prefs?.user_mutual_teammates_count" :teammates-count="tag.stats?.teammates_count"></x-teammates-dd-link>
        </span>
      </span>
      <span v-if="isTMButtonVisible || inviteTagUid || showCallOut" class="align-self-center d-flex flex-shrink-0">
        <teamup-button
            v-if="isTMButtonVisible"
            :uid="tag.uid"
            :status="tag.user?.prefs?.teammate_status"
            class-names="lh-sm py-1 px-2 fs-80"
            :visible="true"
        />
         <view-invite-post-button
             v-if="inviteTagUid"
             :uid="uid"
             :invite-tag-uid="inviteTagUid"
             class="btn btn-color lh-sm py-1 px-2 fs-80 ms-2"
         />
        <invite-button v-if="tag.prefs.callout_flag && showCallOut"
                       :payload="{tag_uid: tag.uid}"
                       :display-name="tag.display_name"
                       :invite-to-count="tag.stats.call_out_to_count"
                       class="btn btn-color lh-sm py-1 me-2 flex-shrink-0"
        />
      </span>
    </span>
  </div>
</template>

<script>
import tagIcon from '../../../hooks/tagIcon'
import XTeammatesDdLink from "../../UI/XTeammatesDdLink";
import TeamupButton from "../../buttons/TeamupButton";
import ViewInvitePostButton from "@/components/buttons/ViewInvitePostButton";
import HeartIcon from "@/components/icons/HeartIcon";
import fetchTagIfNotFound from "@/hooks/fetchTagIfNotFound";


export default {
  name: "TagItem",
  components: {HeartIcon, ViewInvitePostButton, TeamupButton, XTeammatesDdLink},
  props: ['uid', 'reaction', 'inviteTagUid', 'showCallOut'],
  mixins: [tagIcon, fetchTagIfNotFound],
  computed: {
    tagTitle() {
      return this.tag.user && this.tag.user.is_employee ? this.tag.user.employee_title : false
    },
    isTMButtonVisible() {
      return this.tag.user?.prefs?.show_as_teammates && this.tag.user?.prefs?.teammate_status === 'none'
    },
  }
}
</script>
