<template>
  <div class="dropdown">
    <slot bs-toggle="dropdown" link-id="invitesToDD"></slot>

    <ul class="dropdown-menu" aria-labelledby="invitesToDD">
      <li><a class="dropdown-item" href="#">invitesToDD</a></li>
      <li><a class="dropdown-item" href="#">Another action</a></li>
      <li><a class="dropdown-item" href="#">Something else here</a></li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: ['uid'],
    created() {
      // console.log('invites-to dd')
      // count = @collection.total_count
      // text = ""
      // text += Ch.m.h.numeral(count) if count
      // url = config.apiPath + "/v2/tags/posts/#{uid}/users_click_drive"
      // text += " #{owl.pluralize("Person", count)}"
      // text += if count is 1 then " likes this" else " like this"
    },

  }
</script>