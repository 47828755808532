<template>
    <span>
        {{ timeAgo }}
    </span>
</template>


<script>
  import { format } from 'timeago.js';

    export default {
        props: ['editedAt', 'createdAt', 'uid', 'postType', 'caption', 'postfix'],
        computed: {
            timeAgo() {
                const timestamp = this.editedAt || this.createdAt
                const timeago = (new Date(timestamp)).toISOString()
                if ( this.uid === 1) {
                    const monthNames = [
                        "Jan.", "Feb.", "Mar.",
                        "Apr.", "May", "June", "July",
                        "Aug.", "Sept.", "Oct.",
                        "Nov.", "Dec."
                    ]
                    const fixed_date = new Date(timeago)
                    const day = fixed_date.getDate()
                    const monthIndex = fixed_date.getMonth()
                    const year = fixed_date.getFullYear()
                    return monthNames[monthIndex] + ' ' + day + 'th ' + year;
                } else {
                    let caption = ''
                    const postfix = this.postfix || '';
                    if (this.caption) {
                      caption = this.caption + ' '
                    } else {
                      caption = this.editedAt ? "edited " : "posted ";
                      if ( this.uid === 1 || ['auto_user_teamup','auto_leader_signup'].indexOf(this.postType) >= 0) {
                        caption = '';
                      }
                    }
                    return caption + format(timeago, 'dl') + ' ' + postfix ;
                }
            },
        },

    }
</script>