<template>
  <div class="dropdown fs-80 d-inline-block">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport" link-id="peopleLikeDd"></slot>

    <ul class="dropdown-menu" aria-labelledby="peopleLikeDd">
      <li class="dropdown-header px-2 py-1 fs-80">
        <base-numeral :num="driveClickCount"></base-numeral>
        <base-pluralize :num="driveClickCount" word="Person"></base-pluralize>
        {{pluralizeLikes}} this
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item-text p-0">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list v-if="!isLoading" :list="drivenByUids"></tags-list>
          <base-spinner v-if="isLoading" class="p-3"></base-spinner>
        </div>
        <div v-if="drivenByUids.length === 0 && !isLoading" class="text-grey text-center pt-2 pb-3">
          No Items
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import ddFetchData from "../../hooks/ddFetchData";
  import TagsList from "./parts/TagsList";
  import pluralize from 'pluralize';

  export default {
    name: 'peopleLikeDd',
    components: {TagsList},
    mixins: [ddFetchData],
    props: ['uid', 'driveClickCount', 'drivenByUids'],
    data() {
      return {
        isLoading: true,
        nextIndex: -1,
      }
    },
    computed: {
      pluralizeLikes() {
        if (this.driveClickCount === 0) {
          return 'like'
        } else if (this.driveClickCount === 1) {
          return 'likes'
        } else {
          return pluralize('like', this.driveClickCount)
        }
      },
    },
    methods: {
      async fetchData() {
        this.isLoading = true
        await this.$store.dispatch('tags/fetchTagsByUid', this.drivenByUids)
        this.isLoading = false
      }
    },

  }
</script>