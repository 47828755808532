<template>
  <div class="dropdown fs-80 d-inline-block">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport" link-id="resolvingConflictDd"></slot>

    <ul class="dropdown-menu" aria-labelledby="resolvingConflictDd">
      <li class="dropdown-header px-2 py-1 fs-80">
        {{tagDisplayName}} working on:
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item-text p-0">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list :list="list"></tags-list>
          <base-spinner v-if="isLoading || moreIsLoading" class="p-3"></base-spinner>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ddFetchData from "../../hooks/ddFetchData";
import TagsList from "./parts/TagsList";
import {SPECIAL_PAGE_NAMES} from "@/const/tags";

export default {
  name: 'invitesToDD',
  components: {TagsList},
  mixins: [ddFetchData],
  props: ['uid'],
  data() {
    return {
      errorMessage: 'Failed to Get resolving!',
    }
  },
  computed: {
    url() {
      return `tags/${this.uid}/resolving?count=30`
    },
    tag() {
      return this.$store.getters['tags/findTag'](this.uid)
    },
    tagDisplayName() {
      let display_name
      if (this.tag.type === 'page') {
        display_name = 'The ' + SPECIAL_PAGE_NAMES[this.uid] + ` ${this.tag.team_word || this.teamWord } are`
      } else if (this.tag.isOrg) {
        display_name = 'The ' + this.tag.display_name + ` ${this.tag.team_word || this.teamWord } is`
      } else {
        display_name = this.tag.display_name + ' is'
      }
      return display_name
    }
  },
}
</script>