import CommentContent from "../components/feed/comments/CommentContent";
import TheReplies from "../components/feed/comments/replies/TheReplies";

export default {
  components: {
    CommentContent,
    TheReplies
  },
  data() {
    return {
      editMode: false,
      isLoading: false,
      optionVisible: false,
    }
  },
  created() {
    if (!this.$store.getters['tags/findTag'](this.comment.writer_tag_uid)) {
      this.fetchWriter()
    }
  },
  computed: {
    writer() {
      return this.$store.getters['tags/findTag'](this.comment.writer_tag_uid)
    },
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode
      this.$store.dispatch('setEditMode', this.editMode)
    },
    async fetchWriter() {
      const fetchingUids = this.$store.getters['tags/getFetchingUids']
      if (fetchingUids.indexOf(this.comment.writer_tag_uid) === -1) {
        await this.$store.dispatch('tags/fetchTagsByUid', [this.comment.writer_tag_uid])
      }
    },
    async updateComment(updatedPost) {
      this.isLoading = true
      const responseData = await this.$store.dispatch('posts/updateComment', {
        postUid: this.postUid,
        commentUid: this.comment.uid,
        data: updatedPost
      })
      this.isLoading = false
      if (responseData && responseData.success) {
        this.toggleEditMode()
      }
    },
    async deleteComment() {
      this.isLoading = true
      await this.$store.dispatch('posts/deleteComment', {
        postUid: this.postUid,
        commentUid: this.comment.uid,
        parent_comment_uid: this.comment.parent_comment_uid,
        commentsCount: this.post.stats.comments_count,
      })
      this.isLoading = false
    },
    async flagComment() {
      this.isLoading = true
      await this.$store.dispatch('posts/flagComment', {
        postUid: this.postUid,
        commentUid: this.comment.uid
      })
      this.isLoading = false
    },
    async hideComment() {
      this.isLoading = true
      await this.$store.dispatch('posts/hideComment', {
        postUid: this.postUid,
        commentUid: this.comment.uid
      })
      this.isLoading = false
    },
    async react(reaction) {
      if(this.$store.getters['auth/isLoggedIn']) {
        this.$store.dispatch('posts/reactOnComment', {
          postUid: this.postUid,
          commentUid: this.comment.uid,
          parent_comment_uid: this.comment.parent_comment_uid,
          priority: this.comment.priority,
          reaction: reaction,
          prevReaction: this.comment.prefs.reaction_flag,
          promoTagUids: this.post.promo_tag_uids,
        })
      } else {
        await this.$router.push({name: 'sign-in', params: {slug: this.$store.getters['tags/currentTagSlugNoHome']}})
      }
    },
  },
}