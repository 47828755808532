export default {
  isProfileLoading(state) {
    return state.isLoading
  },
  isTitlesLoading(state) {
    return state.isTitlesLoading
  },
  profile(state) {
    return state.profile
  },
  titles(state) {
    return state.titles
  },
}