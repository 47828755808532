<template>
  <div class="text-grey text-end clearfix comment leader-highlighted pt-1 ps-2 pe-2">
    <span class="align-middle text-end">
      <span v-if="totalCount <= 6">
        {{stats.priority_count > 1 ? "Leaders'" : "Leaders'"}} dialogue
      </span>
      <span v-if="totalCount > 6 && xMoreCount">
        <span class="align-middle">
          <base-numeral :num="xMoreCount"/> more <base-pluralize :num="xMoreCount" word="reply"/> from
          <base-dd v-if="nextStats.leaders_count > 0" dd="who-commented" :uids="leadersImg" who-commented="leader">
            {{nextStats.leaders_count > 1 ? "leaders'" : "leader's"}}
          </base-dd>
          dialogue
        </span>
        <span v-if="nextStats.leaders_count > 0" class="comment-stats-icons d-inline-flex align-middle mx-2">
          <comment-stats-image v-for="uid in leadersImg" :uid="uid" :key="uid"/>
        </span>
      </span>
      <span>
        <router-link v-if="!isPostZoom"  :to="{name: 'post', params: { post_uid: postUid, slug: $store.getters['tags/currentTagSlugNoHome']}, query: {count: 100, parent_comment_uid: parentCommentUid}}" class="no-decor mx-1">
          <i class="icon-full-screen fs-5 theme-color align-middle"></i>
        </router-link>
        <i v-show="!isInitialState" @click="setInitialState" class="icon-minus-outline fs-4 theme-color align-middle pointer mx-1"></i>
        <i v-if="xMoreCount" @click="toggleReplies" class="icon-plus-outline fs-4 theme-color align-middle pointer mx-1"></i>
      </span>
    </span>
  </div>
</template>

<script>
import CommentStatsImage from "@/components/feed/partials/CommentStatsImage";

export default {
  name: "RepliesPriorityStats",
  components: {CommentStatsImage},
  inject: ['isPostZoom', 'postUid'],
  props: {
    replyType: String,
    stats: {
      default: {}
    },
    nextStats: {
      default: {}
    },
    visibleCount: Number,
    totalCount: Number,
    parentCommentUid: Number,
    isInitialState: Boolean,
    toggleIsCollapsed: Function,
    setInitialState: Function,
    toggleReplies: Function,
  },
  computed: {
    teammatesImg() {
      return this.nextStats.teammates.slice(0, 3)
    },
    leadersImg() {
      return this.nextStats.leaders.slice(0, 3)
    },
    xMoreCount() {
      return this.totalCount - this.visibleCount
    },
  },
}
</script>
