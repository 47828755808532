import {removeCookie} from "@/functions/cookie";

export default {
  async signIn({dispatch}, payload) {
    return dispatch('auth', {
      data: payload,
      mode: 'login'
    })
  },
  async signUp({dispatch}, payload) {
    return dispatch('auth', {
      data: payload,
      mode: 'signup'
    })
  },
  async forgotPassword({dispatch}, payload) {
    return dispatch('makeFetch', {
      full_url: 'auth/forgot_password_web',
      method: 'POST',
      errorMessage: 'Failed to get Forgot Password!',
      body: payload
    }, { root: true } )
  },
  async resetPassword({dispatch}, payload) {
    return dispatch('makeFetch', {
      full_url: 'auth/reset_password_web',
      method: 'POST',
      errorMessage: 'Failed to Reset Password!',
      body: payload
    }, { root: true } )
  },
  logout({commit, dispatch, rootGetters}) {
    dispatch('favs/clearFavs', {}, {root: true})
    commit('setToken', { token: null });
    commit('setUser');
    commit('clearNotices');
    commit('clearRequests');
    localStorage.removeItem('token');
    removeCookie('access_token')
    rootGetters['consumer']?.deAuth()
    dispatch('config')
  },
  async auth({commit, dispatch, rootGetters}, payload) {
    const responseData = await dispatch('makeFetch', {
      full_url: payload.mode === 'login' ? 'auth/dt_signon' : 'auth/dt_signup',
      method: 'POST',
      errorMessage: 'Failed to Auth!',
      body: payload.data
    }, { root: true } )

    if (responseData && responseData.success) {
      const base64token = window.btoa(responseData.dt_access_token + ':')
      localStorage.setItem('token', base64token);
      commit('setToken', {token: base64token});
      await dispatch('autoLogin');
      rootGetters['consumer']?.auth()
      dispatch('config')
      return responseData
    } else {
      dispatch('config')
      return responseData
    }
  },
  async socialAuth({commit, dispatch}, payload) {
    localStorage.setItem('token', payload.accessToken);
    commit('setToken', {token: payload.accessToken});
    dispatch('config')
    await dispatch('autoLogin');
  },
  async autoLogin({commit, dispatch }) {
    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user',
      method: 'GET',
      errorMessage: 'Failed to Auth!'
    }, { root: true } )

    if (responseData.user_tag) {
      dispatch('tags/addTags', [responseData.user_tag], { root: true })
    }

    if (responseData.user_tag && responseData.user_tag.org_uids?.length) {
      await dispatch('tags/fetchTagsByUid', responseData.user_tag.org_uids, { root: true });
    }
    if (responseData.settings) {
      commit('config/setDarkMode', {dark_mode: responseData.settings.color_mode}, {root: true})
      commit('config/setTheme', {theme: responseData.settings.color_theme}, {root: true})
    }
    commit('setUser', responseData);
    commit('setIsLoading', false);
    await dispatch('fetchNotifications');
  },
  async config({dispatch }) {
    const responseData = await dispatch('makeFetch', {
      full_url: 'app/config',
      method: 'GET',
      is_text: true,
      errorMessage: 'Failed to Get Config!'
    }, { root: true } )
    window.config = {...window.config, ...responseData}
    this.globalProps.apiPath = responseData.VUE_APP_API_PATH
    this.globalProps.imgPath = responseData.VUE_APP_IMG_PATH
    this.globalProps.logoPostUid = Number(responseData.VUE_APP_LOGO_POST_UID)
    this.globalProps.worldPeacePostUid = Number(responseData.VUE_APP_WORLDPEACE_POST_UID)
    this.globalProps.postCharsLimit = Number(responseData.VUE_APP_POST_CHAR_LIMIT)
    this.globalProps.teamWord = responseData.VUE_APP_TEAM_WORD
    this.globalProps.dateTimeFormat = responseData.VUE_APP_DATETIME_FORMAT
    this.globalProps.minYears = Number(responseData.VUE_APP_MIN_YEARS)
    this.globalProps.showPinned = responseData.VUE_APP_SHOW_PINNED
    this.globalProps.showCopyButton = responseData.VUE_APP_SHOW_COPY_BUTTON
    this.globalProps.popDelay = responseData.VUE_APP_POP_DELAY
    this.globalProps.isLiDisabled = responseData.VUE_APP_IS_LI_DISABLED
    this.globalProps.isFbDisabled = responseData.VUE_APP_IS_FB_DISABLED
    this.globalProps.blankMapCover = responseData.VUE_APP_MAP_COVER_IMAGE
    this.globalProps.defaultQrBg = responseData.VUE_APP_DEFAULT_QR_BG
    this.globalProps.signedOutQr = responseData.VUE_APP_SIGNED_OUT_QR
    this.globalProps.commentsPriorityLimit = responseData.VUE_APP_COMMENTS_PRIORITY_LIMIT
    this.globalProps.commentsNormalLimit = responseData.VUE_APP_COMMENTS_NORMAL_LIMIT
    this.globalProps.favAfterSignIn = responseData.VUE_APP_FAV_AFTER_SIGN_IN
    this.globalProps.defaultThemeColor = responseData.VUE_APP_DEFAULT_THEME_COLOR
    this.globalProps.inspirationPostUids = responseData.VUE_APP_INSPIRATION_POST_UIDS
    this.globalProps.mailtoLink = responseData.VUE_APP_REACH_OUT_MAILTO
  },
  async sendVerificationCode({dispatch}, payload) {
    return await dispatch('makeFetch', {
      full_url: 'auth/verif_code',
      method: 'POST',
      body: {email: payload},
      errorMessage: 'Failed to sendVerificationCode!'
    }, { root: true } )
  },
  async verifyCode({dispatch}, payload) {
    return await dispatch('makeFetch', {
      full_url: 'auth/check_verif_code',
      method: 'POST',
      body: payload,
      errorMessage: 'Failed to sendVerificationCode!'
    }, { root: true } )
  },
  async getTagByEmailDomain({dispatch}, payload) {
    return await dispatch('makeFetch', {
      url: `tag_by_email_domain?email=${encodeURIComponent(payload)}`,
      method: 'GET',
      errorMessage: 'Failed to getTagByEmailDomain!'
    }, { root: true } )
  },

  async getEmailDomainByTag({dispatch}, payload) {
    return await dispatch('makeFetch', {
      url: `tags/${payload}/email_domain`,
      method: 'GET',
      errorMessage: 'Failed to getEmailDomainByTag!'
    }, { root: true } )
  },
  async getTitlesByEmailDomain({dispatch}, payload) {
    return await dispatch('makeFetch', {
      full_url: `auth/positions_availability?uniquifier=${payload}`,
      method: 'GET',
      errorMessage: 'Failed to get getTitlesByEmailDomain!',
    }, { root: true } )
  },
  async checkEmailType({dispatch}, payload) {
    return await dispatch('makeFetch', {
      full_url: `util/email_type?email=${encodeURIComponent(payload)}`,
      method: 'GET',
      errorMessage: 'Failed to sendVerificationCode!'
    }, { root: true } )
  },
  async fetchNotifications({commit, state, dispatch, rootState}) {
    if (!state.isLoggedIn) return

    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/updates',
      method: 'GET',
      errorMessage: 'Failed to Update Notifications!'
    }, { root: true } )

    commit('updateNotifications', responseData);
    window.setTimeout(() => {
      dispatch('fetchNotifications')
    }, rootState.config.updatesDelay)
  },

  updateNotifications({commit}, payload){
    commit('updateNotifications', payload)
  },

  async getNotices({dispatch, commit, state}, payload = {}) {
    if (payload.more && state.notices.nextIndex === -1) return

    commit('setNoticeLoading', true)
    const startIndex = payload.more ? state.notices.nextIndex : 0

    const {events, next_index, notices_count, tags} = await dispatch('makeFetch', {
      url: `users/current_user/notices?count=15&start_index=${startIndex}`,
      method: 'GET',
      errorMessage: 'Failed to Get Notices!'
    }, { root: true } )

    await dispatch('tags/addTags', tags, { root: true })

    commit('setNoticesData', {events, next_index, notices_count, more: payload.more})
    commit('setNoticeLoading', false)

  },
  async getBlocked({dispatch, commit, state}, payload = {}) {
    if (payload.more && state.blocked.nextIndex === -1) return

    commit('setBlockedLoading', true)
    const startIndex = payload.more ? state.blocked.nextIndex : 0

    const {list, next_index, tags} = await dispatch('makeFetch', {
      url: `users/current_user/blocked_users_list?count=15&start_index=${startIndex}`,
      method: 'GET',
      errorMessage: 'Failed to Get Blocked!'
    }, { root: true } )

    await dispatch('tags/addTags', tags, { root: true })

    commit('setBlocked', {list, next_index})
    commit('setBlockedLoading', false)
  },
  removeBlocked({commit}, payload) {
    commit('removeBlocked', payload)
  },
  clearBlocked({commit}) {
    commit('clearBlocked')
  },
  async getFollows({dispatch, commit, state}, payload = {}) {
    if (payload.more && state.follows.nextIndex === -1) return

    commit('setFollowsLoading', true)
    const startIndex = payload.more ? state.follows.nextIndex : 0

    const {list, next_index, tags} = await dispatch('makeFetch', {
      url: `users/current_user/follows?count=15&start_index=${startIndex}`,
      method: 'GET',
      errorMessage: 'Failed to Get Follows!'
    }, { root: true } )

    await dispatch('tags/addTags', tags, { root: true })

    commit('setFollows', {list, next_index})
    commit('setFollowsLoading', false)
  },
  removeFollow({commit}, payload) {
    commit('removeFollow', payload)
  },
  clearFollows({commit}) {
    commit('clearFollows')
  },
  removeNoticesByPostUid({commit}, payload) {
    commit('removeNoticesByPostUid', payload)
  },
  async toggleReadStatus({commit, dispatch}, {eventUid, unread}) {
    const status = unread ? 'read' : 'unread'

    const responseData = await dispatch('makeFetch', {
      url: `users/current_user/notices/mark_${status}`,
      method: 'PUT',
      body: {event_uids: [eventUid]},
      errorMessage: 'Failed to toggle Read Status!'
    }, { root: true } )

    if (responseData.success) {
      commit('toggleReadStatus', {eventUid, unread: !unread})

      if (unread) {
        commit('toggleToastReadStatus', {eventUid})
      }
    } else {
      dispatch('setError', responseData.message, {root: true})
    }
  },
  async markAllAsRead({commit, dispatch}, {firstEventUid}) {
    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/notices/mark_all_read',
      method: 'PUT',
      body: {top_notice_uid: firstEventUid},
      errorMessage: 'Failed to mark All As Read!'
    }, { root: true } )

    if (responseData.success) {
      commit('markAllAsRead')
    } else {
      dispatch('setError', responseData.message, {root: true})
    }
  },
  async setOption({dispatch, commit}, payload) {
    const data = {}
    data[payload.key] = payload.value

    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/settings',
      method: 'PUT',
      errorMessage: 'Failed to toggle current user Option!',
      body: data
    }, { root: true } )
    if (responseData.success) {
      commit('toggleOption', payload);
    }
  },
  async deleteAccount({dispatch}) {
    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/delete_account',
      method: 'POST',
      errorMessage: 'Failed to delete Account!',
    }, { root: true } )
    if (responseData.success) {
      dispatch('logout');
    }
  },
  async getTeamupRequests({dispatch, commit}, payload = {}) {
    commit('setRequestsLoading', true);

    //TODO ALEX ADD more to teamup requests dd
    if (payload.more) {
      console.log('MORE getTeamupRequests')
    }

    const responseData = await dispatch('makeFetch', {
      url: 'users/current_user/requests',
      method: 'GET',
      errorMessage: 'Failed to get Teamup Requests!',
    }, { root: true } )
    await dispatch('tags/addTags', responseData.tags, { root: true })
    commit('setRequests', responseData);
    commit('setRequestsLoading', false);
  },
  async readTeamupRequests({dispatch, commit, state}) {
    let url = 'users/current_user/reset_unread_requests_count'
    if (state.requests.readTimestamp > 0) {
      url += `?timestamp=${state.requests.readTimestamp}`
    }
    const responseData = await dispatch('makeFetch', {
      url: url,
      method: 'PUT',
      errorMessage: 'Failed to read Teamup Requests!',
    }, { root: true } )
    if (responseData) {
      commit('updateNotifications', {unread_requests_count: 0});
    } else {
      dispatch('setError', responseData?.message, {root: true})
    }
  },
  async updatePassword({dispatch}, payload) {
    try {
      const responseData = await dispatch('makeFetch', {
        url: 'users/current_user/password',
        method: 'PUT',
        body: payload,
        errorMessage: 'Failed to update password!'
      }, { root: true } )
      if (!responseData.success) {
        throw responseData;
      }
      return responseData
    }
    catch (e) {
      dispatch('setNotice', {
        title: "Password",
        message: e.fields_errors ? e.fields_errors.map(item => item[1]).join(', ') : e.message,
        type: 'alert'
      }, {root: true})
    }

  },
  teamupDDToggle({commit}, payload) {
    commit('teamupDDToggle', payload)
  },
}