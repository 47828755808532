export default {

  // GET     /api/v3/tags/:tag_uid/subscription
  // # payment
  // # final callbacks to make the action happen
  // PUT 	  /api/v3/subscription/submit_card
  // PUT 	  /api/v3/subscription/update_card
  // PUT 	  /api/v3/subscription/change_plan
  // PUT    /api/v3/subscription/check_email
  // # run the checks and get the tokens back
  // PUT 	  /api/v3/subscription/can_subscribe
  // PUT    /api/v3/subscription/can_change_tier
  // GET    /api/v3/subscription/can_update_card



  async getSubscription({commit, dispatch}, {uid}) {
    commit('setLoading', true)
    const responseObj = await dispatch('makeFetch', {
      url: `tags/${uid}/subscription`,
      method: 'GET',
      version: 3,
      errorMessage: 'Failed to getSubscription!',
    }, { root: true } )

    commit('setDetails', responseObj)
    commit('setLoading', false)
  },
  async canSubscribe({commit, dispatch, getters}, {uid}) {
    commit('setLoading', getters['subscriptionAttempt'].tier)
    const responseObj = await dispatch('makeFetch', {
      url: `subscription/can_subscribe`,
      method: 'PUT',
      version: 3,
      body: {tag_uid: uid, period: 'month', tier: getters['subscriptionAttempt'].tier},
      errorMessage: 'Failed to canSubscribe!',
    }, { root: true } )

    if (responseObj && responseObj.success) {
      commit('setSubscriptionAttempt', {
        price: responseObj.price,
        reservation_token: responseObj.reservation_token,
        stripe_client_key: responseObj.stripe_client_key,
        renewal_date: responseObj.renewal_date,
      })
    } else {
      dispatch('setNotice', {
        title: "Subscription",
        message: responseObj.message ,
        type: 'alert'
      }, {root: true})
    }
    commit('setLoading', false)
  },

  async canChangeTier({commit, dispatch, getters}, {uid}) {
    commit('setLoading', getters['subscriptionAttempt'].tier)
    const responseObj = await dispatch('makeFetch', {
      url: `subscription/can_change_tier`,
      method: 'PUT',
      version: 3,
      body: {tag_uid: uid, period: 'month', tier: getters['subscriptionAttempt'].tier},
      errorMessage: 'Failed to canChangeTier!',
    }, { root: true } )

    if (responseObj && responseObj.success) {
      commit('setSubscriptionAttempt', {
        change_token: responseObj.change_token,
        stripe_client_key: responseObj.stripe_client_key,
        action: responseObj.action,
        amount: responseObj.amount,
        message: responseObj.message === 'ok' ? null : responseObj.message,
        renewal_date: responseObj.renewal_date,
      })
    } else {
      dispatch('setNotice', {
        title: "Subscription",
        message: responseObj.message ,
        type: 'alert'
      }, {root: true})
    }

    commit('setLoading', false)
  },

  async canUpdateCard({commit, dispatch}, {uid}) {
    commit('setLoading', true)
    const responseObj = await dispatch('makeFetch', {
      url: `tags/${uid}/can_update_card`,
      method: 'GET',
      version: 3,
      errorMessage: 'Failed to canUpdateCard!',
    }, { root: true } )

    console.log('responseObj', responseObj)

    commit('setLoading', false)
  },

  async submitCard({dispatch, getters}, payload) {
    const responseObj = await dispatch('makeFetch', {
      url: 'subscription/submit_card',
      method: 'PUT',
      version: 3,
      body: payload,
      errorMessage: 'Failed to submitCard!',
    }, { root: true } )

    dispatch('setNotice', {
      title: "Subscription",
      message: responseObj.message,
      type: responseObj && responseObj.success ? 'success' : 'alert',
    }, {root: true})

    dispatch('tags/updateTag', {uid: payload.uid, attrs: [
        { key: 'current_tier', value:   getters['subscriptionAttempt'].tier }
      ]}, { root: true })
    return responseObj
  },

  async changeTier({commit, dispatch, getters}, {uid, email}) {
    commit('setLoading', true)
    const {tier, change_token, stripe_client_key} = getters['subscriptionAttempt']
    const responseObj = await dispatch('makeFetch', {
      url: `subscription/change_plan`,
      method: 'PUT',
      version: 3,
      body: {tag_uid: uid, period: 'month', tier, email, confirmation_token: change_token, stripe_client_key},
      errorMessage: 'Failed to changeTier!',
    }, { root: true } )

    if (responseObj && responseObj.success) {
      const message = tier === 0 ? 'Unsubscribed' : `Subscription Changed to Tier ${tier}`
      dispatch('setNotice', {
        title: "Subscription",
        message: message,
        type: 'success'
      }, {root: true})
      dispatch('tags/updateTag', {uid: uid, attrs: [
          { key: 'current_tier', value: tier }
        ]}, { root: true })
    } else {
      dispatch('setNotice', {
        title: "Subscription",
        message: responseObj.message ,
        type: 'alert'
      }, {root: true})
    }
    commit('setLoading', false)
    return responseObj
  },
}