export default {
  async getData({dispatch, commit}, {uid}) {
    commit('setLoading', true)

    const responseData = await dispatch('makeFetch', {
      url: `tags/${uid}/cover_data`,
      method: 'GET',
      errorMessage: 'Failed to Get Cover Data!'
    }, { root: true } )

    if(responseData.teammate_uids?.length) {
      await dispatch('tags/fetchTagsByUid', responseData.teammate_uids, { root: true });
    }

    commit('setData', responseData)
    commit('setLoading', false)
  },
  async getBanners({dispatch, commit}, {uid}) {
    commit('setLoading', true)

    const responseData = await dispatch('makeFetch', {
      url: `tags/${uid}/banners`,
      method: 'GET',
      errorMessage: 'Failed to Get Banners!'
    }, { root: true } )

    commit('setBanners', responseData);
    commit('setLoading', false)
  },

  async getMapData({dispatch}, payload = {}) {
    const responseData = await dispatch('makeFetch', {
      full_url: 'internal/world_map_data',
      method: 'GET',
      errorMessage: 'Failed to getMapDat!'
    }, { root: true } )
    if(responseData.map?.length) {
      let uids = responseData.map.reduce(( memo, item )=> { memo.push(item.uid); return memo}, [])
      if(payload.getLeaders) {
        uids = [...uids, ...responseData.called_out_leaders.slice(0,8), ...responseData.signed_up_leaders.slice(0,8)]
      }

      await dispatch('tags/fetchTagsByUid', uids, { root: true });
    }
    return responseData
  },

  clearData({commit}) {
    commit('clearData')
  },

}