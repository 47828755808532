<template>
  <div v-show="replies.stats.total_count > 0 || replyCommentUid" class="replies ms-2 pt-2">
    <span v-if="!parentCommentIsPriority || (!isCollapsed && parentCommentIsPriority)" @click="toggleIsCollapsed" class="align-middle pointer position-relative">
      <i class="fs-4 theme-color align-middle position-absolute collapse-replies" :class="isCollapsed ? 'icon-plus-outline' : 'icon-close'"></i>
    </span>
    <replies-collapsed-stats
        v-if="replies.stats.total_count > 0 && isCollapsed"
        :stats="replies.stats"
        :is-initial-state="isPriorityInitialState"
        :parent-comment-is-priority="parentCommentIsPriority"
        @toggle-is-collapsed="toggleIsCollapsed"
    />
    <div v-if="!isCollapsed">
      <div class="border-start theme-border-color ps-2">
        <replies-priority-stats
            v-if="anyPriorityReplies"
            :stats="replies.stats"
            :next-stats="replies.next_stats"
            :is-collapsed="isCollapsed"
            :is-initial-state="isPriorityInitialState"
            :visible-count="priorityReplies.length"
            :total-count="replies.priority.total_count"
            :parent-comment-uid="commentUid"
            :set-initial-state="setInitialPriorityState"
            :toggle-replies="togglePriorityComments"
        />
        <transition-group name="opacity">
          <the-reply v-for="(reply, key) in priorityReplies" :key="key" :comment="reply"
                     :main-comment-uid="commentUid"
                     :parent-comment-uid="parentCommentUid"
          />
        </transition-group>
      </div>
      <transition name="opacity" mode="out-in">
        <typing-users-comment v-if="typingUsers && typingUsers.length > 0" :typing-users="typingUsers" class="py-2" />
      </transition>
      <comment-input
          :parent-comment-uid="commentUid"
          :reply-to-comment-uid="replyCommentUid || parentCommentUid ||  commentUid"
          ref="replyInput"
          class="border-start theme-border-color"
      />
      <div class="regular-replies border-start theme-border-color ps-2">
        <transition-group name="opacity">
          <the-reply v-for="(reply, key) in normalReplies" :key="key" :comment="reply"
                     :main-comment-uid="commentUid"
                     :parent-comment-uid="commentUid"
          />
        </transition-group>
        <replies-normal-stats
            v-if="anyNormalReplies"
            :stats="replies.stats"
            :next-stats="replies.next_stats"
            :toggle-replies="toggleNormalComments"
            :is-initial-state="isNormalInitialState"
            :set-initial-state="setInitialNormalState"
            :visible-count="normalReplies.length"
            :total-count="replies.normal.total_count"
            :parent-comment-uid="commentUid"
            class="comment pe-2"
        />
      </div>

    </div>
  </div>
</template>

<script>
import CommentInput from "@/components/feed/comments/CommentInput";
import TheReply from "@/components/feed/comments/replies/TheReply";
import TypingUsersComment from "../TypingUsersComment";
import RepliesPriorityStats from "@/components/feed/comments/parts/RepliesPriorityStats";
import RepliesNormalStats from "@/components/feed/comments/parts/RepliesNormalStats";
import RepliesCollapsedStats from "@/components/feed/comments/parts/RepliesCollapsedStats";

export default {
  name: "TheReplies",
  components: {
    RepliesCollapsedStats,
    RepliesNormalStats, RepliesPriorityStats, TypingUsersComment, TheReply, CommentInput},
  props: {
    replies: Object,
    commentUid: Number,
    parentCommentUid: Number,
    parentCommentIsPriority: Boolean,
    postUid: Number,
    commentsPageCount: {
      default: false,
    },
    isPostZoom: Boolean,
    typingUsers: {
      default: [],
    }
  },
  data() {
    return {
      parentCommentType: this.parentCommentIsPriority ? 'priority' : 'normal',
      replyCommentUid: null,
      isCollapsed: Number(this.$route.query.parent_comment_uid) !== this.commentUid,
      isPriorityInitialState: true,
      isNormalInitialState: true,
      isAllPriorityLoaded: this.replies.priority.next_index < 0,
      isAllNormalLoaded: this.replies.normal.next_index < 0,
    }
  },
  watch: {
    'replies.priority.next_index'(next_index) {
      this.isAllPriorityLoaded = next_index < 0
    },
    'replies.normal.next_index'(next_index) {
      this.isAllNormalLoaded = next_index < 0
    },
  },
  computed: {
    priorityReplies() {
      if (this.isPriorityInitialState) {
        let firstIndex = this.replies.priority.list.length - this.commentsPriorityLimit
        firstIndex = firstIndex < 0 ? 0 : firstIndex
        return this.replies.priority.list.slice(firstIndex,  this.replies.priority.list.length)
      }
      return this.replies.priority.list
    },
    normalReplies() {
      if (this.isNormalInitialState) {
        let firstIndex = this.replies.normal.list.length - this.commentsNormalLimit
        firstIndex = firstIndex < 0 ? 0 : firstIndex
        return this.replies.normal.list.slice(firstIndex, this.replies.normal.list.length)
      }
      return this.replies.normal.list
    },
    anyPriorityReplies() {
      return this.replies.priority.total_count > 0
    },
    anyNormalReplies() {
      return this.replies.normal.total_count > 0
    },
    plusIconClass() {
      let class_str = this.isCollapsed ? 'icon-plus-outline' : 'icon-close';
      class_str += this.parentCommentIsPriority ? '' : 'position-absolute'
      return class_str
    },
  },
  methods: {
    setInitialPriorityState() {
      this.isPriorityInitialState = true
      this.scrollToEl()
    },
    setInitialNormalState() {
      this.isNormalInitialState = true
      this.scrollToEl()
    },
    scrollToEl() {
      this.$nextTick(() => {
        this.$el.scrollIntoView({ behavior: "smooth" })
      })
    },
    toggleIsCollapsed() {
      this.isCollapsed = !this.isCollapsed
    },
    replyTo(commentUid, writerUid, writerDisplayName) {
      this.isCollapsed = false
      this.$nextTick(() => {
        this.replyCommentUid = commentUid
        this.$refs.replyInput.addMention({uid: writerUid, displayName: writerDisplayName})
      })
    },
    togglePriorityComments() {
      if (this.isAllPriorityLoaded && this.isPriorityInitialState) {
        this.isPriorityInitialState = false
        return
      }

      if (this.replies.priority.next_index > 0) {
        this.isAllPriorityLoaded = false
        this.isPriorityInitialState = false
        this.morePriorityComments()
      }
    },
    toggleNormalComments() {
      if (this.isAllNormalLoaded && this.isNormalInitialState) {
        this.isNormalInitialState = false
        return
      }

      if (this.replies.normal.next_index > 0) {
        this.isAllNormalLoaded = false
        this.isNormalInitialState = false
        this.moreNormalComments()
      }
    },
    morePriorityComments() {
      this.$store.dispatch('posts/moreReplies',
          {postUid: this.postUid, nextIndex: this.replies.priority.next_index, reply_type: 'priority',
            parent_comment_type: this.parentCommentType,
            previous_comment_uid: this.priorityReplies[0].uid, parent_comment_uid: this.commentUid,
            count: this.commentsPageCount || this.$route.query.count || this.commentsPriorityLimit,
            is_post_zoom: this.isPostZoom,
          }
      )
    },
    moreNormalComments() {
      this.$store.dispatch('posts/moreReplies',
          {postUid: this.postUid, nextIndex: this.replies.normal.next_index, reply_type: 'normal',
            parent_comment_type: this.parentCommentType,
            previous_comment_uid: this.normalReplies[0].uid, parent_comment_uid: this.commentUid,
            count: this.commentsPageCount || this.$route.query.count || this.commentsNormalLimit,
            is_post_zoom: this.isPostZoom,
          }
      )
    },
  },
}
</script>

