export default {
  // userId(state) {
  //   return state.userId;
  // },
  token(state) {
    return state.token;
  },
  isAuth(state) {
    return !!state.token
  },
  // didAutoLogout(state) {
  //   return state.didAutoLogout;
  // }

  isLoggedIn(state) {
    return state.isLoggedIn;
  },
  currentUser(state) {
    return state.currentUser;
  },
  currentUserUid(state) {
    return state.currentUser?.uid;
  },
  currentUserOrgs(state, _getters, _, rootGetters) {
    return state.currentUser.org_uids?.reduce((memo, org_uid) => {
      memo.push(rootGetters['tags/findTag'](org_uid))
      return memo
    },[])
  },
  mainCurrentUserOrg(state, _getters, _, rootGetters) {
    if (!state.isLoggedIn || state.currentUser.org_uid === -1) return null;

    return rootGetters['tags/findTag'](state.currentUser.org_uid);
  },
  isUserLoading(state) {
    return state.isLoading;
  },
  isSubscriber(state) {
    // return state.isLoggedIn && state.currentUser.is_subscriber #TODO ALEX NOT WORKING FOR SOME REASON
    return state.isLoggedIn && state.currentUser.org_uid !== -1
  },
  isTwMerged(state) {
    return state.isLoggedIn && state.currentUser.socials_available.indexOf('tw') >= 0
  },
  isFbMerged(state) {
    return state.isLoggedIn && state.currentUser.socials_available.indexOf('fb') >= 0
  },
  isLiMerged(state) {
    return state.isLoggedIn && state.currentUser.socials_available.indexOf('li') >= 0
  },
  isAdmin(state) {
    return state.isLoggedIn && state.currentUser.is_admin
  },
  orgUid(state) {
    return state.isLoggedIn && state.currentUser.org_uid
  },
  teamupRequestsCount(state) {
    return state.currentUser.unread_requests_count
  },
  teamupDDIsOpen(state) {
    return state.dd.teamupIsOpen
  },
  teamupRequestsHistory(state) {
    return state.requests.history
  },
  teamupRequestsIncoming(state) {
    return state.requests.incoming
  },
  teamupRequestsSuggestion(state) {
    return state.requests.suggestion
  },
  noticesCount(state) {
    return state.currentUser?.notices_count
  },
  isRequestsLoading(state) {
    return state.requests.isLoading
  },
  notices(state) {
    return state.notices.events
  },
  toasts(state) {
    return state.toasts
  },
  isNoticesLoading(state) {
    return state.notices.isLoading
  },
  followsList(state) {
    return state.follows.list
  },
  isFollowsLoading(state) {
    return state.follows.isLoading
  },
  blockedList(state) {
    return state.blocked.list
  },
  isBlockedLoading(state) {
    return state.blocked.isLoading
  },
}