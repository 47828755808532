export default {
  isLoading(state) {
    return state.isLoading;
  },
  details(state) {
    return state.details
  },
  subscriptionAttempt(state) {
    return state.subscriptionAttempt
  },
  canSubscribe(state) {
    return !state.details.subscriber && state.details.subscribe_allowed && state.details.current_tier === 0
  },
  canChangeTier(state) {
    return state.details.subscriber && state.details.subscribe_allowed && state.details.current_tier > 0
  },
}