import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      list: [],
      next_index: null,
      total_count: null,
      leaders_count: null,
    }
  },
  getters,
  actions,
  mutations,
}