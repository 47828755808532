export default {
  findPosts: (state) => (payload) => {
    const posts = []
    const uid = Number(payload.uid)
    const post = state.posts.find(post => Number(post.uid) === uid );
    if (post) {
      posts.push(post)
    }
    if (state.currentPost && Number(state.currentPost.uid) === uid) {
      posts.push(state.currentPost)
    }
    return posts
  },
  posts(state) {
    return state.posts;
  },
  post(state) {
    return state.currentPost;
  },
  isPostsLoading(state) {
    return state.isLoading;
  },
  isPostLoading(state) {
    return state.isPostLoading;
  },
  isPostBoxLoading(state) {
    return state.isPostBoxLoading;
  },
  textBoxError(state) {
    return state.textBoxError
  },
  // writer: (state, _getters, _rootState, rootGetters) => (uid) => {
  //   rootGetters['tags/findTag'](uid);
  // }
}