<template>
  <div class="mention-list main-bg">
    <base-spinner v-if="isLoading" class="py-1"></base-spinner>
    <ul v-else class="fs-70">
      <li
          class="item d-flex pointer"
          :class="{ 'is-selected': index === selectedIndex }"
          v-for="(item, index) in items"
          :key="index"
          @click="selectItem(index, $event)"
      >
        <img :src="tagIconSrc(item.icon)" class="rounded-circle me-2">
        <div>
          <div class="fw-bold fs-80">{{ item.display_name }} <i v-if="item.verified" class="icon-check-square theme-color mx-1 fs-80"/></div>
          <span class="link-main">{{tagType(item.type)}}</span>
          <div>
            <span v-if='item.user_is_employee' class="link-main">{{ item.user_employee_title }}</span>
            <span v-if="item.org_uid > 0" class="link-main" >{{item.org_tag}}</span>
          </div>
            <x-teammates-dd-link link-class="link-main no-select" :uid="item.uid" :is-org="item.isOrg" :mutual-teammates-count="item.mutual_teammates_count" :teammates-count="item.teammates_count" />
        </div>
      </li>
    </ul>
    <div v-if="!isLoading && items.length === 0" class="link-main p-2 text-center">No Items</div>
  </div>
</template>

<script>
import XTeammatesDdLink from "../UI/XTeammatesDdLink";

export default {
  components: {XTeammatesDdLink},
  props: {
    command: {
      type: Function,
      required: true,
    },
    editor: Object,
  },
  computed: {
    items() {
      return this.$store.getters['invites/mentionList']
    },
    isLoading() {
      return this.$store.getters['invites/isMentionLoading']
    },
  },
  data() {
    return {
      selectedIndex: 0,
      pageTypes: {
        group: 'Group',
        university: 'University',
        url_company: "Company",
        url_country: "Government",
        url_nonprofit: 'Non Profit',
        custom: "News Feed",
        cause: "",
        page: ""
      },
    }
  },
  watch: {
    items() {
      this.selectedIndex = 0
    },
  },
  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }
      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }
      if (event.key === 'Enter' || event.key === 'Tab') {
        this.enterHandler()
        return true
      }
      return false
    },
    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },
    enterHandler() {
      this.selectItem(this.selectedIndex)
    },
    selectItem(index, e) {
      if( e?.target.classList.contains('no-select') ) return

      const item = this.items[index]
      if (item) {
        this.command({ uid: item.uid, label:  item.display_name })
        // this.editor.commands.insertContent([{ type: 'text', text: ' '}])
      }
    },
    tagIconSrc(src) {
      return (/(syscolor)/.test( src ) ? src.replace(/(syscolor)/, this.$store.state.config.theme) : src) || this.imgPath + '/default-user.png'
    },
    tagType(type) {
      return this.pageTypes[type]
    }
  },
}
</script>