import Link from '@tiptap/extension-link'
import {mergeAttributes} from "@tiptap/core";


export default Link.extend({

  renderHTML({ HTMLAttributes }) {
    this.options.onLinkPaste(HTMLAttributes.href)
    return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
});
