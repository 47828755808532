<template>
  <i class="icon-camera d-block d-md-none align-self-center fs-1 ms-3" @click="getPhoto"/>
</template>

<script>
import { Camera, CameraResultType, CameraDirection } from '@capacitor/camera';

export default {
  name: "CameraButton",
  data() {
    return {
      nextId: Date.now()
    }
  },
  methods: {
    async getPhoto() {
      if(!this.$store.getters['auth/isLoggedIn']) {
        return await this.$router.push({name: 'sign-in', params: {slug: this.$store.getters['tags/currentTagSlugNoHome']}})
      }
      this.nextId += 1
      const image = await Camera.getPhoto({
        quality: 100,
        direction: CameraDirection.Rear,
        allowEditing: false,
        resultType: CameraResultType.DataUrl
      })
      const fileType = `image/${image.format}`
      await this.$store.commit('photoPost/addImage', {name: `photo-${this.nextId}`, id: this.nextId, loading: true, type: fileType})
      await this.uploadOnS3(image.dataUrl, this.nextId, fileType)
    },
    async uploadOnS3(imageFile, id, imageType) {
      await this.$store.dispatch('uploadToS3', imageFile, imageType).then( ({src}) => {
        this.$store.commit('photoPost/setImageLoaded', {id, src})
      }).catch((error) => {
        this.$store.dispatch('setError', error.message || "FileUploader:uploadOnS3 - Error!");
      })
    }
  }
}
</script>
