import numeral from 'numeral';

export function toNumeral(numb) {
  let num = Number(numb);
  if (num < 1000) {
    num = numeral(num).format('0');
  } else if (num >= 1000 && num < 1000000) {
    num = Math.round(num / 100) * 100
    if (parseInt(num/1000) * 1000 === num) {
      num = numeral(num).format('0a');
    } else {
      num = numeral(num).format('0.0a');
    }
  } else if (num >= 1000000) {
    num = num = Math.round(num / 100000) * 100000
    if (parseInt(num / 1000000) * 1000000 === num) {
      num = numeral(num).format('0a');
    } else {
      num = numeral(num).format('0.0a')
    }
  }
  return num;
}


export function enUsOrg() {
  return (/us/gi).test((navigator.language || (navigator.userLanguage &&
    navigator.userLanguage.replace(/-[a-z]{2}$/, String.prototype.toUpperCase))
    || 'en-US')) ? 'Organizations' : "Organisations"
}

export function isOrg(type) {
  return ['page', 'group', 'university', 'url_company', 'url_country', 'city', 'state', 'cause', 'url_nonprofit', 'country'].indexOf(type) >= 0
}

export function isConflict(type) {
  return type === 'cause'
}

export function isHomeOrCustom(type) {
  return ['home', 'custom'].indexOf(type) >= 0
}

export function typesObject(tag) {
  return {
    isUser: tag.type === 'user_profile',
    isOrg: isOrg(tag.type),
    isLeader: tag.type === 'user_profile' && tag.precreated && !tag.claimed,
    isClaimedLeader: tag.type === 'user_profile' && tag.precreated && tag.claimed,
    isHome: tag.type === 'home',
    isCustom: tag.type === 'custom',
  }
}

export function teamWord(type, teamWord, globalTeamWord) {
  return isOrg(type) ? (teamWord || globalTeamWord ) : ''
}

// height
export function isInViewportY (elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

export function isPartInViewportY (elem) {
  const bounding = elem.getBoundingClientRect();
  const viewportHeight = (window.innerHeight || document.documentElement.clientHeight)
  return (
    ( bounding.top > 0 && bounding.top < viewportHeight && bounding.bottom > viewportHeight ) ||
    ( bounding.top < 0 && bounding.bottom < viewportHeight && bounding.bottom > 0 ) ||
    ( bounding.top < 0 && bounding.bottom > viewportHeight && bounding.bottom > 0 && bounding.bottom - viewportHeight > 0) ||
    ( bounding.top >= 0 && bounding.bottom <= viewportHeight )
  )
}

// width
export function isInViewportX (elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.left >= 0 &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// export function videoJsDetails (url) {
//   if(allRegexp.youtube.test(url)) {
//     return { techOrder: 'youtube', type: "video/youtube", src: url.replace(/&list=.*|&index=.*/gi,'') }
//   }
//   if(allRegexp.vimeo.test(url)) {
//     return { techOrder: 'vimeo', type: "video/vimeo", src: url.replace(/&list=.*|&index=.*/gi,'') }
//   }
// }

export function copyToClipboard (text) {
  return new Promise((resolve, reject) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(text);
      return resolve('Copied to clipboard!')
    }

    const body = document.body;

    if (!body) {
      return reject('Copy to clipboard failed!');
    }

    let node = document.createElement('pre');
    node.style.width = '1px';
    node.style.height = '1px';
    node.style.position = 'fixed';
    node.style.top = '5px';
    node.textContent = text;

    body.appendChild(node);

    const selection = getSelection();
    if (selection == null) {
      return reject('Copy to clipboard failed!');
    }
    selection.removeAllRanges();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    body.removeChild(node);
    return resolve('Copied to clipboard!')
  })
}



export function getOrientation (file) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = function(e) {
      let i, length, little, marker, offset, tags, view;
      view = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xFFD8) {
        return resolve({orientation: -2, file: file})
      }
      length = view.byteLength;
      offset = 2;
      while (offset < length) {
        marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xFFE1) {
          little = view.getUint16(offset += 8, false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          tags = view.getUint16(offset, little);
          offset += 2;
          i = 0;
          while (i < tags) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              resolve({orientation: view.getUint16(offset + i * 12 + 8, little), file: file});
            }
            i++;
          }
        } else if ((marker & 0xFF00) !== 0xFF00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      resolve({orientation: -1, file: file});
    };
    reader.onerror = (e) => {
      console.error(e);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
  })
}

export function rotateImage (src, orientation, file_type) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const pic = new Image();
    pic.onerror = () => {
      return reject('Error on load file')
    }
    pic.onload = () => {

      // if (pic.width > 2000 || pic.height > 2000) {
      //   const width = 200
      //   const height = pic.height * (200 / pic.width)
      //   pic.width = width
      //   pic.height = height
      // }
      canvas.width = pic.width;
      canvas.height = pic.height;
      // const width = canvas.width;
      // const height = canvas.height;
      // if (!orientation || orientation > 8) {
      //   return;
      // }
      // if (orientation > 4) {
      //   canvas.width = height;
      //   canvas.height = width;
      // }

      // switch (orientation) {
      //   case 2:
      //     ctx.transform(-1, 0, 0, 1, width, 0);
      //     break;
      //   case 3:
      //     ctx.transform(-1, 0, 0, -1, width, height);
      //     break;
      //   case 4:
      //     ctx.transform(1, 0, 0, -1, 0, height);
      //     break;
      //   case 5:
      //     ctx.transform(0, 1, 1, 0, 0, 0);
      //     break;
      //   case 6:
      //     ctx.transform(0, 1, -1, 0, height, 0);
      //     break;
      //   case 7:
      //     ctx.transform(0, -1, -1, 0, height, width);
      //     break;
      //   case 8:
      //     ctx.transform(0, -1, 1, 0, 0, width);
      //     break;
      //   default:
      //     ctx.transform(1, 0, 0, 1, 0, 0);
      // }
      // switch (orientation) {
      //   case 2:
      //     ctx.translate(width, 0);
      //     ctx.scale(-1, 1);
      //     break;
      //   case 3:
      //     ctx.translate(width, height);
      //     ctx.rotate(Math.PI);
      //     break;
      //   case 4:
      //     ctx.translate(0, height);
      //     ctx.scale(1, -1);
      //     break;
      //   case 5:
      //     ctx.rotate(0.5 * Math.PI);
      //     ctx.scale(1, -1);
      //     break;
      //   case 6:
      //     ctx.rotate(0.5 * Math.PI);
      //     ctx.translate(0, -height);
      //     break;
      //   case 7:
      //     ctx.rotate(0.5 * Math.PI);
      //     ctx.translate(width, -height);
      //     ctx.scale(-1, 1);
      //     break;
      //   case 8:
      //     ctx.rotate(-0.5 * Math.PI);
      //     ctx.translate(-width, 0);
      // }
      ctx.drawImage(pic, 0, 0);
      return resolve({
        imageFile: canvas.toDataURL(file_type),
        width: pic.width,
        height: pic.height
      })
    };
    pic.src = src;
  })
}

export function dataUriToBlob (data_uri, type= "image/jpeg") {
  const binary = atob(data_uri.split(",")[1]);
  const array = [];
  let i = 0;
  while (i < binary.length) {
    array.push(binary.charCodeAt(i));
    i++;
  }
  return new Blob([new Uint8Array(array)], { type: type })
}


export const allRegexp = {
  firstName: /[\d=\][\\/,;`<>~!@#$%^&*()_+}{|":?]/i,
  password: /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z@$!%*?&]{8,}$/,
  youtube: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/,
  youtubeId: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/,
  vimeo: /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
  vimeoId: /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i,
  url: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*[-A-Z0-9+\u0026@#/%=~()_|])/ig,
  img: /.*.(jpe?g|png|gif|bmp|jpg)/,
  twemoji_img: /https:\/\/twemoji\.maxcdn\.com/,
  dropbox: /www\.dropbox\.com/,
  email: /\S+@\S+\.\S+/,
  sysColors: /(grass_green|golden|sky|strawberry|tangerine|funky_purple|pink|night|syscolor)/,
  email_domain(emailDomain) {
    return new RegExp(`\\S+${emailDomain.replace('.', '\\.')}$`, 'g')
  }
}

export const colorSheme = {
  funky_purple: '#1D5BAD',
  grass_green: '#76b845',
  golden: '#f1b716',
  sky: '#dc7901',
  strawberry: '#e13a3c',
  tangerine: '#9d2278',
  pink: '#a50b69',
  night: '#2a3139'
}

export function buildSearchParams(queryObj) {
  const searchParams = new URLSearchParams()
  Object.keys(queryObj).forEach( key => {
    if (queryObj[key]) searchParams.set(key, queryObj[key])
  })
  if (searchParams.toString()) {
    return '?'+searchParams.toString()
  }
  return ''
}