<template>
  <transition name="opacity" mode="out-in">
    <div v-if="textBoxError" class="theme-bg text-inverse fs-80 text-center rounded-top pointer editor-error" @click="hideError">
      {{textBoxError}}
    </div>
  </transition>
  <div v-bind="$attrs"
       class='content ms-2 p-2 rounded w-100 border'
       :class="contentClass"
       @dragenter.prevent="dragEnter"
       @dragleave.prevent="dragLeave"
  >
    <div v-if="!editMode && isLoading" class="comment-spinner-container">
      <base-spinner/>
    </div>
    <comment-header :writer="writer" :source="comment.source" :source-url="comment.source_url"/>
    <tip-tap
        :placeholder="commentsPlaceholder"
        :content="comment.content"
        :limit="postCharsLimit"
        :editable="editMode"
        @onChangeText="onChangeText"
        @onLinkPaste="onLinkPaste"
        @onCharsCountUpdate="onCharsCountUpdate"
        ref="tiptap">
    </tip-tap>
    <template v-if="!editMode">
      <web-page-preview
          v-if="comment.url_preview?.url && comment.images.length === 0"
          v-bind="comment.url_preview"
          :resizable="true"
      />
      <comment-images :images="comment.images"/>
      <div v-if="!isLoading" class="fs-70 d-flex align-items-center mt-1">
        <reactions-tt
            :class="isDriveHighlight ? 'fw-bold link-color text-capitalize' : 'link-main text-capitalize'"
            :reaction-flag="comment.prefs?.reaction_flag || 'none'"
            :container-class="isReply ? 'reply-reactions-tt' : ''"
            @onReact="react"
        >
          <span v-if="comment.reactionLoading" class="spinner-border" role="status"></span>
          <span v-if="!comment.reactionLoading" class="user-select-none">{{reactionText}}</span>
        </reactions-tt>

        <i v-if="canReply" class="icon-dot fs-20 mx-1"></i>
        <a v-if="canReply" class="link-main" @click="setReplyToCommentUid">Reply</a>

        <template v-if="writer?.isLeader">
          <i class="icon-dot fs-20 mx-1"></i>
          <invite-button
            v-if="writer?.prefs.callout_flag"
            tag-name="a"
            class="link-main"
            :payload="{
              tag_uid: comment.writer_tag_uid,
              post_uid: postUid,
              promo_tag_uids: post.promo_tag_uids,
              existing_invite_uid: post.promo_tag_uids[0],
              about_tag_uid: postTagUid,
              real_comment_uid: comment.uid
            }"
            :display-name="writer.display_name">Call-in</invite-button>
        </template>
        <i class="icon-dot fs-20 mx-1"></i>

        <span class="text-grey" @click="logId"><base-time-ago :edited-at="comment.created_at" :created-at="comment.edited_at" :caption="' '"/></span>
        <comment-options v-if="signedIn"
                         :writer-uid="writer?.uid"
                         @toggle-edit-mode="toggleEditMode"
                         @delete-comment="deleteComment"
                         @flag-comment="flagComment"
                         @hide-comment="hideComment"
        />
      </div>
      <comment-reactions-box
          v-if="comment.stats?.drive_click_count"
          :uid="comment.uid"
          :reactions="comment.stats.reactions"
          :reactions-count="comment.stats.drive_click_count"
          :is-highlighted="isDriveHighlight"
      />
    </template>

    <div v-if="editMode && isLoading" class="mt-3 mb-2 d-flex justify-content-end">
      <span class="fw-bold link-color me-2"><base-spinner/></span>
    </div>
    <div v-if="editMode && !isLoading" class="mt-3">
      <div class="fs-80 d-flex align-items-center justify-content-end">
        <the-twemoji @onSelect="addEmoji" class="me-2 lh-1" icon-class="fs-4"/>
        <file-uploader
            class="me-2 lh-1"
            :post-uid="comment.uid"
            :id-offset="images.length"
            @imageLoaded="onImageLoaded"
            @imageAdd="onImageAdd"
            @dragEnter="dragEnter"
            @onDrop="onDrop"
            :is-drag-active="isDragActive"
            drag-zone-class="h-100"
        >
          <i class="icon-image pull-right link-main fs-4"></i>
        </file-uploader>
        <span @click="toggleEditMode" class="btn btn-o-main btn-sm mx-2 py-0 px-2 fw-bold">Cancel</span>
        <span @click="updateComment" class="btn btn-color btn-sm ms-2 py-0 px-2 fw-bold">Save</span>
      </div>
    </div>
    <div v-if="editMode" id="web-page-container">
      <base-spinner v-if="isWebPreviewLoading && web_url" class="w-100"></base-spinner>
      <web-page-preview v-else-if='!isWebPreviewLoading && web_url'
                        v-bind="url_preview"
                        :edit-mode="!isLoading"
                        @removeWebPreviewImage="removeWebPreviewImage"
                        @removeWebPreviewAll="removeWebPreview"
      ></web-page-preview>
    </div>
    <images-preview
      v-if="editMode"
      :images="images"
      :show-remove="!isLoading"
      @removeImage="onImageRemove"
      @onSort="onImagesSort"
    />
  </div>


  <the-promt v-if="promt === 'reset'"
             :ok-func="resetComment"
             :cancel-func="cancelPromt"
             header-class="border-0 theme-color pb-2"
             body-class="p-0 border-0"
             footer-class="justify-content-center border-0 pt-0"
             :close="cancelPromt"
  >
    <template #header><h5 class="text-center w-100">Are you sure you want to cancel?</h5></template>
    <template #okText>Yes</template>
    <template #cancelText>No</template>
  </the-promt>
</template>

<script>
import TipTap from "../../editors/TipTap";
import editor from "../../../hooks/editor";
import FileUploader from "../../editors/FileUploader";
import ThePromt from "../../modals/ThePromt";
import TheTwemoji from "../../editors/TheTwemoji";
import WebPagePreview from "../../editors/WebPagePreview";
import ImagesPreview from "../../editors/ImagesPreview";
import CommentHeader from "./parts/CommentHeader";
import CommentImages from "@/components/feed/comments/parts/CommentImages";
import ReactionsTt from "@/components/dd/ReactionsTt";
import InviteButton from "@/components/buttons/InviteButton";
import CommentOptions from "@/components/feed/comments/CommentOptions";
import CommentReactionsBox from "@/components/feed/comments/parts/CommentReactionsBox";

export default {
  emits: ['toggleEditMode', 'updateComment', 'deleteComment', 'flagComment', 'isLoading', 'react'],
  mixins: [editor],
  inject: ['post', 'postUid', 'postTagUid', 'isPostZoom'],
  props: {
    comment: Object,
    writer: Object,
    isLoading: Boolean,
    editMode: {
      type: Boolean,
      default: false,
    },
    isReply: {
      type: Boolean,
      default: false,
    },
    setReplyToCommentUid: Function,
    canReply: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CommentReactionsBox,
    CommentOptions,
    InviteButton,
    ReactionsTt,
    CommentImages,
    CommentHeader,
    ImagesPreview,
    WebPagePreview,
    TheTwemoji,
    ThePromt,
    FileUploader,
    TipTap
  },
  data() {
    return {
      textBoxError: null,
      images: this.comment.images.reduce((memo, src, index) => {
        memo.push({src: src, id: index})
        return memo
      }, []),
      web_url: this.comment.url_preview?.url,
      url_preview: this.comment.url_preview,
      message: this.comment.content,
    }
  },
  computed: {
    isEmpty() {
      return (this.isActive || this.editMode) && this.charsCount === 0 && this.images.length === 0 && !this.web_url
    },
    contentClass() {
      return {
        'text-highlight': (this.isPostZoom && this.$route.query.comment && Number(this.$route.query.comment) === this.comment.uid) || this.comment.is_highlighted,
        'border-light': this.writer?.isLeader,
        'position-relative': !this.editMode,
        'theme-border-color': this.editMode,
      }
    },
    isDriveHighlight() {
      return this.comment.prefs?.reaction_flag && this.comment.prefs?.reaction_flag !== 'none'
    },
    reactionText() {
      return this.isDriveHighlight && this.comment.prefs.reaction_flag !== 'drive' ? this.comment.prefs.reaction_flag : 'like'
    },
    signedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
  },
  watch: {
    'comment.error'(val) {
      this.textBoxError = val
    },
    editMode(val) {
      if (val) {
        this.$refs.tiptap.setCursorAtEnd()
      }
    },
  },
  methods: {
    deleteComment() {
      this.$emit('deleteComment')
    },
    flagComment() {
      this.$emit('flagComment')
    },
    hideComment() {
      this.$emit('hideComment')
    },
    react(reaction) {
      this.$emit('react', reaction)
    },
    toggleEditMode() {
      this.$emit('toggleEditMode')
    },
    updateComment() {
      this.$emit('updateComment', {
        message: JSON.stringify(this.message),
        images: this.images.map((img)=> { return img.src}),
        url_preview: this.url_preview,
      })
    },
    resetComment() {
      this.$emit('toggleEditMode')
    },
    addError(e) {
      this.textBoxError = e
    },
    hideError() {
      clearTimeout(this.errorTimeoutId)
      this.textBoxError = null
    },
    logId() {
      const parentLine = this.comment.parent_comment_uid ? ` Parent Comment UID: ${this.comment.parent_comment_uid}` : ''
      console.log(`UID: ${this.comment.uid}${parentLine}`)
    },
  },
}
</script>
