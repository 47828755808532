<template>
  <div class="invite-dd text-left body-bg text-grey fw-normal popup-full-size-sm">
    <div class="theme-color fs-75 px-2 py-1 fw-bold border-bottom">
      <span class="text-grey">
        <base-numeral :num="tag.stats.call_out_to_count"/> <base-pluralize :num="tag.stats.call_out_to_count" word="mention"/>
        counted calling-in
      </span>
      <base-tag-dd-link :uid="tag.uid" class="link-color" target="_blank">
        "{{tag.display_name}}"
      </base-tag-dd-link>
      <invite-leader-title v-if="tag.isLeader"
                           :uid="tag.uid"
                           :org-uids="tag.org_uids"
                           @update-position="updatePosition"
      />
      <span v-if="tag.isOrg" class="text-grey">
        leaders to the table for agreements.
      </span>
    </div>
    <div>
      <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
        <tags-list :list="list" :invite-tag-uid="uid"/>
        <div v-if="list.length === 0 && !isLoading && !moreIsLoading" class="text-grey text-center py-2">
          No Items
        </div>
      </div>
      <base-spinner v-if="isLoading || moreIsLoading" class="p-3"/>
    </div>
  </div>
</template>

<script>
  import TagsList from "@/components/dd/parts/TagsList";
  import ddFetchData from "@/hooks/ddFetchData";
  import InviteLeaderTitle from "./parts/InviteLeaderTitle";

  export default {
    name: 'InviteDd',
    components: {InviteLeaderTitle, TagsList},
    mixins: [ddFetchData],
    emits: ['update-position'],
    props: {
      uid: {
        type: Number,
        required: true,
      },
    },
    created() {
      this.fetchData()
    },
    data() {
      return {
        errorMessage: 'Failed to Get Invites From!',
        version: 3
      }
    },
    computed: {
      url() {
        const currentTagUid = this.$store.getters['tags/currentTag'].uid
        return `tags/${this.uid}/who_called_out?count=30&from_tag_uid=${currentTagUid}`
      },
      tag() {
        return this.$store.getters['tags/findTag'](this.uid || 1)
      },
    },
    watch: {
      list() {
        this.$nextTick(this.updatePosition)
      }
    },
    methods: {
      updatePosition() {
        this.$emit('update-position')
      },
    },
  }
</script>