<template>
  <span v-if="isTeammates || isMutual">
    <base-dd :uid="uid" dd="teammates" :link-class="linkClass" class="d-inline-block" :class="ddClass">
      <base-numeral :num="totalCount"/> {{isMutual ? 'mutual' : ''}} <base-pluralize :word="teammateWord" :num="totalCount"/>
    </base-dd>
  </span>
</template>

<script>
  export default {
    props: ['uid', 'isOrg', 'mutualTeammatesCount', 'teammatesCount', 'linkClass', 'ddClass'],
    computed: {
      isMutual() {
        const isMe = this.$store.state.auth.currentUser.uid === this.uid;
        const isLoggedIn = this.$store.state.auth.isLoggedIn;
        return this.mutualTeammatesCount && isLoggedIn && !this.isOrg && !isMe
      },
      isTeammates() {
        return !this.isMutual && this.teammatesCount > 0
      },
      teammateWord() {
        return this.isOrg ? 'leader' : 'teammate'
      },
      totalCount() {
        return this.isMutual ? this.mutualTeammatesCount : this.teammatesCount
      },
    }
  }
</script>