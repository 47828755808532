<template>
  <div class="toast-container position-fixed p-2">
    <transition-group name="route">
      <notice-item v-for="notice in notices" :key="notice.id" :id="notice.id" :title="notice.title" :message="notice.message" :type="notice.type"></notice-item>
    </transition-group>
    <transition-group name="route">
      <toast-item v-for="toast in toasts" :key="toast.event_uid" :notice="toast"/>
    </transition-group>
  </div>
</template>

<script>
  import NoticeItem from './NoticeItem'
  import ToastItem from "../toast/ToastItem";

  export default {
    components: {ToastItem, NoticeItem },
    computed: {
      notices() {
        return this.$store.getters['notices']
      },
      toasts() {
        return this.$store.getters['auth/toasts'].slice(0, 3)
      },
    },
  }
</script>



<!-- EXAMPLE -->
<!--this.$store.dispatch('setNotice', {-->
<!--   title: 'Welcome Back',-->
<!--   message: 'You logged in as Alex',-->
<!--})-->