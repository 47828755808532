<template>
  <button @click="openPostZoom">View</button>
</template>

<script>
import {buildSearchParams} from "../../functions/global";

export default {
  name: "ViewInvitePostButton",
  props: {
    uid: Number,
    inviteTagUid: Number,
  },
  methods: {
    async openPostZoom() {
      const responseData = await this.$store.dispatch('invites/getPostData', {
        uid: this.uid,
        inviteTagUid: this.inviteTagUid
      })
      if (responseData && responseData.success) {
        const searchParamsString = buildSearchParams({
          about_tag_uid: this.inviteTagUid,
          comment: responseData.comment_uid,
          mode: this.$route.query.mode,
          type: this.$route.query.type,
        })
        const postPathSlash = this.$route.path.endsWith('/') ? '' : '/'
        return await this.$router.push(this.$route.path + `${postPathSlash}post/${responseData.post_uid}${searchParamsString}`)
      } else {
        this.$store.dispatch('setNotice', {
          title: 'Oops!',
          message: responseData.message || 'Failed to find post',
        })
      }
    },
  },

}
</script>
