export default {
  setController(state, payload) {
    state.controller = payload
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setFoundedData(state, payload) {
    if (payload.query !== state.query) {
      state.foundedTags = payload.metas;
      if ( state.foundedTags[0] ) {
        state.foundedTags[0].selected = true;
      }
    } else {
      state.foundedTags = state.foundedTags.concat(payload.metas);
    }
    state.founded_next_index = payload.next_index;
    state.query = payload.query;
    // state.founded_total_count = payload.total_count;

  },
  setHistory(state, payload) {
    state.historyTags = payload
  },
  addHistoryTag(state, payload) {
    state.historyTags = state.historyTags.filter(tag => tag.uid !== payload.uid);
    state.historyTags.unshift(payload)
  },
  updateHistoryLSTag(state) {
    localStorage.setItem('tagbar/history', JSON.stringify(state.historyTags))
  },
  setHistoryLoading(state, payload) {
    state.isHistoryLoading = payload
  },
  clearFoundData(state) {
    state.foundedTags = [];
    state.founded_next_index = 0;
    // state.total_count = null;
  }
}