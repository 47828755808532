//IN
export const POST_REACT = 'post:react';
export const COMMENT_NEW = 'comment:new';
export const COMMENT_UPDATE = 'comment:update';
export const COMMENT_REACT = 'comment:react';
export const TYPING = 'typing';
export const NOTICE_NEW = 'notice:new';
export const POST_NEW = 'post:new';
//OUT
export const TYPING_START = 'typing:start';
export const TYPING_END = 'typing:end';
export const COMMENT_DELETE = 'comment:delete';