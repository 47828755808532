export default {

  isTagbarLoading(state) {
    return state.isLoading
  },
  foundedTags(state) {
    return state.foundedTags
  },
  historyTags(state) {
    return state.historyTags
  },
  isHistoryLoading(state) {
    return state.isHistoryLoading
  },

  // teammates(state, _getters, _rootState, rootGetters) {
  //   return rootGetters['tags/getTags'](state.list)
  // },
  // isInnerTag: (state, _getters, rootState) => (uid) => {
  //
  //   // config.inner_tag_uids.indexOf(uid) > -1 and config.sys_uids.breaking_news isnt uid
  // }
}