<template>
  <img class="rounded-circle" :src="tag?.icon">
</template>

<script>
import fetchTagIfNotFound from "@/hooks/fetchTagIfNotFound";

export default {
  name: "CommentStatsImage",
  mixins: [fetchTagIfNotFound]
}
</script>