export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setData(state, payload) {
    state.showEnhancedCover = payload.show_enhanced_cover
    state.teammateIcons = payload.teammate_icons
    state.teammateUids = payload.teammate_uids
    state.videos = payload.videos
  },
  setBanners(state, {list}) {
    state.banners = list
  },
  clearData(state) {
    state.banners = null
    state.showEnhancedCover = null
    state.teammateIcons = null
    state.teammateUids = null
    state.videos = null
  }
}