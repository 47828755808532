import { createPopper, preventOverflow, flip } from '@popperjs/core';

export default {
  data() {
    return {
      ddEl: null,
      isVisible: false,
      canShow: true,
      hideTimeout: null,
      showTimeout: null,
      offset: [0, 10],
      delayToShow: 1000,
      delayToHide: 600,
    }
  },
  methods: {
    showDD() {
      clearTimeout(this.hideTimeout)
      clearTimeout(this.showTimeout)
      if (this.ddEl) return;
      if (!this.canShow) return;

      this.showTimeout = setTimeout(() => {
        const headerH = document.getElementById('header').offsetHeight
        const headerRH = document.getElementById('header-right').offsetHeight
        const paddingTop = headerH > headerRH ? headerH : headerH + headerRH

        this.isVisible = true
        this.ddEl = createPopper(this.$refs.popupLink?.$el || this.$refs.popupLink, this.$refs.popupEl, {
          placement: 'top',
          modifiers: [preventOverflow, flip, {
            name: 'offset',
            options: {
              offset: this.offset,
            },
          }, {
            name: 'preventOverflow',
            options: {
              padding: {top: paddingTop},
              altAxis: true,
            },
          }],
        });
      } ,this.delayToShow)
    },
    hideDD() {
      clearTimeout(this.showTimeout)
      clearTimeout(this.hideTimeout)
      this.canShow = true
      if (!this.ddEl) { return }
      this.hideTimeout = setTimeout(() => {
        this.isVisible = false
        setTimeout(this.destroyDdEl, 600 )
      } ,this.delayToHide)
    },
    destroyDdEl() {
      if (!this.ddEl) { return }
      this.ddEl.destroy()
      this.ddEl = null
    },
  },
  beforeUnmount() {
    clearTimeout(this.showTimeout)
    clearTimeout(this.hideTimeout)
    this.destroyDdEl()
  },
}
