import globalProps from "@/globalProps";

export default {
  async setCurrentTag({commit, rootState, dispatch, rootGetters}, payload) {
    commit('setLoading', true)
    const {tag, tags} = await dispatch('makeFetch', {
      url: `tags/tagbar?slug=${payload.slug}`,
      method: 'GET',
      errorMessage: 'Failed to Auth!',
      version: 3,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Basic " + rootState.auth.token,
        'Timezone-offset': new Date().getTimezoneOffset()
      },
    }, { root: true } )

    commit('setCurrentTag', tag)
    const user = rootGetters['auth/currentUser']
    let importantUids = user?.uid ? [user.uid, ...user.org_uids] : []
    importantUids = importantUids.concat([...rootGetters['favs/list']])
    await commit('addTags', {tags, reset: true, importantUids})
    commit('setLoading', false)
  },
  async fetchTagsByUid({dispatch, commit, state}, payload) {
    const uids = payload.filter((uid) => !state.tags[uid])
    if(uids.length === 0) return

    commit('setFetchingUids', uids)
    const responseData = await dispatch('makeFetch', {
      url: `tags_by_uids?uids=${uids.join()}`,
      method: 'GET',
      version: 3,
      errorMessage: 'Failed to Fetch Tags By Uid!'
    }, { root: true } )
    commit('removeFetchingUids', responseData.found)
    await dispatch('addTags', responseData.tags)
  },
  async addTags({ commit }, payload) {
    return new Promise((resolve) => {
      commit('addTags', {tags: payload})
      return resolve()
    })
  },
  updateTag({commit}, payload) {
    commit('updateTag', payload)
  },
  async blockToggleTag({dispatch, commit}, payload) {
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/${payload.uid}/block`,
        version: 3,
        method: payload.block_flag ? 'PUT' : 'DELETE',
        errorMessage: 'Failed to HIDE Post!',
      }, { root: true } )
      if(responseData.success) {
        commit('updateTag', {uid: payload.uid, attrs: [
          {key: ['user','prefs','block_flag'], value: payload.block_flag}
        ]})
      } else {
        dispatch('setError', responseData.message, {root: true})
      }

    }catch (error) {
      dispatch('setError', error, {root: true})
    }
  },
  async flagFake({dispatch}, payload) {
    return await dispatch('makeFetch', {
      url: `tags/${payload.uid}/flag_as_fake`,
      method: 'PUT',
      errorMessage: 'Failed to flag As Fake!',
    }, { root: true } )

  },
  async flagInappropriateActivity({dispatch}, payload) {
    return await dispatch('makeFetch', {
      url: `tags/${payload.uid}/flag_as_inappropriate`,
      method: 'PUT',
      errorMessage: 'Failed to flag Inappropriate Activity!',
    }, { root: true } )
  },
  async followToggleTag({dispatch, commit}, payload) {
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/${payload.uid}/follow`,
        method: payload.follow_flag ? 'PUT' : 'DELETE',
        errorMessage: 'Failed to Follow Tag!',
      }, { root: true } )
      if(responseData.success) {
        commit('updateTag', {uid: payload.uid, attrs: [
          {key: ['prefs','follow_flag'], value: payload.follow_flag}
        ]})
      } else {
        dispatch('setError', responseData.message, {root: true})
      }

    }catch (error) {
      dispatch('setError', error, {root: true})
    }
  },
  async likeToggle({dispatch, commit}, payload) {
    try {
      const responseData = await dispatch('makeFetch', {
        url: `tags/${payload.uid}/drive`,
        method: payload.driveFlag ? 'DELETE' : 'PUT',
        errorMessage: 'Failed to LIKE Post!',
      }, { root: true } )
      if(responseData.success) {
        commit('updateTag', {uid: payload.uid, attrs: [
            {key: ['prefs','drive_flag'], value: !payload.driveFlag},
            {key: ['stats','drive_click_count'], value: responseData.drive_click_count},
            {key: ['stats','people_followers_count'], value: responseData.people_followers_count},
            {key: ['prefs','follow_flag'], value: !payload.driveFlag},
            {key: 'driven_by_uids', value: responseData.driven_by_uids}
        ]})
      } else {
        dispatch('setError', responseData.message, {root: true})
      }

    }catch (error) {
      dispatch('setError', error, {root: true})
    }
  },
  async updateAvatar({dispatch}, payload) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/avatar`,
      method: 'POST',
      version: 3,
      body: {avatar_url: payload.avatarUrl, avatar_url_cropped: payload.avatarUrlCropped},
      errorMessage: 'Failed to updateAvatar!',
    }, { root: true } )

    if(responseData.success) {
      dispatch('updateTag', {uid: payload.uid, attrs: [
        { key: 'image', value: payload.avatarUrlCropped },
        { key: 'icon', value: payload.avatarUrlCropped },
        { key: 'icon_original', value: payload.avatarUrl },
      ]})
    } else {
      dispatch('setError', responseData.message, {root: true})
    }
  },

  async updateCover({dispatch, rootGetters}, payload) {
    const method = payload.url ? 'POST' : 'DELETE'
    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/cover`,
      method: method,
      body: {url: payload.url, url_cropped: payload.urlCropped},
      errorMessage: 'Failed to updateCover!',
    }, { root: true } )

    if(responseData.success) {
      const coverUrl = payload.url ? payload.urlCropped : rootGetters['config/getDefaultCover']

      dispatch('updateTag', {uid: payload.uid, attrs: [
        { key: 'cover', value: coverUrl}
      ]})
    } else {
      dispatch('setError', responseData.message, {root: true})
    }
  },
  async updateQrCode({dispatch}, payload) {
    const responseData = await dispatch('makeFetch', {
      url: `tags/${payload.uid}/qrcode`,
      method: 'PUT',
      version: 3,
      body: {qr_url: payload.qr_url},
      errorMessage: 'Failed to updateQrCode!',
    }, { root: true } )

    if(responseData.success) {
      dispatch('updateTag', {uid: payload.uid, attrs: [
          { key: 'qr_code_url', value: payload.qr_url },
        ]})
    } else {
      dispatch('setError', responseData.message, {root: true})
    }
  },
  async incrementInviteToCount({commit, rootGetters}, payload) {
    payload.map((uid, index) => {
      setTimeout(() => {
        const tag = rootGetters['tags/findTag'](uid)
        if (!tag) return

        const old_call_out_to_count = rootGetters['tags/findTag'](uid).stats.call_out_to_count
        commit('updateTag', {uid, attrs: [
            {key: 'action', value: 'increment'},
            {key: 'animate_timestamp', value: new Date().getTime()},
            {key: ['stats', 'call_out_to_count'], value: old_call_out_to_count + 1},
        ]})
      }, globalProps.popDelay * (index + 1))
    })
  },
  async decrementInviteToCount({commit, rootGetters}, payload) {
    payload.map((uid, index) => {
      setTimeout(() => {
        const tag = rootGetters['tags/findTag'](uid)
        if (!tag) return

        const old_call_out_to_count = rootGetters['tags/findTag'](uid).stats.call_out_to_count
        commit('updateTag', {uid, attrs: [
            {key: 'action', value: 'decrement'},
            {key: 'animate_timestamp', value: new Date().getTime()},
            {key: ['stats', 'call_out_to_count'], value: old_call_out_to_count - 1},
          ]})
      }, globalProps.popDelay * (index + 1))
    })
  }
}