<template>
  <div class="dropdown fs-80 d-inline-block">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport" link-id="mentionListDD"></slot>

    <ul class="dropdown-menu" aria-labelledby="mentionListDD">
      <li class="dropdown-header px-2 py-1 fs-80">
        <base-numeral :num="mentionList.length"/>
        <base-pluralize :num="mentionList.length" word="mention"/>
        counted.
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item-text p-0">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list :list="mentionList" :show-call-out="true"/>
          <div v-if="mentionList.length === 0 && !isLoading && !moreIsLoading" class="text-grey text-center py-2">
            No Items
          </div>
          <base-spinner v-if="isLoading || moreIsLoading" class="p-3"></base-spinner>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import TagsList from "@/components/dd/parts/TagsList";
import ddFetchData from "@/hooks/ddFetchData";

export default {
  name: 'MentionListDd',
  components: { TagsList},
  mixins: [ddFetchData],
  emits: ['update-position'],
  props: {
    mentionList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      nextIndex: -1,
    }
  },
  methods: {
    async fetchData() {
      this.list = this.mentionList
    },
  },
}
</script>