<template>
  <i :class="icon"/>
</template>

<script>
export default {
  name: "GlobeIcon",
  data() {
    return {
      icon: `icon-${window.config.VUE_APP_LOCATION || 'north-south-america' }`
    }
  },
}
</script>
