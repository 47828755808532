<template>
  <div class="dropdown fs-80 d-inline-block">
    <slot bs-toggle="dropdown" bs-auto-close="outside" bs-boundary="viewport" link-id="peopleInvitedDD"></slot>

    <ul class="dropdown-menu" aria-labelledby="peopleInvitedDD">
      <li class="dropdown-header px-2 py-1 fs-80">
        Called-out
        <base-numeral :num="otherTagUids.length"></base-numeral>
        <base-pluralize :num="otherTagUids.length" word="Leader"></base-pluralize>
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item-text p-0">
        <div @scroll.passive="infinityScrollHandler" class="tag-list tag-list-dd">
          <tags-list v-if="!isLoading" :list="otherTagUids"></tags-list>
          <base-spinner v-if="isLoading" class="p-3"></base-spinner>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ddFetchData from "../../hooks/ddFetchData";
import TagsList from "./parts/TagsList";

export default {
  name: 'peopleInvitedDD',
  components: {TagsList},
  mixins: [ddFetchData],
  props: ['otherTagUids'],
  data() {
    return {
      isLoading: true,
      nextIndex: -1,
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      await this.$store.dispatch('tags/fetchTagsByUid', this.otherTagUids)
      this.isLoading = false
    }
  },
}
</script>