export default {
  setUser(state, payload={}) {
    if(Object.keys(payload).length === 0) {
      state.isLoggedIn = false;
      state.currentUser = {};
      return
    }
    const user_settings = {...payload.user_tag.user};
    delete payload.user_tag.user
    const full_settings = {...payload.settings, ...user_settings }
    delete payload.settings

    const user_tag = {...payload.user_tag}
    delete payload.user_tag

    const user = {...payload, ...full_settings, ...user_tag }
    state.isLoggedIn = true;
    state.currentUser = user;
  },
  setToken(state, payload) {
    state.token = payload.token;
  },
  // setAutoLogout(state){
  //   state.didAutoLogout = true;
  // },
  setIsLoading(state, payload) {
    state.isLoading = payload
  },
  toggleOption(state, payload) {
    state.currentUser[payload.key] = payload.value
  },
  updateNotifications(state, payload) {
    if ('unread_requests_count' in payload) {
      state.currentUser.unread_requests_count = payload.unread_requests_count
    }
    if ('notices_count' in payload) {
      state.currentUser.notices_count = payload.notices_count
    }
    if ('is_subscriber' in payload) {
      state.currentUser.is_subscriber = payload.is_subscriber
    }
  },
  teamupDDToggle(state, payload) {
    state.dd.teamupIsOpen = payload
  },
  setBlocked(state, {list, next_index}) {
    state.blocked.nextIndex = next_index
    state.blocked.list = state.blocked.list.concat(list)
  },
  setBlockedLoading(state, payload) {
    state.blocked.isLoading = payload
  },
  removeBlocked(state, payload) {
    state.blocked.list = state.blocked.list.filter((uid) => uid !== payload.uid)
  },
  clearBlocked(state) {
    state.blocked.nextIndex = 0
    state.blocked.list = []
  },
  setFollows(state, {list, next_index}) {
    state.follows.nextIndex = next_index
    state.follows.list = state.follows.list.concat(list)
  },
  setFollowsLoading(state, payload) {
    state.follows.isLoading = payload
  },
  removeFollow(state, payload) {
    state.follows.list = state.follows.list.filter((uid) => uid !== payload.uid)
  },
  clearFollows(state) {
    state.follows.nextIndex = 0
    state.follows.list = []
  },
  setNoticeLoading(state, payload) {
    state.notices.isLoading = payload
  },
  setNoticesData(state, {events, next_index, notices_count, more}) {
    state.notices.nextIndex = next_index
    state.currentUser.notices_count = notices_count
    if (more) {
      state.notices.events = state.notices.events.concat(events)
    } else {
      state.notices.events = events
    }
  },
  setToasts(state, payload) {
    state.toasts = [...payload, ...state.toasts]
  },
  clearNotices(state) {
    state.notices.isLoading = false
    state.notices.nextIndex = 0
    state.currentUser.notices_count = 0
    state.notices.events = []
  },
  clearNoticesEvents(state) {
    state.notices.isLoading = false
    state.notices.nextIndex = 0
    state.notices.events = []
  },
  setRequests(state, payload) {
    state.requests.readTimestamp = payload.read_timestamp
    state.requests.previousReadTimestamp = payload.previous_read_timestamp
    state.currentUser.unread_requests_count = payload.unread_count
    state.requests.history = payload.history
    state.requests.incoming = payload.incoming
    state.requests.suggestion = payload.suggestion
  },
  acceptTeamupRequest(state, payload) {
    const foundRequest = state.requests.incoming.find((request) => request.tag_uid === payload.uid)
    if (!foundRequest) return

    foundRequest.time = Date.now()
    state.requests.history.unshift(foundRequest)
    state.requests.incoming = state.requests.incoming.filter((request) => request.tag_uid !== payload.uid)
  },
  declineTeamupRequest(state, payload) {
    state.requests.incoming = state.requests.incoming.filter((request) => request.tag_uid !== payload.uid)
  },
  setRequestsLoading(state, payload) {
    state.requests.isLoading = payload
  },
  clearRequests(state) {
    state.requests.isLoading = false
    state.requests.readTimestamp = 0
    state.requests.previousReadTimestamp = 0
    state.currentUser.unread_requests_count = 0
    state.requests.history = []
    state.requests.incoming = []
    state.requests.suggestion = []
  },
  clearRequestsLists(state) {
    state.requests.isLoading = false
    state.requests.readTimestamp = 0
    state.requests.previousReadTimestamp = 0
    state.requests.history = []
    state.requests.incoming = []
    state.requests.suggestion = []
  },
  removeNoticesByPostUid(state, {postUid}) {
    state.notices.events = state.notices.events.filter((event) => event.post_uid !== postUid)
  },
  toggleReadStatus(state, {eventUid, unread}) {
    let event = state.notices.events.find(event => event.event_uid === eventUid);
    if (!event) return
    Object.assign(event, {unread})
    state.currentUser.notices_count = state.currentUser.notices_count - 1
  },
  toggleToastReadStatus(state, {eventUid}) {
    state.toasts = state.toasts.filter(toast => toast.event_uid !== eventUid );
  },
  markAllAsRead(state) {
    state.notices.events = state.notices.events.map(item => Object.assign(item, {unread: false}) )
    state.currentUser.notices_count = 0
  },
}