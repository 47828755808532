<template>
  <Player
    :autoplay="video.autoplay"
    :loop="video.loop"
    :muted="video.muted"
    theme="dark"
    :style="playerStyle"
    ref="player"
    @vmReady="onReady"
    @vmPausedChange="onPause"
    @vmPlaybackEnded="onEnded"
    @vmCurrentPosterChange="vmCurrentPosterChange"
    :view-type="ViewType.video"
    :aspect-ratio="video.aspectRatio || '16:9'"
  >
    <Youtube v-if="isYT"
      :videoId="videoId"
      :poster="video.poster"
    />
    <Vimeo v-if="isVimeo"
      :videoId="videoId"
      :poster="video.poster"
    />
    <vm-video v-if="isRegularVideo"
           :poster="video.poster"
    >
      <source
          :data-src="video.src"
          type="video/mp4"
      />
    </vm-video>
    <DefaultUi noControls>
      <DefaultControls hideOnMouseLeave :activeDuration="500" />
    </DefaultUi>
  </Player>
</template>

<script>
  import {allRegexp, colorSheme} from "@/functions/global";
  import { Player, DefaultUi, Youtube, Vimeo, Video, ViewType, DefaultControls } from '@vime/vue-next';

  export default {
    emits: ['loaded', 'toggleControls'],
    components: {
      Player,
      DefaultUi,
      DefaultControls,
      Youtube,
      Vimeo,
      VmVideo: Video,
    },
    props: {
      video: Object,
    },
    data() {
      return {
        posterSrc: null,
        ViewType: ViewType,
      }
    },
    computed: {
      isYT() {
        return allRegexp.youtube.test(this.video.src)
      },
      isVimeo() {
        return allRegexp.vimeo.test(this.video.src)
      },
      isRegularVideo() {
        return !this.isYT && !this.isVimeo
      },
      videoId() {
        if (this.isYT) {
          const match = this.video.src.match(allRegexp.youtubeId)
          return match[2]
        } else {
          const match = this.video.src.match(allRegexp.vimeoId)
          return match[1]
        }
      },
      playerStyle() {
        return `--vm-player-theme: ${colorSheme[this.$store.state.config.theme]};`
      },
    },
    methods: {
      onReady() {
        this.$emit('loaded')
      },
      onPause(state) {
        this.$emit('toggleControls', state)
      },
      onEnded() {
        this.$emit('toggleControls', true)
        this.$refs.player.currentTime = 0
      },
      vmCurrentPosterChange(src) {
        this.posterSrc = src
      },
    },
    beforeUnmount() {
      this.$emit('toggleControls', true);
    },
  }
</script>
