<template>
  <div class="qr-modal-content">
    <div class="qr-modal-content-inner text-center">
      <div class="qr-avatar-wrapper">
        <img class="rounded-circle me-2 avatar" :src="tag?.icon || imgPath + '/default-user.png'" />
      </div>
      <div class="d-flex flex-column align-items-center">
        <div class="fw-bold fs-140">{{tag.display_name}}</div>
        <div v-if="tagTitle" class="text-grey fs-120">{{tagTitle}} {{ org ? org.display_name: ''}}</div>
      </div>
      <vue-qrcode
          :value="`https://www.dualdialogue.com/${tag.tag}/autoteamup/${tag.uid}`"
          :color="{ dark: '#000000ff', light: '#ffffffff' }"
          type="image/png"
          :margin="2"
          class="mt-2 w-70"
          :width="600"
          @change="onChangeQrCode"
      />
    </div>
  </div>
</template>

<script>
import tagTitle from "@/hooks/tagTitle";
import VueQrcode from 'vue-qrcode'

export default {
  name: "QrMyCode",
  mixins: [tagTitle],
  components: {VueQrcode},
  props: {
    onChangeQrCode: Function
  },
  computed: {
    currentUser() {
      return this.$store.getters['auth/currentUser']
    },
    tag() {
      return this.$store.getters['tags/findTag'](this.currentUser.uid)
    },
    org() {
      if (this.tag.org_uid === -1) return

      return this.$store.getters['tags/findTag'](this.tag.org_uid)
    },
  },
}
</script>
