import { register } from 'timeago.js';


const localeFunc = (_number, index) => {
  return [
    ['just now', 'right now'],
    ['1m', 'now'],
    ['1m', 'now'],
    ['%sm', 'now'],
    ['1h', 'now'],
    ['%sh', 'now'],
    ['1d', 'now'],
    ['%sd', 'now'],
    ['1w', 'now'],
    ['%sw', 'now'],
    ['1mo', 'now'],
    ['%smo', 'now'],
    ['1yr', 'now'],
    ['%syr', 'now']
  ][index];
};
register('dl', localeFunc);

// prefixAgo: null,
//   prefixFromNow: null,
//   suffixAgo: "",
//   suffixFromNow: "from now",
//   inPast: 'any moment now',
//   seconds: "1m",
//   minute: "1m",
//   minutes: "%sm",
//   hour: "1h",
//   hours: "%sh",
//   day: "1d",
//   days: "%sd",
//   month: "1mo",
//   months: "%smo",
//   year: "1yr",
//   years: "%syr",
//   wordSeparator: " ",
//   numbers: []