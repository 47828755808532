<template>
  <template v-if="orgUids.length > 0">
    <span class="text-grey">of</span>
    <template v-for="(uid, index) in orgUids" :key="uid">
      <base-tag-dd-link class="link-color" :uid="uid">
        <template #prefix>"</template>
        <template #affix>"<span v-if="orgUids.length -1 !== index" class="text-grey">, </span></template>
      </base-tag-dd-link>
    </template>
  </template>
  <span v-if="list.length" class="text-grey">
    member of <base-numeral v-if="list.length > 2" :num="list.length"/> dual <base-pluralize :num="list.length" word="dialogue"/>
  </span>
  <base-spinner v-if="isLoading" display-inline/>
  <template v-if="!isLoading">
    <template v-for="(group, index) in groups" :key="group.uid">
      <base-tag-dd-link :uid="group.uid" class="link-color" target="_blank">
        "{{group.display_name}}"
      </base-tag-dd-link>
      {{groups.length -1 === index ? '' : ', '}}
    </template>
    <span v-if="!moreVisible && list.length > 4" class="link-main" @click="toggleMoreIsVisible">...more</span>
    <span v-if="moreVisible && list.length > 4" class="text-grey">to the table for agreements.</span>
  </template>
</template>

<script>
export default {
  name: "InviteLeaderTitle",
  emits: ['update-position'],
  props: {
    uid: {
      required: true,
    },
    orgUids: {
      required: true,
    },
  },
  created() {
    this.fetchGroups()
  },
  watch: {
    groups() {
      this.$nextTick(() => {
        this.$emit('update-position')
      })
    }
  },
  data() {
    return {
      moreVisible: false,
      isLoading: true,
      list: [],
    }
  },
  computed: {
    groups() {
      const uids = this.moreVisible ? this.list : this.list.slice(0,4)
      return this.$store.getters['tags/getTags'](uids)
    },
  },
  methods: {
    async fetchGroups() {
      this.isLoading = true
      try {
        const responseData = await this.$store.dispatch('makeFetch', {
          url: `tags/${this.uid}/resolving?count=100`,
          method: 'GET',
          version: 2,
        })
        if (responseData.tags.length) {
          await this.$store.dispatch('tags/addTags', responseData.tags)
        }
        this.list = responseData.list
        this.isLoading = false
      } catch (error) {
        console.error('Failed to fetch /resolving', error?.message)
        this.isLoading = false
      }
    },
    toggleMoreIsVisible() {
      this.moreVisible = !this.moreVisible
    },
  },
}
</script>
