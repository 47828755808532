<template>
  <div class="row g-0 images-preview-container">
    <span v-for="(image, index) in images" :key="image" class="image-preview col-12 pointer" @click="zoomImages(index)">
      <img :src="image" class="img-thumbnail shadow-sm p-0" alt="img" loading="lazy">
    </span>
    <images-modal v-if="selectedIndex >= 0" :images="images" :selected-index="selectedIndex" @close="closeModal"></images-modal>
  </div>
</template>

<script>
import ImagesModal from "../../../modals/ImagesModal";
import zoomImages from "../../../../hooks/zoomImages";

export default {
  name: "CommentImages",
  components: {ImagesModal},
  mixins: [zoomImages],
  props: {
    images: {
      default: [],
    },
  },
}
</script>

<style scoped>

</style>