export default {
  isPhotosLoading(state) {
    return state.isLoading;
  },
  photos(state) {
    return state.photos
  },
  // isInnerTag: (state, _getters, rootState) => (uid) => {
  //
  //   // config.inner_tag_uids.indexOf(uid) > -1 and config.sys_uids.breaking_news isnt uid
  // }
}