export default {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setTitlesLoading(state, payload) {
    state.isTitlesLoading = payload;
  },
  setProfile(state, payload) {
    state.profile = payload;
    state.initialProfileData = JSON.parse(JSON.stringify(payload))
  },
  resetProfile(state) {
    state.profile = Object.assign({}, JSON.parse(JSON.stringify(state.initialProfileData)))
  },
  resetInitialProfileData(state) {
    state.initialProfileData = Object.assign({}, JSON.parse(JSON.stringify(state.profile)))
  },
  setTitles(state, payload) {
    state.titles = payload;
  },
  clearData(state) {
    state.profile = {};
    state.initialProfileData = {};
    state.titles = [];
  }
}