<template>
  <div>
    <div id="reader"></div>
    <base-spinner v-if="isLoading" class="my-5 py-5"/>
    <div class="mt-2 text-center">
      <label class="btn btn-color" for="qr-input-file"><i class="icon-image me-2"/> Scan from photos</label>
      <input @change="scanFile" class="d-none" type="file" id="qr-input-file" accept="image/*" :disabled="isLoading">
    </div>
    <div v-if="mode === SCANNER_MODE_FILE" class="mt-2 text-center">
      <button @click="startBarcodeScanner" class="btn btn-color"><i class="icon-camera me-2"/> Scan from camera</button>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode} from "html5-qrcode";

const SCANNER_MODE_CAMERA = 'camera';
const SCANNER_MODE_FILE = 'file';

export default {
  name: "QrCodeScan",
  props: {
    onSuccessScan: Function,
  },
  mounted() {
    this.startBarcodeScanner()
  },
  beforeUnmount() {
    this.stopBarcodeScanner()
  },
  watch: {
    'html5QrCode.isScanning'(val) {
      this.isLoading = !val
    }
  },
  data() {
    return {
      SCANNER_MODE_CAMERA: SCANNER_MODE_CAMERA,
      SCANNER_MODE_FILE: SCANNER_MODE_FILE,
      html5QrCode: null,
      isLoading: true,
      mode: SCANNER_MODE_CAMERA,
    }
  },
  methods: {
    async startBarcodeScanner() {
      this.isLoading = true
      this.mode = SCANNER_MODE_CAMERA
      let cameraId = ''
      const devices = await Html5Qrcode.getCameras()
      if (devices && devices.length) {
        cameraId = devices[1] ? devices[1].id : devices[0].id;
      }
      this.html5QrCode = new Html5Qrcode("reader")
      const options = {  focusMode: "continuous", rememberLastUsedCamera: true, verbose: true}
      this.html5QrCode.start(
          cameraId,
          options,
          this.successScan
        ).catch((err) => {
          this.$store.dispatch('setError', err)
        });
    },
    async scanFile(e) {
      if (e.target.files.length === 0) return

      this.mode = SCANNER_MODE_FILE
      await this.stopBarcodeScanner()
      const imageFile = e.target.files[0]
      // Scan QR Code
      this.html5QrCode.scanFile(imageFile, true)
        .then(this.successScan)
        .catch(err => {
          return this.$store.dispatch('setError', `Failed to scan file: ${err}`);
        })
    },
    async successScan(decodedText) {
      await this.stopBarcodeScanner()
      const uid = decodedText.split('_')[1]
      if (!uid) {
        return this.$store.dispatch('setError', 'Failed to scan code');
      }
      await this.$store.dispatch('teammates/teamUpRequest', {uid: Number(uid)})
      this.onSuccessScan()
    },
    async stopBarcodeScanner() {
      if (!this.html5QrCode.isScanning) return

      await this.html5QrCode.stop()
      this.isLoading = false
    },
  },

}
</script>
