import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import {NONE} from "@/const/statuses";

export default {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      nextIndex: 0,
      list: [],
      wdu: '',
      saveStatus: NONE,
    }
  },
  getters,
  actions,
  mutations,
}
