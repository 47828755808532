<template>
    <component
        v-bind="$attrs"
        :is="tagName"
        @click="invite"
        v-touch:hold="hasDd ? showDD : null"
        v-on="{
          mouseenter: hasDd ? showDD : null,
          mouseleave: hasDd ? hideDD : null,
        }"
        class="invite-button"
        :class="[isAnimating && 'animate', isVisible && 'z-3 position-relative', animationClass]"
        ref="popupLink"
    >
        <slot><i class="icon-megaphone me-1"></i> <base-numeral :num="inviteToCount" v-if="inviteToCount"></base-numeral></slot>
    </component>
  <span
      ref="popupEl"
      v-on="{
          mouseenter: hasDd ? showDD : null,
          mouseleave: hasDd ? hideDD : null,
      }"
      class="popup-dd-container lh-base">
    <transition name="opacity" mode="out-in">
      <invite-dd
          v-if="isVisible"
          :uid="payload.tag_uid"
          @update-position="updatePosition"
      />
    </transition>
  </span>
  <div v-if="isVisible" @click="hideDD" class="d-md-none w-100 h-100 z-2 dd-overlay position-fixed top-0 start-0"></div>
</template>


<script>
  import { Browser } from '@capacitor/browser';
  import InviteDd from "@/components/dd/InviteDd";
  import popup from "@/hooks/popup";
  import {Capacitor} from "@capacitor/core";

  export default {
    name: 'InviteButton',
    components: {InviteDd},
    mixins: [popup],
    props: {
      displayName: String,
      inviteToCount: Number,
      tagName: {
        type: String,
        default: 'button'
      },
      payload: {
        default: {}
      },
      hasDd: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        isAnimating: null,
        animationClass: null,
        animateTimeout: null,
      }
    },
    watch: {
      animateTimestamp(val, oldVal) {
        if (val && val.animate_timestamp !== oldVal.animate_timestamp) {
          this.animateButton(val.action)
        }
      },
    },
    methods: {
      async invite() {
        let promoTagUids = []
        if (this.payload.promo_tag_uids && this.payload.first_promo_uid) {
          promoTagUids = [...this.payload.promo_tag_uids]
          promoTagUids.splice(promoTagUids.indexOf(this.payload.first_promo_uid), 1)
          promoTagUids.unshift(this.payload.first_promo_uid)
        }
        if (!this.payload.promo_tag_uids && this.payload.first_promo_uid) {
          promoTagUids.push(this.payload.first_promo_uid)
        }
        if (this.payload.promo_tag_uids && !this.payload.first_promo_uid) {
          promoTagUids = [...this.payload.promo_tag_uids]
        }
        const data = {
          post_uid: this.postUid,
          feed_tag_uid: this.$store.getters['tags/currentTagUid'],
          mode: this.$store.getters['tags/findTag'](this.payload.tag_uid).isOrg ? 'team' : 'single',
          ...{...this.payload, promo_tag_uids: promoTagUids},
        }
        if(!this.$store.getters['auth/isLoggedIn']) {
          return await this.$router.push({name: 'sign-in', params: {slug: this.$store.getters['tags/currentTagSlugNoHome']}})
        }
        if(this.$store.getters['auth/isTwMerged']) {

          const windowReference = Capacitor.isNativePlatform() ? false : window.open('','_blank', 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=500,height=600')
          try {
            const responseData = await this.$store.dispatch('makeFetch', {
              url: `tags/${data.tag_uid}/tweet_invite`,
              method: 'POST',
              errorMessage: 'Failed to TWEET INVITE!',
              body: data
            }, { root: true } )

            if(responseData && responseData.success) {
              if (windowReference) {
                windowReference.location = `https://twitter.com/intent/tweet?url=${responseData.socials.tw_url}&text=${responseData.socials.tw_text}`
              } else {
                await Browser.open({ url: `https://twitter.com/intent/tweet?url=${responseData.socials.tw_url}&text=${responseData.socials.tw_text}` });
              }
            } else {
              throw new Error(responseData.message || 'Failed get Tweet invite data!');
            }
          }catch (error) {
            this.$store.dispatch('setError', error, {root: true})
          }
        } else {
          const url = this.apiPath + "/auth/twitter/mergepopup" + "?access_token=" + localStorage.getItem('token')

          const left = (screen.width/2)-(1000/2)
          const top = (screen.height/2)-(750/2)
          const auth_window = window.open(url, 'Authentication', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=1000, height=750, top='+top+', left='+left);
          auth_window.localStorage = window.localStorage
        }
      },
      animateButton(increment) {
        this.animationClass = increment
        this.isAnimating = true
        clearTimeout(this.animateTimeout)
        this.animateTimeout = setTimeout(() => {
          this.isAnimating = false
          this.animationClass = null
        }, 300)
      },
      updatePosition() {
        this.ddEl.update();
      },
    },
    computed: {
      animateTimestamp() {
        const tag = this.$store.getters['tags/findTag'](this.payload.tag_uid)
        return {animate_timestamp: tag?.animate_timestamp || null, action: tag?.action || null}
      },
    },
    beforeUnmount() {
      clearTimeout(this.animateTimeout)
    },
  }
</script>