<template>
    <transition name="route" mode="out-in" appear>
        <div :class="className" v-bind="$attrs">
            <div class="spinner-border spinner-border-sm" :class="spinnerClassName" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </transition>
</template>


<script>
  export default {
    name: 'BaseSpinner',
    props: {
      displayInline: {
        type: Boolean,
        default: false,
      },
      themeColor: {
        type: Boolean,
        default: true,
      },
      spinnerClass: {
        type: String,
        default: '',
      },
    },
    computed: {
      className() {
        return this.displayInline ? 'd-inline-block' : 'd-flex justify-content-center align-items-center h-100'
      },
      spinnerClassName() {
        return [this.themeColor && 'theme-color', this.spinnerClass]
      },
    },
  }

</script>