<template>
  <div class="dropdown" ref="foundedTagsDropdown">
    <a class="" href="#" role="button" id="foundedDDLink" data-bs-toggle="dropdown" aria-expanded="false" ref="foundedDD"></a>
    <div class="dropdown-menu w-100" :class="{show: isDdVisible}" aria-labelledby="foundedDDLink" ref="dropdownMenu">
      <div class="card m-0">
        <div class="card-header theme-bg">
          <div class="header-title">
            <div class="text-white">
              <template v-if="mode === 'history'">
                Recent Searches - <span class="link-white pointer" @click="$emit('onClearHistory')">Clear All</span>
              </template>
              <template v-else>
                Search Results
              </template>
              <base-spinner v-if="isLoading" class="float-end" :theme-color="false" displayInline></base-spinner>
            </div>
          </div>
        </div>
        <div id="founded-tags-list-wrapper" @scroll.passive="infinityScrollHandler">
          <ul class="list-unstyled m-0" id="founded-tags-list">
            <li v-for="(tag, index ) in activeList" :key="tag.uid" @mouseenter="onMouseEnter(index)">
              <tag-list-item
                  :tag="tag"
                  :selected-index="selectedIndex"
                  :index="index"
                  :mode="mode"
                  @navigateToTag="navigateToTag"
                  @onClose="closeDD"
              />
            </li>
            <li v-if="activeList.length === 0 && !isLoading" class="dropdown-item p-3 text-center">No Results</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import fetchOnScroll from "../../../hooks/fetchOnScroll";
    import {Dropdown} from 'bootstrap';
    import TagListItem from "./TagListItem";

    export default {
      props: ['mode', 'isDdVisible', 'activeList', 'isLoading', 'selectedIndex'],
      components: {TagListItem},
      mixins: [fetchOnScroll],
      emits: ['onMore', 'onSelect', 'onClearHistory', 'onClose', 'navigateToTag'],
      mounted() {
        this.dropdown = new Dropdown(this.$refs.foundedDD, {autoClose: false})
        this.inputEl = document.getElementById('tagbarInput')
        document.addEventListener("click", this.onGlobalClick)
      },
      beforeUnmount() {
        document.removeEventListener("click", this.onGlobalClick)
      },
      methods: {
        more() {
          this.$emit('onMore')
        },
        onMouseEnter(index) {
          this.$emit('onSelect', index)
        },
        onGlobalClick(evt) {
          const composedPath = evt.composedPath();
          const isMenuTarget = composedPath.includes(this.$refs.dropdownMenu);
          const isInputTarget = composedPath.includes(this.inputEl);
          if (!this.isDdVisible || isMenuTarget || isInputTarget) {
            return evt
          }

         this.closeDD()
        },
        closeDD() {
          this.$emit('onClose')
        },
        navigateToTag() {
          this.$emit('navigateToTag')
        },
      },
    }
</script>
