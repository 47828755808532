<template>
  <div class="text-grey clearfix py-1">
    <span class="align-middle float-end">
      <span v-if="totalCount <= 6">
        <span v-if="stats.teammates_count > 0">
          {{stats.teammates_count > 1 ? "Teammates'" : "Teammate's"}}
        </span>
        <span v-if="stats.people_count > 0">
          {{stats.teammates_count > 0 ? 'and' : ''}}
          {{stats.people_count > 1 ?  "Peoples'" : "Peoples'"}}
        </span>
        dialogue
      </span>
      <span v-if="totalCount > 6">
        <span class="align-middle">
          <base-numeral :num="xMoreCount"/> more <base-pluralize :num="xMoreCount" word="reply"/>
          from
          <span v-if="nextStats.teammates_count > 0">
            {{nextStats.teammates_count > 1 ? "teammates'" : "teammate's"}}
          </span>
          <span v-if="nextStats.people_count > 0">
            {{nextStats.teammates_count > 0 ? 'and' : ''}}
            Peoples'
          </span>
          dialogue
        </span>
        <span v-if="nextStats.teammates_count > 0" class="comment-stats-icons d-inline-flex align-middle mx-2">
          <comment-stats-image v-for="uid in teammatesImg" :uid="uid" :key="uid"/>
        </span>
        <span>
          <router-link v-if="!isPostZoom"  :to="{name: 'post', params: { post_uid: postUid, slug: $store.getters['tags/currentTagSlugNoHome']}, query: {count: 100, parent_comment_uid: parentCommentUid}}" class="no-decor mx-1">
            <i class="icon-full-screen fs-5 theme-color align-middle"></i>
          </router-link>
          <i v-show="!isInitialState" @click="setInitialState" class="icon-minus-outline fs-4 theme-color align-middle pointer mx-1"></i>
          <i v-if="xMoreCount" @click="toggleReplies" class="icon-plus-outline fs-4 theme-color align-middle pointer mx-1"></i>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import CommentStatsImage from "@/components/feed/partials/CommentStatsImage";

export default {
  name: "RepliesNormalStats",
  components: {CommentStatsImage},
  inject: ['isPostZoom', 'postUid'],
  props: {
    stats: {
      default: {}
    },
    nextStats: {
      default: {}
    },
    visibleCount: Number,
    totalCount: Number,
    parentCommentUid: Number,
    isInitialState: Boolean,
    toggleReplies: Function,
    setInitialState: Function,
  },
  computed: {
    teammatesImg() {
      return this.nextStats.teammates.slice(0, 3)
    },
    xMoreCount() {
      return this.totalCount - this.visibleCount
    },
  },
}
</script>
