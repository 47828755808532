export default {
  isCoverLoading(state) {
    return state.isLoading;
  },
  teammateIcons(state) {
    return state.teammateIcons
  },
  teammateUids(state) {
    return state.teammateUids
  },
  banners(state) {
    return state.banners
  },
}